import React, { useState, useEffect, useContext, useCallback, Fragment, useMemo } from 'react'
import ReactDOM from 'react-dom'
import { Link, useHistory, withRouter } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import { withStyles, useTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import GridListTileBar from '@material-ui/core/GridListTileBar'

import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Slider from '@material-ui/core/Slider'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Tooltip from '@material-ui/core/Tooltip'
import TextField from '@material-ui/core/TextField'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import Checkbox from '@material-ui/core/Checkbox'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import Modal from '@material-ui/core/Modal'
import SwipeableViews from 'react-swipeable-views'
import CircularProgress from '@material-ui/core/CircularProgress'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import PublicIcon from '@material-ui/icons/Public'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import SearchIcon from '@material-ui/icons/Search'
import CancelIcon from '@material-ui/icons/Cancel'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import CheckRoundedIcon from '@material-ui/icons/CheckRounded'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import DoneIcon from '@material-ui/icons/Done'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Picker from 'emoji-picker-react'
import Popover from '@material-ui/core/Popover'
import { AdContext, AdDispatchContext } from '../components/Base/AppContainer'
import { MarketerAdContext, MarketerAdDispatchContext } from '../components/Base/MarketerContainer'
import { trimText } from '../util/Helpers'
import api from '../services/api'

import { blue, hoverBlue, red, darkGrey, darkestGrey, marketerGrey, marketerBlue } from '../assets/colorPalette'

import { Item } from './components'
import Carousel from './Carousel'

import { Message, Dropdown } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import styled from 'styled-components'
import { DragAndDrop } from '../components/Base/DragAndDrop'
import { HelpMeWrite } from '../components/Base/HelpMeWrite'
import { io } from 'socket.io-client'
import VideoAdPreviewCard from './VideoAdPreviewCard'

const eightMb = 8388608
const allowedImageTypes = ['image/png', 'image/jpeg']

//Styled Components:
const Mes = styled(Message)`
  border-left: solid 3px;
  box-shadow: none;
  -webkit-box-shadow: none;
  border-radius: 0 !important;
  font-size: 1em;
  display: flex;
  align-items: center;
  &&& > .close.icon {
    position: initial;
    opacity: 1;
    margin-left: auto;
  }
`

const DEBUG_MODE = false

const dropdownStyles = makeStyles({
  underline: {
    borderBottom: '0px solid red !important',
    '&:hover': {
      borderBottom: '0px solid rgba(0,0,0,0)'
    }
  }
})
const filterOptions = [
  {
    key: 'All Custom Ads',
    text: 'All Custom Ads',
    value: 'all'
  },
  {
    key: 'Recruiting',
    text: 'Recruiting',
    value: 'recruiting'
  },
  {
    key: 'Branding',
    text: 'Branding',
    value: 'branding'
  },
  {
    key: 'Searching',
    text: 'Searching',
    value: 'search'
  }
]
//* styles
const useStyles = createUseStyles({
  mainContainer: {
    padding: 16
  },
  input: {
    color: marketerBlue + '!important',
    fontSize: '15px!important'
  },
  input2: {
    color: 'black' + '!important',
    fontSize: '15px!important'
  },
  mainContainerSmall: {
    padding: '0px 8px'
  },

  cardContainer: {
    padding: 24
  },
  cardContainerSmall: {
    padding: 8
  },
  linkTitleWidth: {
    width: '100%',
    top: '0px'
  },
  linkTagsWidth: {
    width: '75%',
    top: '0px'
  },
  linkStyleUrl: {
    width: '100%',
    top: '0px',
    cursor: 'pointer!important'
  },

  headTitleWidth: {
    width: '368px',
    left: '4px'
  },
  headTitleWidthF: {
    width: '395px',
    left: '4px'
  },
  headPosBorder: {
    borderRight: '1px solid grey'
  },
  headAd: {
    fontSize: '15px!important'
  },
  headTitleAd: {
    fontSize: '17px!important',
    textTransform: 'uppercase'
  },
  headDisplay: {
    width: '102%!important',
    position: 'absolute',
    top: '-55px!important'
  },
  headDisplayAutoComplete: {
    position: 'relative',
    top: '-10px!important'
  },
  chip: {
    borderRadius: '2px!important',
    marginTop: 10,
    marginRight: 5
  },
  imgSwipeable: {
    '& > *': {
      width: '80%!important'
    },
    '& > :last-child': {
      marginRight: '0px!important'
    },
    '& > :first-child': {
      marginLeft: '0px!important'
    }
  },
  flexContainer: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center'
  },
  cardContainerPage: {
    padding: '2px 8px!important'
  },
  slideContainer: {
    padding: '0 10px'
  },
  fullContainer: {
    width: '100%',
    height: '100%'
  },
  fullContainerExp: {
    width: '100%',
    height: '450px!important'
  },
  fullContainerDrop: {
    width: '100%',
    height: '450px'
  },
  fullContainerReal: {
    position: 'absolute',
    left: '80px'
  },
  fullContainerReal2: {
    position: 'absolute',
    left: '0px',
    top: '20px'
  },
  fullContainerReal3: {
    position: 'absolute',
    left: '70px',
    top: '20px'
  },
  fullContainerInstagram: {
    width: '85%',
    height: '100%'
  },
  fullWidth: {
    width: '100%!important'
  },
  fullWidthWithPadding: {
    width: '100%!important',
    padding: 1
  },
  sliderContainer: {
    width: '100%',
    height: '100%'
  },
  paperStyles: {
    padding: 1
  },
  paperStyles_bottomButtons: {
    padding: 3
  },
  paperStyles_bottomButtons_ready: {
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: '9px'
  },
  evenCardHeight: {
    height: 'calc(100% - 32px)'
  },
  smallPadding: {
    padding: '8px!important'
  },
  TopPaddingLink: {
    paddingTop: '40px!important'
  },
  TopPaddingLink10: {
    paddingTop: '10px!important'
  },
  noTopPadding: {
    paddingTop: '0px!important'
  },
  noBottomPaddingX: {
    paddingBottom: '0px!important',
    position: 'relative',
    top: '-5px'
  },
  noBottomPadding: {
    paddingBottom: '0px!important'
  },
  yesBottomPadding: {
    paddingBottom: '10px!important'
  },
  noLeftPadding: {
    paddingLeft: '0px!important'
  },
  paddingTopSmall: {
    paddingTop: '16px!important'
  },
  marginTopMedium: {
    marginTop: '16px!important'
  },
  marginTopLarge: {
    marginTop: '24px!important'
  },
  buttonStyles: {
    textTransform: 'initial!important'
  },
  alignStart: {
    textAlign: 'start!important'
  },
  alignEnd: {
    textAlign: 'end!important'
  },
  alignEndInsta: {
    textAlign: 'end!important',
    top: '4px',
    right: '1px',
  },
  alignCenter: {
    textAlign: 'center!important'
  },
  alignJustify: {
    textAlign: 'justify!important'
  },
  heavyFontWeight: {
    fontWeight: '800!important'
  },
  lightFontWeight: {
    fontWeight: '300!important'
  },
  descriptionLineHeight: {
    lineHeight: '1.75em!important'
  },
  totalTitle: {
    paddingTop: 13
  },
  blue: {
    color: `${blue}!important`
  },
  offBlue: {
    color: `${hoverBlue}!important`
  },
  darkGrey: {
    color: `${darkGrey}!important`
  },
  darkestGrey: {
    color: `${darkestGrey}!important`
  },
  underline: {
    borderBottom: '0px solid red !important',
    '&:hover': {
      borderBottom: '0px solid rgba(0,0,0,0)'
    }
  },
  marketerGrey: {
    color: `${marketerGrey}!important`
  },
  marketerBlue: {
    color: `${marketerBlue}!important`
  },
  black: {
    color: `${marketerGrey}!important`
  },
  greencolor: {
    color: 'green' + '!important'
  },
  boldFontWeight: {
    fontWeight: '500!important'
  },
  trueBoldFontWeight: {
    fontWeight: '600!important'
  },
  customPaper: {
    '-webkit-box-shadow': '0px 2px 4px 0px #E0E0E0!important',
    boxShadow: '0px 2px 4px 0px #E0E0E0!important'
  },
  imgWrapper: {
    position: 'relative'
  },
  imgWrapperInsta: {
    position: 'relative',
    height: '375px'
  },
  mainListingImageContainer: {
    height: 275
  },
  mainListingImageContainerSocialMedia: {
    height: 425
  },
  mainListingImageContainerInsta: {
    height: 375
  },
  mainListingImage: {
    height: '100%',
    width: '100%',
    backgroundPosition: 'center',
    backgroundSize: '100% 100%',
    borderRadius: '4px 4px 0px 0px'
  },
  mainListingImageLegacy: {
    height: '100%',
    width: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: '4px 4px 0px 0px'
  },
  pageProfileImg: {
    borderRadius: 30
  },
  imgArrowContainer: {
    zIndex: '10',
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 10
  },
  customWidth: {
    maxWidth: 'none!important'
  },
  imgArrowContainerLarge: {
    zIndex: '10',
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 5
  },
  imgArrowContainerMidLeft: {
    zIndex: '10',
    position: 'absolute',
    top: '35%',
    left: 0,
    padding: 10
  },
  imgArrowContainerMidRight: {
    zIndex: '10',
    position: 'absolute',
    top: '35%',
    right: 0,
    padding: 10
  },
  imgAddContainerInsta: {
    zIndex: '10',
    width: '100%',
    height: '40px',
    bottom: '0px',
    background: 'rgba(55, 151, 240, 1)',
    padding: 10
  },
  imgAddButtonInsta: {
    zIndex: '10',
    top: 100,
    right: 0,
    padding: 10
  },
  imgAddContainer: {
    zIndex: '10',
    position: 'absolute',
    height: '100%',
    top: '-6px',
    right: '46%',
    opacity: 0.75
  },
  imgAddButton: {
    zIndex: '10',
    position: 'absolute',
    top: 100,
    right: 0,
    padding: 10
  },
  imgSorted: {
    opacity: 1.0
  },
  imageCarousel: {
    '&:hover': {
      opacity: 0.25
    }
  },
  rotateK: {
    transform: 'rotate(10deg)'
  },
  iconCarousel: {
    position: 'absolute',
    top: '50',
    right: '50'
  },
  gridListCss: {
    width: '100%',
    height: 450
  },
  icono: {
    color: 'green'
  },
  title: {
    color: 'white'
  },
  titleBar: {
    background: 'linear-gradient(to top, rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 70%, rgba(0,0,0,0) 100%)',
    top: '0%',
    right: '0%'
  },
  linkStyle: {
    cursor: 'pointer',
    textDecoration: 'none'
  },
  linkStyleCall: {
    cursor: 'pointer',
    textDecoration: 'none',
    fontSize: '15px!important'
  },
  emptyProfilePic: {
    width: 48,
    height: 48
  },
  sponsoredIcon: {
    marginLeft: '4px!important'
  },
  modalContainer: {
    position: 'absolute',
    width: 800,
    height: 500,
    top: 'calc(50% - 300px)',
    left: 'calc(50% - 460px)',
    outline: '0px!important'
  },
  termsHeader: {
    padding: '40px 0px 0px 40px!important'
  },
  termsIcon: {
    padding: '40px 40px 0px 0px!important'
  },
  termsItem: {
    padding: '0px 36px 0px 40px!important',
    color: `${marketerGrey}!important`
  },
  termsBodyHeader: {
    fontWeight: '700!important'
  },
  editIconHeader: {
    border: '4px',
    transition: '0.3s ease!important',
    cursor: 'pointer',
    '&:hover': {
      color: '#3e8989!important'
    }
  },
  editIconHeaderRel: {
    border: '4px',
    transition: '0.3s ease!important',
    cursor: 'pointer',
    '&:hover': {
      color: '#3e8989!important'
    },
    position: 'relative',
    top: '4px'
  },
  editIconHeaderRelDone: {
    border: '4px',
    transition: '0.3s ease!important',
    cursor: 'pointer',
    '&:hover': {
      color: '#3e8989!important'
    },
    position: 'relative',
    top: 'px'
  },
  editIconHeader_2: {
    position: 'absolute',
    top: '-5px',
    left: '0px'
  },
  editIconHeader_3: {
    position: 'absolute',
    top: '-5px',
    left: '-10px'
  },
  editIconHeader_4: {
    position: 'absolute',
    top: '-40px',
    left: '430px'
  },
  editIconsOs: {
    position: 'relative',
    left: '430px',
    top: '0px'
  },
  editIcon: {
    border: '4px',
    transition: '0.3s ease!important',
    cursor: 'pointer',
    '&:hover': {
      color: '#3e8989!important'
    }
  },
  socialImagePreviewFb: {
    width: '100%', 
    height: '100%', 
    objectFit: 'contain', 
    maxHeight: '425px'
  },
  socialImagePreviewInstagram: {
    width: '100%', 
    height: '100%', 
    objectFit: 'contain', 
    maxHeight: '375px'
  },
  editIconEmoji: {
    '& svg': {
      fontSize: 30
    },
    border: '4px',
    transition: '0.3s ease!important',
    cursor: 'pointer',
    '&:hover': {
      color: '#3e8989!important'
    }
  },
  editIconContainer: {
    transition: '0.3s ease!important',
    opacity: '0'
  },
  agreeMessage: {
    transition: '0.3s ease!important',
    opacity: '0',
    color: red
  },
  opacityActive: {
    opacity: '100'
  },
  loaderContainer: {
    position: 'absolute',
    top: 'calc(50% - 38px)',
    left: 'calc(50% - 38px)'
  },
  submitButton: {
    marginTop: '20px!important',
    marginBottom: '20px!important',
    fontWeight: '700!important',
    borderRadius: '25px!important'
  },
  EditAdButton: {
    marginTop: '5px!important',
    marginBottom: '20px!important',
    width: '100px',
    fontWeight: '800!important',
    borderRadius: '25px!important'
  },
  UploadButton: {
    width: '200px',
    fontWeight: '1200!important',
    borderRadius: '25px!important'
  },
  UploadReadyButton: {
    width: '230px',
    fontWeight: '1200!important',
    borderRadius: '25px!important'
  },
  UploadReadyButtonGallery: {
    width: '200px',
    fontWeight: '1200!important',
    borderRadius: '25px!important'
  },
  ResetAdButton: {
    marginTop: '20px!important',
    marginBottom: '20px!important',
    width: '100px',
    fontWeight: '800!important',
    borderRadius: '25px!important'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  roundButton: {
    borderRadius: '25px!important',
    fontWeight: '700!important'
  },
  roundButtonSave: {
    position: 'relative',
    left: '-1px',
    borderRadius: '25px!important',
    fontWeight: '700!important'
  },
  roundButtonTags: {
    position: 'relative',
    width: '25%',
    left: '15px',
    borderRadius: '25px!important',
    fontWeight: '700!important'
  },
  roundButtonDone: {
    position: 'relative',
    left: '-3px',
    borderRadius: '25px!important',
    fontWeight: '700!important'
  },
  roundButtonCancel: {
    position: 'relative',
    left: '2px',
    borderRadius: '25px!important',
    fontWeight: '700!important'
  },
  customTooltipLarge: {
    fontSize: 11
  },
  customTooltip: {
    fontSize: 12
  },
  adTitle: {
    color: `${marketerGrey}!important`,
    fontWeight: '700!important'
  },
  budgetWarning: {
    color: `#FF0000!important`,
    fontWeight: '700!important'
  },
  customCTAButton: {
    textTransform: 'none!important',
    color: `${marketerGrey}!important`,
    fontWeight: '600!important'
  },
  cardPreviewPaddingInsta: {
    padding: '5px 8px!important'
  },
  cardPreviewPadding: {
    padding: '16px 24px!important'
  },
  cardTextPreviewPadding: {
    padding: '0px 14px!important'
  },
  adPreviewPaper: {
    margin: '0px 40px'
  },
  noMarginButton: {
    margin: '0px!important'
  },
  agentSelect: {
    color: `${marketerGrey}!important`
  },
  smallBottomPaddingRow: {
    paddingBottom: '5px!important'
  },
  smallBottomPhotoOrderPaddingRow: {
    paddingBottom: '2px!important'
  },
  smallRowPadding: {
    paddingTop: '10px!important',
    paddingBottom: '10px!important'
  },
  adSettingInputLabel: {
    paddingTop: '4px',
    paddingLeft: '6px'
  },
  adSettingTextInputUnderline: {
    color: 'red',
    '&::before': {
      display: 'none!important'
    }
  },
  adSettingTextInputRoot: {
    borderRadius: '4px!important'
  },
  adSettingTextInputInput: {
    padding: '6px 4px 6px 0px!important'
  },
  adSettingTextInputInputDuration: {
    padding: '6px 4px 6px 18px!important'
  },
  adSettingInputAdornment: {
    marginTop: '0px!important'
  },
  redText: {
    color: 'red!important'
  },
  SelectPageButton: {
    marginTop: '5px!important',
    marginBottom: '20px!important',
    width: '125px',
    fontWeight: '800!important',
    borderRadius: '25px!important'
  },
  editCarouselButton: {
    display: 'flex',
    justifyContent: 'center'
  },
  error:{
    backgroundColor: '#FAF1E5',
    color: '#B16D20',
    borderRadius: '10px',
    borderColor: '#B16D20',
    padding: '10px',
    margin: '10px'
  }
})

const CustomIconButton = withStyles({
  root: {
    background: 'rgba(255,255,255,0.75)',
    '&:hover': {
      backgroundColor: '#fff'
    }
  }
})(IconButton)

const CustomSlider = withStyles({
  thumb: {
    height: 20,
    width: 20,
    marginTop: -8,
    marginLeft: -6
  },
  rail: {
    backgroundColor: '#000',
    height: 4
  },
  track: {
    height: 4
  },
  valueLabel: {
    left: 'calc(-50% + 4px)'
  }
})(Slider)

const CustomSelect = withStyles({
  root: {
    width: '100%!important',
    padding: '6px!important',
    paddingLeft: '10px!important',
    color: marketerGrey
  }
})(Select)

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const formatAssetsForPhotos = (assets) => {
  return assets.map((url, index) => ({
    url: url,
    order: index,
    category: ''
  }))
}

function validateAd(ad) {
  if (ad?.mediaType === "video") {
      // Check for exactly 2 assets
      if (!ad.assets || ad.assets.length !== 2) {
          return true
      }
      // Check that each asset has an imageUrl
      if (!ad.assets.every(asset => asset.imageUrl)) {
          return true
      }
  } else if (ad?.mediaType === "image") {
      // Check for at least 1 asset
      if (!ad.assets || ad.assets.length < 1) {
          return true
      }
  }

  return false
}
//* API Calls from Services
const getListing = (mls, mlsNum, setHook) => api.get(`/listings/mls/${mls}/mlsnum/${mlsNum}`, setHook)
const getTeamAgents = (brivityAgentId, setHook) => api.get(`/team-agents/user/${brivityAgentId}`, setHook) //UNLOCK -- Cargar desde el useEffect userSavedLogin en marketercontainer.js
const postFacebookAd = (data, hook) => api.post(`/facebook/ad`, data, hook)
const postImageAd = (data, hook) => api.postPhoto(`/facebook/image/upload`, data, hook)
const postSearch = (data, hook) => api.postForm_noToken(`https://jaradhull.com/pages/search.php`, data, hook)
const getAutoComplete = (mlsids, term, setHook) =>
  api.getAutoComplete(`https://jaradhull.com/pages/autocomplete.php?mlsIds=${mlsids}&term=${term}`, setHook)
const getReadyAutoComplete = (query, country, next, setHook) =>
  api.get(`/facebook/getTargetLocations?query=${query}&${next}&country=${country}`, setHook)
const getReadyTargetInterest = (query, setHook) => api.get(`/facebook/getTargetInterest?query=${query}`, setHook)
const getAutoCompletes = (site, mlsids, term, setHook) =>
  api.getAutoCompletes(`https://${site}/pages/autocomplete.php?mlsIds=${mlsids}&term=${term}`, setHook)
const getCounterAd = (setHook) => api.get(`/facebook/counts/ads`, setHook)
const getGalleryPhotos = (type, setHook) => api.get(`/facebook/gallery-images/adtype/${type}`, setHook)
const getGalleryPhotosReady = (type, setHook) => api.get(`/facebook/gallery-images/adtype/${type}`, setHook)
const itemHeader = ['listed', 'sold', 'openHouse', 'homeValue', 'buyerSearch']

const getUserPayments = (id, setHook) => api.get(`/user/paymentProfile/${id}`, setHook)
const getTeamPayments = (id, setHook) => api.get(`/team/paymentProfile/${id}`, setHook)

const getUserSubscription = (id, setHook) => api.get(`/user/subscriptionProfile/${id}`, setHook)
const getTeamSubscription = (id, setHook) => api.get(`/team/subscriptionProfile/${id}`, setHook)

const AdSettingsCard = ({
  locationObj,
  interestReadyObj,
  locationReadyObj,
  areas,
  interests,
  counter,
  locationAuto,
  inputValue,
  handleChange,
  selectedItem,
  handleDelete,
  askServerAutoComplete,
  inputValueI,
  handleChangeI,
  selectedItemI,
  handleDeleteI,
  controlledAutoCompleteI,
  handleChangeTags,
  selectedItemTags,
  handleDeleteTags,
  classes,
  userInfo,
  targetValue,
  adDuration,
  handleAdDuration,
  dailyBudget,
  handleDailyBudget,
  openRequirements,
  handleCloseRequirement,
  listingObj,
  buyerSearchObj,
  updateAdEdit,
  editAdText,
  closeAdEdit,
  handleOpen,
  linkText,
  handleEditLinkText,
  onChangeLinkText,
  onChangeTagsText,
  linkValue,
  tagsValue,
  editLinkValue,
  handleClose,
  open,
  handleTargetChange,
  handleFormatChange,
  formatValue,
  terms,
  handleTermsCheckbox,
  handleCreateAd,
  submitAttempt,
  handleSubmitAttempt,
  standalone,
  theme,
  adDetails,
  userFbAuth,
  recalculateDays,
  recalculateBudget,
  contentIndex,
  selectPhotosAction,
  teamAgents,
  selectedAgent,
  adType,
  handleAgentSelect,
  creatingAd,
  queries,
  setSelectedPaymentOption,
  handlePaymentOptionChange,
  paymentOptions,
  selectedPaymentOption,
  adSeed,
  defaultPaymentOption
}) => {
  const isVideoSeededAd = adSeed?.mediaType === 'video'
  const disablePurchaseAd = 
    creatingAd ||
    !terms ||
    !queries.website ||
    !queries.isAdmin ||
    !queries.ad_id ||
    !selectedPaymentOption ||
    !paymentOptions?.length ||  
    validateAd(adSeed)
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={standalone ? classes.cardContainerSmall : classes.cardContainer}
    >
      {standalone ? undefined : (
        <Grid item xs={12} className={classes.fullContainer}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item xs={6}>
              {standalone ? undefined : (
                <Button
                  color="primary"
                  size="large"
                  component={Link}
                  to="/listings"
                  className={`${classes.buttonStyles}`}
                  startIcon={<ArrowBackIcon />}
                >
                  View all listings
                </Button>
              )}
            </Grid>
            <Grid item xs={6} className={classes.alignEnd}>
              {standalone ? undefined : (
                <Button
                  color="primary"
                  size="large"
                  component={Link}
                  to="/dashboard"
                  className={`${classes.buttonStyles}`}
                >
                  Cancel
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
      {adType !== 'homeValue' && adType !== 'buyerSearch' && adType !== 'multiple' && adType !== 'readyMade' && (
        <Grid item xs={12}>
          <Typography variant="h5" className={`${classes.heavyFontWeight} ${classes.black}`}>
            {listingObj.address.street}
          </Typography>
        </Grid>
      )}
      {adType === 'buyerSearch' && (
        <Grid item xs={12}>
          <Typography variant="h5" className={`${classes.heavyFontWeight} ${classes.black}`}>
            {buyerSearchObj.total + ' Listings Found'}
          </Typography>
        </Grid>
      )}
      {adType === 'multiple' && (
        <Grid item xs={12}>
          <Typography variant="h5" className={`${classes.heavyFontWeight} ${classes.black}`}>
            {'Multi-Listing Ad Campaign'}
          </Typography>
        </Grid>
      )}
      {!editAdText && (
        <Grid item xs={12} className={`${classes.sliderContainer}`}>
          <Typography
            id="ad-duration-label"
            variant="body2"
            gutterBottom
            className={`${classes.trueBoldFontWeight} ${classes.marketerGrey}`}
          >
            AD DURATION
          </Typography>
          <CustomSlider
            value={adDuration}
            onChange={handleAdDuration}
            aria-labelledby="ad-duration-label"
            valueLabelDisplay="auto"
            min={3}
            max={30}
          />
          <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={1}>
            <Grid item xs={2}>
              <TextField
                type="number"
                variant="filled"
                value={adDuration}
                className={classes.marketerGrey}
                InputProps={{
                  classes: {
                    root: classes.adSettingTextInputRoot,
                    input: classes.adSettingTextInputInputDuration,
                    underline: classes.adSettingTextInputUnderline
                  },
                  inputProps: { max: 30, min: 3 }
                }}
                onChange={(e) => handleAdDuration(e)}
                onBlur={recalculateDays}
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <Typography
                id="ad-duration-label"
                variant="body2"
                gutterBottom
                className={`${classes.boldFontWeight} ${classes.black}`}
              >
                days
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}

      {!editAdText && (
        <Grid item xs={12} className={`${classes.sliderContainer}`}>
          {adDuration * dailyBudget < 50 ? (
            <Typography
              id="ad-duration-label"
              variant="body2"
              gutterBottom
              className={`${classes.trueBoldFontWeight} ${classes.budgetWarning}`}
            >
              Based on your current budget, you may not get sufficient lead conversions. Consider increasing your daily
              budget or ad duration.
            </Typography>
          ) : (
            <Typography
              id="ad-duration-label"
              variant="body2"
              gutterBottom
              className={`${classes.trueBoldFontWeight} ${classes.marketerGrey}`}
            >
              DAILY BUDGET
            </Typography>
          )}

          <CustomSlider
            value={dailyBudget}
            onChange={handleDailyBudget}
            aria-labelledby="ad-duration-label"
            valueLabelDisplay="auto"
            min={10}
            max={300}
            valueLabelFormat={(value) => <div>${value}</div>}
          />
          <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={1}>
            <Grid item xs={5}>
              <div className={classes.flexContainer}>
                <TextField
                  type="number"
                  InputProps={{
                    classes: {
                      root: classes.adSettingTextInputRoot,
                      input: classes.adSettingTextInputInput,
                      underline: classes.adSettingTextInputUnderline
                    },
                    startAdornment: (
                      <InputAdornment position="start" className={`${classes.blue} ${classes.adSettingInputAdornment}`}>
                        $
                      </InputAdornment>
                    ),
                    inputProps: { max: 1000, min: 10 }
                  }}
                  value={dailyBudget}
                  className={classes.marketerGrey}
                  onChange={(e) => handleDailyBudget(e)}
                  variant="filled"
                  onBlur={recalculateBudget}
                />{' '}
                <Typography
                  id="ad-duration-label"
                  variant="body2"
                  gutterBottom
                  className={`${classes.boldFontWeight} ${classes.black} ${classes.adSettingInputLabel}`}
                >
                  /day
                </Typography>
              </div>
            </Grid>
            <Grid item xs={7} className={classes.alignEnd}>
              <Typography variant="caption" className={`${classes.darkGrey}`}>
                (A higher daily budget produces more leads)
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}

      {!editAdText && queries.adType === 'readyMade' && (
        <Grid item xs={12} className={`${classes.sliderContainer}`}>
          <Typography
            id="ad-duration-label"
            variant="body2"
            gutterBottom
            className={`${classes.trueBoldFontWeight} ${classes.marketerGrey}`}
          >
            TARGET AUDIENCE
          </Typography>
          <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={1}>
            <Grid item xs={11}>
              <Typography
                id="modal-content"
                style={{
                  color: 'black',
                  fontWeight: 'bolder',
                  fontSize: '12px',
                  marginLeft: 0
                }}
              >
                {'Areas: ' + areas}
              </Typography>
              <Typography
                id="modal-content"
                style={{
                  color: 'black',
                  fontWeight: 'bolder',
                  fontSize: '12px',
                  marginLeft: 0
                }}
              >
                {'Interest: ' + interests}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}

      {!editAdText && queries.adType !== 'readyMade' && (
        <Grid item xs={12} className={classes.fullContainer}>
          <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.fullContainer}>
            <Grid item xs={1}>
              <CheckRoundedIcon className={classes.marketerBlue} fontSize="large" />
            </Grid>
            <Grid item xs={11}>
              <Typography variant="body2" className={classes.darkestGrey}>
                {queries.adType === 'homeValue'
                  ? queries.mlsAddress !== undefined
                    ? 'Ad set to target area: ' + queries.mlsAddress
                    : ''
                  : "We've auto-targeted the best audience for your ad."}{' '}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {!editAdText && (
        <Grid item xs={12} className={classes.fullContainer}>
          <div
            style={{
              width: '100%!important',
              borderBottom: `1px solid #E6E6E6`
            }}
          />
        </Grid>
      )}
      {!editAdText && (
        <Grid item xs={12} className={classes.fullContainer} classes={{ root: classes.smallRowPadding }}>
          <Grid container direction="row" justify="space-between" align="center">
            <Grid item xs={6} className={classes.alignStart}>
              <Typography variant="subtitle1" className={classes.darkestGrey}>
                Facebook Ad Budget
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.alignEnd}>
              <Typography variant="subtitle1" className={classes.darkestGrey}>
                $
                {(adDuration * dailyBudget).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {!editAdText && adDetails.credit >= 0 && (
        <Grid item xs={12} className={classes.fullContainer} classes={{ root: classes.smallRowPadding }}>
          <Grid container direction="row" justify="space-between" align="center">
            <Grid item xs={6} className={classes.alignStart}>
              <Typography variant="subtitle1" className={classes.darkestGrey}>
                Credit
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.alignEnd}>
              <Typography variant="subtitle1" className={classes.darkestGrey}>
                $
                {adDetails.credit.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {!editAdText && (
        <Grid item xs={12} className={classes.fullContainer} classes={{ root: classes.smallRowPadding }}>
          <Grid container direction="row" justify="space-between" align="center">
            <Grid item xs={6} className={classes.alignStart}>
              <Typography variant="subtitle1" className={classes.darkestGrey}>
                Service Fee
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.alignEnd}>
              <Typography variant="subtitle1" className={classes.darkestGrey}>
                $
                {(adDuration * dailyBudget * 0.125).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {!editAdText && (
        <Grid item xs={12} className={classes.fullContainer} classes={{ root: classes.smallRowPadding }}>
          <Grid container direction="row" justify="space-between" alignItems="flex-end">
            <Grid item xs={6} className={classes.alignStart}>
              <Typography variant="subtitle1" className={`${classes.darkestGrey}`}>
                Total
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.alignEnd}>
              {adDetails.credit > adDuration * dailyBudget * 0.125 + adDuration * dailyBudget ? (
                <Typography variant="subtitle1" className={`${classes.black} ${classes.heavyFontWeight}`}>
                  $
                  {(0).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </Typography>
              ) : (
                adDetails.credit < adDuration * dailyBudget * 0.125 + adDuration * dailyBudget && (
                  <Typography variant="subtitle1" className={`${classes.black} ${classes.heavyFontWeight}`}>
                    $
                    {(adDuration * dailyBudget * 0.125 + adDuration * dailyBudget - adDetails.credit).toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }
                    )}
                  </Typography>
                )
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
      {!editAdText && adDetails.credit > adDuration * dailyBudget * 0.125 + adDuration * dailyBudget && (
        <Grid item xs={12} className={classes.fullContainer} classes={{ root: classes.smallRowPadding }}>
          <Grid container direction="row" justify="space-between" alignItems="flex-end">
            <Grid item xs={6} className={classes.alignStart}></Grid>
            <Grid item xs={6} className={classes.alignEnd}>
              <Typography variant="subtitle1" className={`${classes.greencolor} ${classes.heavyFontWeight}`}>
                {'credit left: ' +
                  (adDetails.credit - (adDuration * dailyBudget * 0.125 + adDuration * dailyBudget)).toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }
                  )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {!editAdText ? (
        <>
          <Grid item xs={12} className={classes.fullContainer} classes={{ root: classes.smallRowPadding }}>
            <Grid container direction="row" justify="space-between" alignItems="center">
              {paymentOptions?.length && 
                <>
                  <Grid item xs={6} className={classes.alignStart}>
                    <Typography variant="subtitle1" className={classes.darkestGrey}>
                      Charge To
                    </Typography>
                  </Grid>
                  <Grid item xs={6} className={classes.alignEnd}>
                    <Select
                      fullWidth
                      value={selectedPaymentOption?.paymentProfileId || ''} // Using paymentProfileId or an empty string if undefined
                      onChange={handlePaymentOptionChange}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Select payment option' }}
                    >
                      {paymentOptions.map((option, index) => (
                        <MenuItem key={`${option.id}-${Math.random() * 1000}-${index}`} value={option.id}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </>
              }
            </Grid>
          </Grid>
        {selectedPaymentOption?.paymentProfileId !== defaultPaymentOption?.id && (
          <div className={classes.error}>
            <p>This ad will be purchased with card {selectedPaymentOption?.maskedCardNumber}, which is not your default payment method.</p>
          </div>
        )}
          <Grid item xs={12} className={classes.fullContainer}>
            <Grid container direction="row" justify="center" alignItems="center">
              <Grid item xs={7}>
                <div className={classes.flexContainer}>
                  <Checkbox
                    color="primary"
                    inputProps={{
                      'aria-label': 'terms and conditions agreement checkbox'
                    }}
                    defaultChecked={terms}
                    onChange={handleTermsCheckbox}
                  />
                  <Typography variant="body2" className={classes.darkestGrey}>
                    I agree to the{' '}
                    <span
                      className={`${classes.linkStyle}`}
                      style={{ color: theme.palette.primary.main }}
                      onClick={handleOpen}
                    >
                      terms of use
                    </span>
                    .
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={5}>
                <Button
                  fullWidth
                  disabled={disablePurchaseAd}
                  variant="contained"
                  color="primary"
                  size="large"
                  className={standalone && classes.submitButton}
                  classes={{ root: classes.noMarginButton }}
                  onClick={creatingAd ? undefined : handleCreateAd}
                  disableElevation
                >
                  Purchase Ad
                  {creatingAd && <CircularProgress size={24} color="primary" className={classes.buttonProgress} />}
                </Button>
              </Grid>
            </Grid>
            <Modal
              open={openRequirements}
              onClose={handleCloseRequirement}
              aria-labelledby="modal-BasicReq"
              aria-describedby="modal-contentReq"
            >
              <Paper className={classes.modalContainer} elevation={3} style={{ outline: 0 }}>
                <Grid container direction="column" justify="center" alignItems="flex-start">
                  <Grid item xs={12} className={`${classes.noLeftPadding} ${classes.fullContainer}`}>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                      <Grid item xs={11} className={classes.noLeftPadding}>
                        <Typography
                          id="modal-title"
                          variant="h4"
                          style={{ color: theme.palette.primary.main }}
                          className={classes.termsHeader}
                        >
                          Basic requirements
                        </Typography>
                      </Grid>
                      <Grid item xs={1} className={classes.termsIcon}>
                        <IconButton aria-label="close Basic requirements modal" onClick={handleCloseRequirement}>
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <br />
                  <Typography id="modal-content" variant="body2">
                    <Grid item xs={12} className={classes.termsItem}>
                      Before you can create Facebook ad campaigns in Brivity Marketer, you need to set up a Facebook
                      Business Manager that owns both your business's Facebook Page If you have a personal ad account,
                      then you need to connect it to a Business Manager. If you've never run ads with your personal ad
                      account, then you need to create a new ad account inside Business Manager before you can create
                      Facebook ad campaigns. Learn more about Business Manager If you don't have a Business Manager,
                      then you can set one up when you're setting up the Facebook channel.
                    </Grid>
                    <br />
                    <Grid item xs={12} className={`${classes.termsItem} `}>
                      <span className={classes.termsBodyHeader}>Facebook Page settings</span>
                    </Grid>
                    <Grid item xs={12} className={classes.termsItem}>
                      The following requirements need to be met on Facebook before you can connect your Facebook Page:
                    </Grid>
                    <br />
                    <Grid item xs={12} className={classes.termsItem}>
                      - Your Facebook account needs to have an admin role on the Page.
                    </Grid>
                    <br />
                    <Grid item xs={12} className={classes.termsItem}>
                      - The Facebook page needs to be published.
                    </Grid>
                    <br />
                    <Grid item xs={12} className={classes.termsItem}>
                      - You can be an admin on multiple Facebook pages, and access them all using a single Facebook
                      Business Manager. However, every Facebook business page can only be owned by a single Facebook
                      Business Manager. You need to be the admin of both the Facebook Business Manager that owns a
                      specific Facebook page and the Facebook page itself to connect it with the Facebook channel in
                      Brivity Marketer.
                    </Grid>
                  </Typography>
                </Grid>
              </Paper>
            </Modal>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-content">
              <Paper className={classes.modalContainer} elevation={3} style={{ outline: 0 }}>
                <Grid container direction="column" justify="center" alignItems="flex-start">
                  <Grid item xs={12} className={`${classes.noLeftPadding} ${classes.fullContainer}`}>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                      <Grid item xs={11} className={classes.noLeftPadding}>
                        <Typography
                          id="modal-title"
                          variant="h4"
                          style={{ color: theme.palette.primary.main }}
                          className={classes.termsHeader}
                        >
                          Terms of Use
                        </Typography>
                      </Grid>
                      <Grid item xs={1} className={classes.termsIcon}>
                        <IconButton aria-label="close terms of use modal" onClick={handleClose}>
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <br />
                  <Typography id="modal-content" variant="body2">
                    <Grid item xs={12} className={`${classes.termsItem}`}>
                      <span className={classes.termsBodyHeader}>PURCHASES AND PAYMENT</span>
                    </Grid>
                    <Grid item xs={12} className={classes.termsItem}>
                      Brivity will charge you through an online billing account managed by a third-party service
                      provider for the purchase of products and/or services. By purchasing, you agree to pay Brivity for
                      all charges at the current prices for the products you or others using your billing account may
                      acquire. You authorize Brivity to charge your selected payment provider for such purchases and
                      agree to make payment using the chosen payment method. For products or services subject to
                      recurring charges, you consent to Brivity charging your payment method on a recurring basis
                      without requiring prior approval for each charge until you cancel the applicable product or
                      service. Brivity reserves the right to correct pricing errors or mistakes even if payment has
                      already been requested or received. Sales tax may be added to purchase prices as determined by
                      Brivity. Prices are subject to change, and all payments must be made in U.S. dollars.
                    </Grid>
                    <br />
                    <Grid item xs={12} className={`${classes.termsItem} `}>
                      <span className={classes.termsBodyHeader}>REFUND POLICY</span>
                    </Grid>
                    <Grid item xs={12} className={classes.termsItem}>
                      All service fees and sales fees are non-refundable.
                    </Grid>
                    <br />
                    <Grid item xs={12} className={classes.termsItem}>
                      You may discontinue your Facebook Ad Campaigns at any time; however, you will not be able to
                      resume the Campaign once it has been stopped.
                    </Grid>
                  </Typography>
                </Grid>
              </Paper>
            </Modal>
          </Grid>
        </>
      ) : adType === 'readyMade' ? (
        <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={1}>
          {!isVideoSeededAd ? (
            <>
              <Grid item xs={10} className={classes.TopPaddingLink}>
                <Typography
                  id="modal-content"
                  style={{
                    color: 'black',
                    fontWeight: 'bolder',
                    fontSize: '13px',
                    marginLeft: 0
                  }}
                >
                  GALLERY OF CUSTOM ADS:
                </Typography>
              </Grid>
              <Grid item xs={10} className={classes.TopPaddingLink10}>
                <Button
                  variant="outlined"
                  color="primary"
                  size={'small'}
                  className={queries.adType === 'readyMade' ? classes.UploadReadyButton : classes.UploadButton}
                  id="select-different-ad"
                  onClick={selectPhotosAction}
                  style={{
                    border: '3px solid',
                    fontSize: '15px',
                    fontWeight: 'bold'
                  }}
                >
                  SELECT DIFFERENT AD
                </Button>
              </Grid>
            </>
          ) : null}
          <Grid item xs={10} className={classes.TopPaddingLink}>
            <Typography
              id="modal-content"
              style={{
                color: 'black',
                fontWeight: 'bolder',
                fontSize: '13px',
                marginLeft: 0
              }}
            >
              TARGET AREA:
            </Typography>
            <RadioGroup aria-label="quiz" name="quiz" value={targetValue} onChange={handleTargetChange} row>
              <FormControlLabel value="US" control={<Radio color="primary" />} label="United States" />
              <FormControlLabel value="CA" control={<Radio color="primary" />} label="Canada" />
            </RadioGroup>
          </Grid>
          <Grid item xs={10} className={classes.TopPaddingLink10}>
            <Autocomplete
              disableClearable
              id="combo-box-location"
              closeIcon={<SearchIcon fontSize="small" />}
              classes={{
                input: classes.headDisplayAutoComplete
              }}
              options={
                locationReadyObj !== null
                  ? locationReadyObj.data
                  : [
                      {
                        region: '',
                        name: 'Search by city or zip'
                      }
                    ]
              }
              groupBy={(option) => option.region}
              getOptionLabel={(option) => option.name}
              value={locationAuto}
              noOptionsText={'No options'}
              inputValue={inputValue}
              onChange={handleChange}
              onInputChange={askServerAutoComplete}
              style={{ width: '450px', height: '40px' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  placeholder="Search by city or zip"
                  InputProps={{ ...params.InputProps, disableUnderline: true }}
                />
              )}
            />
          </Grid>
          <Grid container justify="left" item xs={12} className={classes.PaddingTopBottom}>
            {selectedItem.length > 0 &&
              selectedItem.map((item, i) => (
                <Chip
                  key={`${item.name}-${i}`}
                  className={classes.chip}
                  label={item.name}
                  deleteIcon={<CancelIcon />}
                  onDelete={handleDelete(item)}
                />
              ))}
          </Grid>
          <Grid item xs={10} className={classes.TopPaddingLink}>
            <Typography
              id="modal-content"
              style={{
                color: 'black',
                fontWeight: 'bolder',
                fontSize: '13px',
                marginLeft: 0
              }}
            >
              TARGET INTEREST:
            </Typography>
          </Grid>
          <Grid item xs={10} className={classes.TopPaddingLink10}>
            <Autocomplete
              disableClearable
              id="combo-box-location"
              closeIcon={<SearchIcon fontSize="small" />}
              classes={{
                input: classes.headDisplayAutoComplete
              }}
              options={
                interestReadyObj !== null
                  ? interestReadyObj.data
                  : [
                      {
                        name: 'Search by interest'
                      }
                    ]
              }
              getOptionLabel={(option) => option.name}
              noOptionsText={'No options'}
              inputValue={inputValueI}
              onChange={handleChangeI}
              onInputChange={controlledAutoCompleteI}
              style={{ width: '450px', height: '40px' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  placeholder="Choose interests applicable to your target audience"
                  InputProps={{ ...params.InputProps, disableUnderline: true }}
                />
              )}
            />
          </Grid>
          <Grid container justify="left" item xs={12} className={classes.PaddingTopBottom}>
            {selectedItemI.length > 0 &&
              selectedItemI.map((item, i) => (
                <Chip
                  key={`selected-item-${item.name}-${i}`}
                  className={classes.chip}
                  label={item.name}
                  deleteIcon={<CancelIcon />}
                  onDelete={handleDeleteI(item)}
                />
              ))}
          </Grid>

          <Grid item xs={10} className={classes.TopPaddingLink}>
            <Typography
              id="modal-content"
              style={{
                color: 'black',
                fontWeight: 'bolder',
                fontSize: '13px',
                marginLeft: 0
              }}
            >
              CALL TO ACTION URL LINK:
            </Typography>
          </Grid>
          <Grid item xs={10} style={{ border: editLinkValue ? '1px solid grey' : 'none' }}>
            {editLinkValue ? (
              <TextField
                InputProps={{
                  className: classes.input,
                  disableUnderline: true
                }}
                disabled={!editLinkValue}
                id="fullLink"
                className={classes.linkTitleWidth}
                value={linkValue || ''}
                onChange={onChangeLinkText}
              />
            ) : (
              <a
                style={{ color: '#818181!important' }}
                onClick={() =>
                  window.open(
                    linkValue.includes('https://') || linkValue.includes('http://')
                      ? linkValue
                      : 'https://' + linkValue,
                    '_blank'
                  )
                }
              >
                <span className={`${classes.linkStyleCall}`} style={{ color: theme.palette.primary.main }}>
                  {linkValue}
                </span>
              </a>
            )}
          </Grid>
          <Grid item xs={2}>
            <IconButton
              style={{ padding: '1px' }}
              id={'insert-emoticon-edit-2'}
              onClick={handleEditLinkText}
              color="primary"
              className={editLinkValue ? classes.editIconHeaderRelDone : classes.editIconHeaderRel}
            >
              {editLinkValue ? <DoneIcon /> : <EditOutlinedIcon />}
            </IconButton>
          </Grid>
        </Grid>
      ) : adType === 'homeValue' ? (
        <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={1}>
          <Grid item xs={10} className={classes.TopPaddingLink}>
            <Typography
              id="modal-content"
              style={{
                color: 'black',
                fontWeight: 'bolder',
                fontSize: '10px',
                marginLeft: 0
              }}
            >
              CALL TO ACTION URL LINK:
            </Typography>
          </Grid>
          <Grid item xs={10} style={{ border: editLinkValue ? '1px solid grey' : 'none' }}>
            {editLinkValue ? (
              <TextField
                InputProps={{
                  className: classes.input,
                  disableUnderline: true
                }}
                disabled={!editLinkValue}
                id="fullLink"
                className={classes.linkTitleWidth}
                value={linkValue || ''}
                onChange={onChangeLinkText}
              />
            ) : (
              <a
                style={{ color: '#818181!important' }}
                onClick={() =>
                  window.open(
                    linkValue.includes('https://') || linkValue.includes('http://')
                      ? linkValue
                      : 'https://' + linkValue,
                    '_blank'
                  )
                }
              >
                <span className={`${classes.linkStyleCall}`} style={{ color: theme.palette.primary.main }}>
                  {linkValue}
                </span>
              </a>
            )}
          </Grid>
          <Grid item xs={2}>
            <IconButton
              style={{ padding: '1px' }}
              id={'insert-emoticon-edit-2'}
              onClick={handleEditLinkText}
              color="primary"
              className={editLinkValue ? classes.editIconHeaderRelDone : classes.editIconHeaderRel}
            >
              {editLinkValue ? <DoneIcon /> : <EditOutlinedIcon />}
            </IconButton>
          </Grid>
        </Grid>
      ) :(adSeed?.mediaType === "image" && adSeed?.assets?.length === 1 )? null:(
        adType !== 'buyerSearch' &&
        adType !== 'multiple' &&
        adType !== 'readyMade' && 
        adSeed?.mediaType !== "video" ?(
      <Grid item xs={12} className={classes.fullContainerExp} classes={{ root: classes.smallBottomPaddingRow }}>
            <Typography
              id="ad-duration-label"
              variant="body2"
              gutterBottom
              className={`${classes.trueBoldFontWeight} ${classes.marketerGrey}`}
            >
              FORMAT
            </Typography>
             <div style={{ width: 500 }}>
              <RadioGroup aria-label="quiz" name="quiz" value={formatValue} onChange={handleFormatChange}>
                <FormControlLabel value="single" control={<Radio color="primary" />} label="Single Photo" />
                <FormControlLabel value="multiple" control={<Radio color="primary" />} label="Carousel Photos" />
              </RadioGroup>

              {formatValue === 'multiple' && (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.roundButton}
                  onClick={selectPhotosAction}
                  disableElevation
                >
                  SELECT PHOTOS
                </Button>
              )}
            </div>
            
          </Grid>
        
      ): null)}

      <Typography
        variant="caption"
        className={`${classes.agreeMessage} ${
          submitAttempt && (!terms || !adDetails.page_id) && classes.opacityActive
        }`}
      >
        {submitAttempt && !adDetails.page_id
          ? 'You need to select a Facebook Page before proceeding.'
          : submitAttempt && !terms && 'You need to agree to the terms of use before proceeding.'}
      </Typography>
      {editAdText && (
        <Grid item xs={12} className={classes.fullContainer}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item xs={10} className={classes.TopPaddingLink10}>
              <Typography
                id="modal-content"
                style={{
                  color: 'black',
                  fontWeight: 'bolder',
                  fontSize: '13px',
                  marginLeft: adType === 'readyMade' ? '-10px' : 0
                }}
              >
                TAGS (for internal use; not applied on the ad):
              </Typography>
            </Grid>
            <Grid item xs={10} className={classes.TopPaddingLink10}>
              <TextField
                InputProps={{
                  className: classes.input2
                }}
                id="fullTags"
                className={classes.linkTagsWidth}
                value={tagsValue || ''}
                onChange={onChangeTagsText}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    handleChangeTags()
                  }
                }}
              />
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.roundButtonTags}
                onClick={handleChangeTags}
                disableElevation
              >
                Add Tag
              </Button>
            </Grid>

            <Grid container justify="left" item xs={12} className={classes.PaddingTopBottom}>
              {selectedItemTags.length > 0 &&
                selectedItemTags.map((item, i) => (
                  <Chip
                    key={`selected-item-${item.name}-${i}`}
                    className={classes.chip}
                    label={item.name}
                    deleteIcon={<CancelIcon />}
                    onDelete={handleDeleteTags(item)}
                  />
                ))}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            className={classes.fullContainer}
            style={{
              marginTop: '80px'
            }}
          >
            <Typography
              id="modal-content"
              style={{
                color: 'black',
                fontSize: '13px',
                marginLeft: adType === 'readyMade' ? '-10px' : 0
              }}
            >
              <strong>Note:</strong> Lead routing occurs based off of the current settings in the CRM and is not based
              the user who runs the ad. To learn more about the lead routing settings in the CRM, check out this{' '}
              <a href="https://www.brivityknowledge.com/docs/lead-routing-1">knowledgebase article here</a>! If you are
              an agent level user, please ask a team admin for assistance.
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

const AdPreviewCard = ({
  classes,
  adDetails,
  buyerSearchObj,
  multipleLisObj,
  linkValue,
  contentIndex,
  adLayout,
  listHeaderValue,
  setListHeaderValue,
  adtypeSelected,
  handelEditHeaderText,
  setEditHeaderValue,
  editHeaderValue,
  inputEl,
  onChangeHeaderText,
  inputElAd,
  valueHeaderList,
  headerValue,
  setHeaderValue,
  openHeaderList,
  onChangeHeaderList,
  onOpenHeaderList,
  onCloseHeaderList,
  openEmojiPicker,
  headerAdLines,
  startInstaMode,
  closeInstaMode,
  instaPreviewMode,
  handleAdLayoutChange,
  listingObj,
  filteredPhotos,
  localSorted,
  updateAdEdit,
  editAdText,
  closeAdEdit,
  formatValue,
  handleAdTextUpdate,
  setUpdatedAdText,
  updatedAdText,
  resetUpdatedAdtext,
  prevIndex,
  nextIndex,
  index,
  prevIndexPreview,
  nextIndexPreview,
  indexPreview,
  standalone,
  userFbAuth,
  editPageAction,
  selectPhotosAction,
  queries,
  showTooltip,
  uploadFileClick,
  theme,
  isFirstRender,
  adSeed,
  setAdDetails
}) => {
  let [visiblePageEditIcon, setVisiblePageEditIcon] = useState(false)
  let [visibleAdTextEditIcon, setVisibleAdTextEditIcon] = useState(false)
  let [statePos, setStatePos] = useState(0)
  const [isHelpingMeWrite, setIsHelpingMeWrite] = useState({
    title: false,
    description: false
  })
  const enablePageIcon = () => setVisiblePageEditIcon(true)
  const disablePageIcon = () => setVisiblePageEditIcon(false)
  const enableTextIcon = () => setVisibleAdTextEditIcon(true)
  const disableTextIcon = () => setVisibleAdTextEditIcon(false)

  const optionalFeatures = {
    Formalize: 'make it more formal',
    'Relax Tone': 'make it less formal',
    'Make Shorter': 'make it shorter. Limit your response to around 150 characters',
    'Make Longer': 'make it longer',
    'Add more emojis': 'add more emojis',
    Rephrase: 'rephrase'
  }

  const createSocket = () => {
    const socket = io(
      `${process.env.REACT_APP_BRIVITYAI_URL}api/socket/social-ads`,
      {
          path: "/api/socket",
          transports: ["websocket"],
          auth: { token: queries.token_alf },
      }
    )

    return socket
  }

  const getAiResponse = (socket, setHook, loadingHook, payload) => {
    loadingHook({ ...isHelpingMeWrite, [payload.contentType]: true })

    socket.connect()
    socket.emit('payload', payload)

    let updatedAd = ''
    socket.on('token', (data) => {
      updatedAd += data
      setHook(updatedAd)
    })

    socket.on('final', () => {
      loadingHook({ ...isHelpingMeWrite, [payload.contentType]: false })
    })
  }

  const createBuyerSearchObject = (searchData) => {
    return searchData?.map((listing) => ({
      acreage: listing.acreage || '',
      address: listing.address || '',
      bedrooms: listing.bedrooms || '',
      price: listing.price || '',
      propertyType: listing.propertyType || '',
      sqFeet: listing.sqFeet || '',
      totalBaths: listing.totalBaths || '',
      year: listing.year || '',
      location: listing.location || '',
      mainPhoto: listing.main_photo || '',
      status: listing.mlsStatus || '',
      taxes: {
        amount: listing.taxes.amount || '',
        year: listing.taxes.year || ''
      },
      priceFormatted: listing.price_formatted || '',
      hasOpenhouses: listing.hasOpenhouses || '',
      statusText: listing.statusText || ''
    }))
  }

  const handleHelpMeWrite = async (instructions, mode, loadingHook = setIsHelpingMeWrite) => {
    const descriptionSocket = createSocket()
    const titleSocket = createSocket()
    const adType = queries.adType

    const aiListingData = []

    if (adType === 'buyerSearch') {
      const dynamicObject = createBuyerSearchObject(buyerSearchObj?.data)
      aiListingData.push(...dynamicObject)
    }

    // carousel ads
    if (adType === "multiple") {
      (multipleLisObj || []).forEach(multipleListing => {
        aiListingData.push({
          acreage: multipleListing.listing.acreage || '',
          address: multipleListing.listing.address || '',
          bedrooms: multipleListing.listing.bedrooms || '',
          communityFeatures: multipleListing.listing.communityFeatures || '',
          exteriorFeatures: multipleListing.listing.exteriorFeatures || '',
          financialInformation: multipleListing.listing.financialInformation || '',
          interiorFeatures: multipleListing.listing.interiorFeatures || '',
          location: multipleListing.listing.location || '',
          lotFeatures: multipleListing.listing.lotFeatures || '',
          price: multipleListing.listing.price || '',
          propertyFeatures: multipleListing.listing.propertyFeatures || '',
          remarks: multipleListing.listing.remarks || '',
          sqFeet: multipleListing.listing.sqFeet || '',
          standardPropertyType: multipleListing.listing.standardPropertyType || '',
          totalBaths: multipleListing.listing.totalBaths || '',
          year: multipleListing.listing.year || ''
        })
      })
    }

    
    const multipleListingsAdTypes = ["multiple", "buyerSearch", "readyMade", "homeValue"]
    if(!multipleListingsAdTypes.includes(adType) && listingObj){
      const {photos, mainPhoto, ...rest} = listingObj
      aiListingData.push(rest)
    }

    const defaultPayload = {
      adType,
      listingDetails: `${JSON.stringify(aiListingData)}`,
      previousText: updatedAdText || '',
      instructions: `${optionalFeatures[mode] || instructions}`
    }

    getAiResponse(descriptionSocket, handleAdTextUpdate, loadingHook, {
      ...defaultPayload,
      contentType: 'description'
    })
    getAiResponse(titleSocket, setHeaderValue, loadingHook, {
      ...defaultPayload,
      contentType: 'title'
    })
  }

  const handleLandingPage = () => {
    // this case should not be possible, but nice to have just in case.
    if (!queries.website) return;
  
    const baseWebsite = queries.website.endsWith('/') ? queries.website : `${queries.website}/`;
    const fullUrl = `${baseWebsite}mls/landing/${listingObj.mlsNum}`;
  

    const secureUrl = fullUrl.startsWith('http://') || fullUrl.startsWith('https://') 
                      ? fullUrl 
                      : `https://${fullUrl}`;
  
    return window.open(secureUrl, '_blank');
  };
    

  const handleEditTextIconClick = () => {
    updateAdEdit()
    disableTextIcon()
  }

  useEffect(() => {
    const noAiFirstRender = ['readyMade', 'homeValue']
    if (!noAiFirstRender.includes(queries.adType) && isFirstRender) {
      const helpMeWriteOnLoad = async () => {
        await handleHelpMeWrite('limit your response to around 150 characters', '')
      }
      helpMeWriteOnLoad()
    }
  }, [])

  const videoSeeds = useMemo(() => {
    if (adSeed?.mediaType === 'video' && adSeed?.assets?.length) {
      return adSeed.assets.map((video) => ({
        videoURL: video.url,
        videoRatio: video.aspectRatio
      }))
    }
    return []
  }, [adSeed])

  return (
    <Fragment>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={standalone ? classes.cardContainerSmall : classes.cardContainer}
      >
        <Grid
          item
          xs={12}
          className={classes.fullContainer}
          onMouseEnter={enablePageIcon}
          onMouseLeave={disablePageIcon}
        >
          <Grid container direction="row" justify="flex-start" alignItems="left">
            <Grid item xs={2}>
              <div className={classes.emptyProfilePic}>
                <div
                  style={
                    adDetails.page_profile_img
                      ? {
                          backgroundImage: `url(${adDetails.page_profile_img})`
                        }
                      : { background: '#666' }
                  }
                  className={`${adSeed?.mediaType ? classes.mainListingImage : classes.mainListingImageLegacy} ${classes.pageProfileImg}`}
                />
              </div>
            </Grid>
            <Grid item xs={7}>
              <Typography
                variant="body1"
                className={`${classes.boldFontWeight} ${classes.black} ${classes.fullContainerReal} `}
              >
                <span className={`${!adDetails.page_name ? classes.redText : ''}`}>
                  {trimText(adDetails.page_name ? adDetails.page_name : 'No Page Selected', 43, false)}
                </span>
                <Typography
                  variant="body1"
                  className={`${classes.black} ${classes.lightFontWeight} ${classes.fullContainerReal2}`}
                >
                  Sponsored •{' '}
                </Typography>

                <PublicIcon className={`${classes.black} ${classes.sponsoredIcon} ${classes.fullContainerReal3}`} />
              </Typography>
            </Grid>

            <Grid item xs={3} className={`${classes.alignEnd} ${classes.editIconContainer} ${classes.opacityActive}`}>
              {editAdText ? (
                <>
                  <Button
                    variant="outlined"
                    color="primary"
                    size={'large'}
                    className={classes.SelectPageButton}
                    onClick={editPageAction}
                    style={{ border: '2px solid' }}
                  >
                    Select page
                  </Button>
                </>
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  size={'large'}
                  className={classes.EditAdButton}
                  onClick={updateAdEdit}
                  style={{ border: '2px solid' }}
                >
                  Edit Ad
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
        {editAdText && (
          <Grid item xs={12} className={`${classes.alignEnd} ${classes.fullWidth} ${classes.noBottomPadding}`}>
            <HelpMeWrite
              handleHelpMeWrite={handleHelpMeWrite}
              isHelpingMeWrite={isHelpingMeWrite}
              style={{ width: '102%', paddingBottom: '1rem' }}
            />
            {queries.adType === 'multiple' && (
              <Grid container direction="row" justify="flex-start" alignItems="left">
                <Typography variant="h6" align="left" className={`${classes.heavyFontWeight} ${classes.black}`}>
                  {queries.adType !== 'multiple'
                    ? '(Dynamic) X Beds | X Baths | X Sqft'
                    : multipleLisObj[statePos]?.fieldValues?.fullAddress}
                </Typography>
              </Grid>
            )}
            {openHeaderList === false ? (
              queries.adType !== 'multiple' && (
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="left"
                  style={{ border: '1px solid grey' }}
                >
                  <Grid item xs={11} className={`${classes.alignStart}`}>
                    <TextField
                      inputRef={inputElAd}
                      disableUnderline={true}
                      id="fullName"
                      InputProps={{
                        className: classes.input2,
                        disableUnderline: true
                      }}
                      className={editHeaderValue ? classes.headTitleWidth : classes.headTitleWidthF}
                      value={headerValue || ''}
                      onChange={(e) => setHeaderValue(e.target.value)}
                      disabled={isHelpingMeWrite['title']}
                    />
                  </Grid>
                  <Grid item xs={1} className={`${classes.alignStart}`}>
                    <IconButton
                      style={{ padding: '1px 1px 1px 1px' }}
                      id={'insert-emoticon-ad'}
                      onClick={openEmojiPicker}
                      color="primary"
                      className={classes.editIconHeader}
                    >
                      <EmojiEmotionsIcon fontSize={'large'} />
                    </IconButton>
                  </Grid>
                </Grid>
              )
            ) : (
              <Grid container direction="row" justify="flex-start" alignItems="left">
                <Grid item xs={12}>
                  <Autocomplete
                    disableClearable
                    id="free-solo-demo"
                    onOpen={onOpenHeaderList}
                    onClose={onCloseHeaderList}
                    open={openHeaderList}
                    onChange={onChangeHeaderList}
                    classes={{
                      groupUl: classes.headAd,
                      groupLabel: classes.headTitleAd,
                      paper: classes.headDisplay
                    }}
                    options={
                      queries.adType !== 'homeValue'
                        ? headerAdLines
                            .filter((item) => item.enable === true)
                            .map((item) => {
                              if (item.ad_type === 'homeValue') {
                                let newDetails = item
                                newDetails.ad_type = 'Home Value'

                                return newDetails
                              }

                              if (item.ad_type === 'buyerSearch') {
                                let newDetails = item
                                newDetails.ad_type = 'Buyer Search'
                                return newDetails
                              }

                              if (item.ad_type === 'openHouse') {
                                let newDetails = item
                                newDetails.ad_type = 'Open House'
                                return newDetails
                              } else {
                                return item
                              }
                            })
                        : headerAdLines
                            .filter(
                              (item) =>
                                item.enable === true && (item.ad_type === 'homeValue' || item.ad_type === 'Home Value')
                            )
                            .map((item) => {
                              if (item.ad_type === 'homeValue') {
                                let newDetails = item
                                newDetails.ad_type = 'Home Value'
                                return newDetails
                              }
                              if (item.ad_type === 'buyerSearch') {
                                let newDetails = item
                                newDetails.ad_type = 'Buyer Search'
                                return newDetails
                              }
                              if (item.ad_type === 'openHouse') {
                                let newDetails = item
                                newDetails.ad_type = 'Open House'
                                return newDetails
                              } else {
                                return item
                              }
                            })
                    }
                    groupBy={(option) => option.ad_type}
                    getOptionLabel={(option) => '  ' + option.text}
                    renderInput={(params) => (
                      <TextField {...params} disabled={true} size="small" variant="outlined" fullWidth="false" />
                    )}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
        {!instaPreviewMode && (
          <Grid item xs={12} className={classes.fullContainer}>
            {!editAdText ? (
              <Typography className={`${classes.alignStart} ${classes.descriptionLineHeight} ${classes.black}`}>
                {' '}
                {[
                  <TextField
                    inputRef={inputEl}
                    placeholder="Write some text for your ad!"
                    value={trimText(updatedAdText || '', 500, false)}
                    onChange={(e) => handleAdTextUpdate(e.target.value)}
                    InputProps={{
                      className: `${classes.descriptionLineHeight} ${classes.black}`,
                      disableUnderline: true,
                      inputProps: {
                        style: {
                          minHeight: 'auto',
                          maxHeight: 'none',
                          overflow: 'auto'
                        }
                      }
                    }}
                    rowsMax={7}
                    fullWidth
                    multiline
                    disabled={true}
                  />
                ]}
              </Typography>
            ) : (
              <Grid container>
                <Grid item xs={12} style={{ position: 'relative' }}>
                  <TextField
                    inputRef={inputEl}
                    placeholder="Write some text for your ad!"
                    value={updatedAdText !== null ? updatedAdText : listingObj.remarks}
                    onChange={(e) => handleAdTextUpdate(e.target.value)}
                    InputProps={{
                      className: `${classes.descriptionLineHeight} ${classes.black}`,
                      inputProps: {
                        style: {
                          minHeight: 'auto',
                          maxHeight: 'none',
                          overflow: 'auto'
                        }
                      }
                    }}
                    rowsMax={7}
                    fullWidth
                    multiline
                    disabled={isHelpingMeWrite['description']}
                  />
                  <IconButton
                    id={'insert-emoticon'}
                    onClick={openEmojiPicker}
                    color="primary"
                    className={classes.editIconEmoji}
                    style={{ position: 'absolute', bottom: -10, right: 10 }}
                  >
                    <EmojiEmotionsIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={12} style={{ padding: 0 }}>
                  <Typography className={classes.editIconsOs}>
                    <span
                      style={{
                        fontFamily: 'Open Sans',
                        color: updatedAdText && updatedAdText.length > 500 ? '#f2714D' : 'grey',
                        fontWeight: updatedAdText && updatedAdText.length > 500 ? 'bold' : '500',
                        fontSize: '15px'
                      }}
                    >
                      {updatedAdText ? updatedAdText.length : 0}
                    </span>{' '}
                    <span
                      style={{
                        fontFamily: 'Open Sans',
                        color: 'grey',
                        fontWeight: '500',
                        fontSize: '15px'
                      }}
                    >
                      {'/ 500'}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
        <Grid
          item
          xs={12}
          className={instaPreviewMode === true ? classes.fullContainerInstagram : classes.fullContainer}
        >
          <Paper elevation={0} className={classes.customPaper}>
            <div className={instaPreviewMode === true ? classes.imgWrapperInsta : classes.imgWrapper}>
              {adSeed?.mediaType === 'video' ? (
                <VideoAdPreviewCard videos={videoSeeds} instagramMode={instaPreviewMode} />
              ) : adSeed?.mediaType === 'image' && queries.adType === 'multiple' ? (
                <Carousel
                  title="Carousel"
                  imgArrowContainerMidLeft={classes.imgArrowContainerMidLeft}
                  imgArrowContainerMidRight={classes.imgArrowContainerMidRight}
                  statePos={setStatePos}
                >
                  {filteredPhotos &&
                    filteredPhotos.map((el, i) => {
                      const img = encodeURI(el.url)
                      return (
                      <Item key={`multiple-img-${i}`} img={img} />
                    )
                    })}
                </Carousel>
              ) : queries.adType === 'multiple' ? (
                <Carousel
                  title="Carousel"
                  imgArrowContainerMidLeft={classes.imgArrowContainerMidLeft}
                  imgArrowContainerMidRight={classes.imgArrowContainerMidRight}
                  statePos={setStatePos}
                >
                  {multipleLisObj &&
                    multipleLisObj.map((el, i) => {
                      return <Item key={`multiple-img-${i}`} img={`${el.listing.photos[0]?.url}`} />
                    })}
                </Carousel>
              ) : queries.adType === 'buyerSearch' ? (
                <Carousel
                  title="Carousel"
                  imgArrowContainerMidLeft={classes.imgArrowContainerMidLeft}
                  imgArrowContainerMidRight={classes.imgArrowContainerMidRight}
                  statePos={setStatePos}
                >
                  {buyerSearchObj &&
                    buyerSearchObj.data.map((el, i) => {
                      return <Item key={`buyer-img-${i}`} img={`${el.main_photo}`} />
                    })}
                </Carousel>
              ) : formatValue === 'multiple' ? (
                <Carousel
                  title="Carousel"
                  imgArrowContainerMidLeft={classes.imgArrowContainerMidLeft}
                  imgArrowContainerMidRight={classes.imgArrowContainerMidRight}
                  statePos={setStatePos}
                >
                  {filteredPhotos &&
                    filteredPhotos.map((el, i) => {
                      return <Item key={`carousel-${i}`} img={`${el.url}`} />
                    })}
                </Carousel>
              ) : queries.adType === 'readyMade' ? (
                <SwipeableViews enableMouseEvents={false} index={indexPreview}>
                  {filteredPhotos &&
                    filteredPhotos.map((el, i) => {
                      var x = encodeURI(el.url)
                      return (
                        <div
                          key={`filteredPhotos-${el.url}-${i}`}
                          className={
                            adSeed?.mediaType === "image" ? 
                              classes.mainListingImageContainerSocialMedia :
                            instaPreviewMode === true
                              ? classes.mainListingImageContainerInsta
                              : classes.mainListingImageContainer
                          }
                        >
                          {' '}
                          <div style={{ backgroundImage: `url('${x}')` }} className={adSeed?.mediaType ? classes.mainListingImage : classes.mainListingImageLegacy} />
                        </div>
                      )
                    })}
                </SwipeableViews>
              ) : (
                <SwipeableViews
                  enableMouseEvents={formatValue === 'multiple' ? true : false}
                  index={formatValue === 'single' ? index : indexPreview}
                >
                  {filteredPhotos &&
                    filteredPhotos.map((el, i) => {
                      return (
                        <div
                          className={
                            adSeed?.mediaType === "image" ? 
                              classes.mainListingImageContainerSocialMedia :
                              instaPreviewMode === true
                                ? classes.mainListingImageContainerInsta
                                : classes.mainListingImageContainer
                          }
                        >
                          {' '}
                          <div
                            key={i}
                            style={{ backgroundImage: `url(${el.url})` }}
                            className={adSeed?.mediaType ? classes.mainListingImage : classes.mainListingImageLegacy}
                          />
                        </div>
                      )
                    })}
                </SwipeableViews>
              )}
              {(adSeed?.mediaType === "image" && adSeed?.assets?.length === 1 ) ? null :(
              adSeed?.mediaType !== "video" &&             
              editAdText === true &&
              formatValue === 'single' &&
              queries.adType !== 'buyerSearch' &&
              queries.adType !== 'readyMade' &&
              queries.adType !== 'multiple' && (
                <Tooltip
                  arrow
                  open={showTooltip}
                  placement="left"
                  className={classes.imgArrowContainerLarge}
                  classes={{ tooltip: classes.customWidth }}
                  title={<span className={classes.customTooltipLarge}>{adSeed?.mediaType !== "image" ? "Select or upload your ad photo" : "Select your ad photo"}</span>}
                  aria-label="Select or upload your ad photo (exterior recommended)"
                >
                  <ButtonGroup>
                    {adSeed?.mediaType !== "image" ? (
                    <CustomIconButton aria-label="camera photo" onClick={uploadFileClick}>
                      <PhotoCameraIcon />
                    </CustomIconButton>
                    ) : null}

                    <CustomIconButton aria-label="previous ad image" onClick={prevIndex}>
                      <ChevronLeftIcon />
                    </CustomIconButton>
                    <CustomIconButton aria-label="next ad image" onClick={nextIndex}>
                      <ChevronRightIcon />
                    </CustomIconButton>
                  </ButtonGroup>
                </Tooltip>
              )
              )}
              {editAdText && formatValue === 'multiple' && (
                <div id="ad-carousel-image" className={classes.editCarouselButton}>
                  <Button
                    variant="outlined"
                    color="primary"
                    size={'medium'}
                    className={classes.SelectPageButton}
                    onClick={selectPhotosAction}
                    style={{ border: '2px solid' }}
                  >
                    Edit Carousel
                  </Button>
                </div>
              )}
              {instaPreviewMode && !editAdText && (
                <div
                  className={classes.imgAddContainerInsta}
                  style={{ cursor: 'pointer' }}
                  onClick={
                    queries.adType === 'homeValue' || adtypeSelected === 'homeValue'
                      ? () =>
                          window.open(
                            linkValue.includes('https://') || linkValue.includes('http://')
                              ? linkValue
                              : 'https://' + linkValue,
                            '_blank'
                          )
                      : handleLandingPage
                  }
                >
                  <Typography
                    style={{
                      fontFamily: 'Open Sans',
                      color: 'white',
                      fontWeight: '500',
                      fontSize: '15px'
                    }}
                  >
                    {'Learn More'}
                  </Typography>

                  <div className={classes.alignEndInsta}>
                    <NavigateNextIcon style={{ color: 'white' }} fontSize={'large'} />
                  </div>
                </div>
              )}
              {instaPreviewMode && !editAdText ? (
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  className={!editAdText ? classes.cardPreviewPaddingInsta : classes.cardTextPreviewPadding}
                >
                  <Grid item xs={4}>
                    <img
                      alt="instagram heart"
                      style={{ padding: '4px' }}
                      src={process.env.PUBLIC_URL + '/IG_heart.svg'}
                    />
                    <img
                      alt="instagram comment"
                      style={{ padding: '4px' }}
                      src={process.env.PUBLIC_URL + '/IG_comment.svg'}
                    />
                    <img
                      alt="instagram share"
                      style={{ padding: '4px' }}
                      src={process.env.PUBLIC_URL + '/IG_share.svg'}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{ justifyContent: 'center', alignItems: 'center' }}
                    className={classes.alignCenter}
                  >
                    {formatValue === 'multiple' && [
                      <FiberManualRecordIcon
                        style={{
                          color: 'rgba(55, 151, 240, 1)',
                          fontSize: '10px'
                        }}
                      />,
                      <FiberManualRecordIcon style={{ color: 'grey', fontSize: '10px' }} />,
                      <FiberManualRecordIcon style={{ color: 'grey', fontSize: '10px' }} />
                    ]}
                  </Grid>
                  <Grid item xs={4} className={classes.alignEnd}>
                    <img alt="instagram icon" style={{ padding: '4px' }} src={process.env.PUBLIC_URL + '/IG_icon.svg'} />
                  </Grid>

                  <Grid item xs={12} className={classes.alignJustify}>
                    <Typography className={`${classes.alignStart} ${classes.descriptionLineHeight} ${classes.black}`}>
                      {' '}
                      {[
                        trimText(updatedAdText ? updatedAdText : '', 115, false),
                        ' ',
                        <span
                          className={`${classes.editIconContainer} ${visibleAdTextEditIcon && classes.opacityActive}`}
                        >
                          <EditOutlinedIcon
                            color="primary"
                            className={classes.editIcon}
                            onClick={handleEditTextIconClick}
                          />
                        </span>
                      ]}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  className={!editAdText ? classes.cardPreviewPadding : classes.cardTextPreviewPadding}
                >
                  {!editAdText ? (
                    <Grid item xs={8}>
                      {queries.adType === 'multiple' ? (
                        <Typography variant="h6" className={classes.adTitle}>
                          {trimText(
                            multipleLisObj[statePos]?.fieldValues?.fullAddress !== null &&
                              multipleLisObj[statePos]?.fieldValues?.fullAddress !== undefined
                              ? multipleLisObj[statePos]?.fieldValues?.fullAddress
                              : ' ',
                            65
                          )}
                        </Typography>
                      ) : (
                        <Typography variant="h6" className={classes.adTitle}>
                          {trimText(headerValue, 65)}
                        </Typography>
                      )}
                      <Typography variant="subtitle2" className={classes.darkestGrey}>
                        {queries.adType === 'multiple' ? (
                          trimText(
                            `${
                              multipleLisObj[statePos]?.listing?.bedrooms !== null &&
                              multipleLisObj[statePos]?.listing?.bedrooms !== undefined
                                ? multipleLisObj[statePos]?.listing?.bedrooms
                                : '0'
                            } beds | ${
                              multipleLisObj[statePos]?.listing?.fullBaths !== null &&
                              multipleLisObj[statePos]?.listing?.fullBaths !== undefined
                                ? multipleLisObj[statePos]?.listing?.fullBaths
                                : '0'
                            } baths | ${
                              multipleLisObj[statePos]?.listing.squareFeet !== null &&
                              multipleLisObj[statePos]?.listing.squareFeet !== undefined
                                ? multipleLisObj[statePos]?.listing.squareFeet.toLocaleString()
                                : '0'
                            } sqft`,
                            43
                          )
                        ) : queries.adType === 'buyerSearch' ? (
                          trimText(
                            `${
                              buyerSearchObj?.data[statePos]?.bedrooms !== null
                                ? buyerSearchObj?.data[statePos]?.bedrooms
                                : '0'
                            } beds | ${
                              buyerSearchObj?.data[statePos]?.totalBaths !== null
                                ? buyerSearchObj?.data[statePos]?.totalBaths
                                : '0'
                            } baths | ${
                              buyerSearchObj?.data[statePos]?.sqFeet !== null
                                ? buyerSearchObj?.data[statePos]?.sqFeet?.toLocaleString()
                                : '0'
                            } sqft`,
                            43
                          )
                        ) : queries.adType === 'readyMade' ? (
                          <a
                            style={{ color: '#818181!important' }}
                            onClick={() =>
                              window.open(
                                linkValue.includes('https://') || linkValue.includes('http://')
                                  ? linkValue
                                  : 'https://' + linkValue,
                                '_blank'
                              )
                            }
                          >
                            <span className={`${classes.linkStyleCall}`} style={{ color: theme.palette.primary.main }}>
                              {linkValue}
                            </span>
                          </a>
                        ) : adtypeSelected === 'homeValue' ? (
                          <span>Enter your address and get instant access to your home’s value.</span>
                        ) : (
                          trimText(
                            `${listingObj.bedrooms} beds | ${
                              listingObj.totalBaths
                            } baths | ${listingObj.sqFeet.toLocaleString()} sqft`,
                            43
                          )
                        )}
                      </Typography>
                    </Grid>
                  ) : (
                    <Typography fullWidth variant="subtitle2" className={classes.darkestGrey}>
                      Facebook requires that text cannot contain content that is{' '}
                      <a
                        style={{ color: '#818181!important' }}
                        onClick={() =>
                          window.open(
                            'https://www.facebook.com/policies/ads/prohibited_content/misleading_claims',
                            '_blank'
                          )
                        }
                      >
                        <span className={`${classes.linkStyle}`} style={{ color: theme.palette.primary.main }}>
                          {' '}
                          misleading
                        </span>
                      </a>
                      ,
                      <a
                        style={{ color: '#818181!important' }}
                        onClick={() =>
                          window.open(
                            'https://transparency.meta.com/policies/community-standards/adult-nudity-sexual-activity/'
                          )
                        }
                      >
                        <span className={`${classes.linkStyle}`} style={{ color: theme.palette.primary.main }}>
                          {' '}
                          adult
                        </span>
                      </a>
                      ,
                      <a
                        style={{ color: '#818181!important' }}
                        onClick={() =>
                          window.open('https://www.facebook.com/policies/ads/lead_ads/political_affiliation')
                        }
                      >
                        <span className={`${classes.linkStyle}`} style={{ color: theme.palette.primary.main }}>
                          {' '}
                          political
                        </span>
                      </a>
                      ,
                      <a
                        style={{ color: '#818181!important' }}
                        onClick={() =>
                          window.open('https://www.facebook.com/policies/ads/prohibited_content/personal_attributes')
                        }
                      >
                        <span className={`${classes.linkStyle}`} style={{ color: theme.palette.primary.main }}>
                          {' '}
                          or implies personal attributes
                        </span>
                      </a>
                      .
                    </Typography>
                  )}
                </Grid>
              )}
              {!editAdText && (
                <Grid xs={12} className={classes.alignCenter}>
                  <CustomIconButton aria-label="Facebook ad" onClick={closeInstaMode}>
                    <FacebookIcon color={instaPreviewMode ? 'none' : 'primary'} fontSize={'large'} />
                  </CustomIconButton>
                  <CustomIconButton aria-label="Instagram ad" onClick={startInstaMode}>
                    <InstagramIcon color={!instaPreviewMode ? 'none' : 'primary'} fontSize={'large'} />
                  </CustomIconButton>
                </Grid>
              )}
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  )
}

const Listing = ({
  listingId,
  justLogged,
  setDataFacebook,
  readyMadePhotoListcpy,
  setReadyMadePhotoListcpy,
  userInfo,
  headerList,
  buyerSearchObj,
  multipleLisObj,
  searchCriteria,
  setJustLogged,
  standalone,
  standaloneContext,
  listing,
  standaloneAction,
  listingPageAction,
  userFbAuth,
  editPageAction,
  selectPhotoAction,
  queries,
  contentIndex,
  adCreateAction,
  onAdCreated,
  handleHeight,
  adSeed
}) => {
  // util
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  // context
  let adDetails = useContext(standaloneContext === 'marketer' ? MarketerAdContext : AdContext)
  let setAdDetails = useContext(standaloneContext === 'marketer' ? MarketerAdDispatchContext : AdDispatchContext)
  // listing hooks
  let [adDuration, setAdDuration] = useState(14)
  let [locationObj, setLocationObj] = React.useState(null)
  let [locationReadyObj, setLocationReadyObj] = React.useState(null)
  let [interestReadyObj, setInterestReadyObj] = React.useState(null)
  let [locationAuto, setLocationAuto] = React.useState(null)
  let [inputValue, setInputValue] = React.useState('')
  let [selectedItem, setSelectedItem] = useState([])
  let [inputValueI, setInputValueI] = React.useState('')
  let [selectedItemI, setSelectedItemI] = useState([])
  let [selectedItemTags, setSelectedItemTags] = useState([])
  let [dailyBudget, setDailyBudget] = useState(20)
  let [adLayout, setAdLayout] = useState(2)
  let [editAdText, setEditAdText] = useState(false)
  let [instaPreviewMode, setInstaPreviewMode] = useState(false)
  let [localSelected, setLocalSelected] = useState([])
  let [currentSelected, setCurrentSelected] = useState({})
  let [localSorted, setLocalSorted] = useState([])
  let [updatedAdText, setUpdatedAdText] = useState(null)
  let [cancelText, setCancelText] = useState(null)
  let [selectPhotosMode, setSelectPhotosMode] = useState(false)
  let [listingObj, setListingObj] = useState(null)
  let [termsOpen, setTermsOpen] = useState(false)
  let [index, setIndex] = useState(0)
  let [indexPreview, setIndexPreview] = useState(0)
  let [termsCheckbox, setTermsCheckbox] = useState(false)
  let [submitAttempt, setSubmitAttempt] = useState(false)
  let [formatValue, setFormatValue] = useState('single')
  let [targetValue, setTargetValue] = useState('US')
  let [selectedAgent, setSelectedAgent] = useState(0)
  let [teamAgents, setTeamAgents] = useState(null)
  let [showTooltip, setShowTooltip] = useState(true)
  let [filterReadyValue, setFilterReadyValue] = useState()
  let [open, setOpen] = React.useState(false)
  let [openRequirements, setOpenRequirements] = React.useState(false)
  let [modalEmojiPicker, setModalEmojiPicker] = useState(false)
  let [anchorEl, setAnchorEl] = React.useState(null)
  let inputEl = React.useRef(null)
  let inputElAd = React.useRef(null)
  const hiddenFileInput = React.useRef(null)
  const scrollList = React.useRef(null)
  let [filteredPhotos, setFilteredPhotos] = useState([])
  let [listingObjBck, setListingObjBck] = useState([])
  let [imageIndexBck, setImageIndexBck] = useState(0)
  // checkout hooks
  let [facebookAd, setFacebookAd] = useState(null)
  let [imageAd, setImageAd] = useState(null)
  let [creatingAd, setCreatingAd] = useState(false)
  let [adCreated, setAdCreated] = useState(false)
  let [errorSnackbar, setErrorSnackbar] = useState(false)
  let [snackbarMessage, setSnackbarMessage] = useState('')

  const [isFirstRender, setIsFirstRender] = useState(true)

  // let [headerList,setHeaderList] = useState({});
  let [homeValuePhotoList, setHomeValuePhotoList] = useState({})
  let [readyMadePhotoList, setReadyMadePhotoList] = useState({})
  // let [readyMadePhotoListcpy,setReadyMadePhotoListcpy] = useState({});
  let [counters, setCounters] = useState({})
  let [counter, setCounter] = useState(1)
  let [valueHeaderList, setValueHeaderList] = useState(null)
  let [openHeaderList, setOpenHeaderList] = useState(false)
  let [headerValue, setHeaderValue] = useState('See the price, all the photos and property details now')
  let [listHeaderValue, setListHeaderValue] = useState({})
  let [editHeaderValue, setEditHeaderValue] = useState(false)

  let [editLinkValue, setEditLinkValue] = useState(false)
  const brUserInfo = JSON.parse(localStorage.getItem('brUserInfo'))
  let [linkValue, setLinkValue] = useState('')
  let [tagsValue, setTagsValue] = useState('')
  //brUserInfo.website + "/homeValue"
  let [adtypeSelected, setAdTypeSelected] = useState('listed') //homeValue,listed,etc
  let [currentAdText, setCurrentAdText] = useState(null)

  let [searchResponse, setSearchResponse] = useState(null)
  let [autoCompleteRes, setAutoCompleteRes] = useState(null)
  let [areas, setAreas] = useState(null)
  let [interests, setInterests] = useState(null)
  let [facebookAdEdit, setFacebookAdEdit] = useState(null)

  const [paymentProfiles, setPaymentProfiles] = useState({
    personal: [],
    team: []
  })
  const [personalSubscriptions, setPersonalSubscriptions] = useState()
  const [teamSubscriptions, setTeamSubscriptions] = useState()

  // Function mirrored from alf-react
  const expirationMonthYearIsValid = (
    expiration_month,
    expiration_year
  ) => {
    if (!expiration_year || !expiration_month) return false;
    const now = new Date();
    const year = now.getFullYear();
    const monthNum = now.getMonth() + 1; // + 1 for JS date, months are zero-based
  
    return (
      year < expiration_year ||
      (year === expiration_year && monthNum <= expiration_month)
    );
  };


  const updatePaymentProfiles = (userId, isTeam = false) => {
    const apiMethod = isTeam ? getTeamPayments : getUserPayments

    apiMethod(userId, (response) => {
      if (response && Array.isArray(response.paymentProfiles)) {
        const filteredProfiles = response.paymentProfiles.filter((profile) => {
          // Check if 'last_name' contains "ONLY", but not when preceded by "Marketer"
          const onlyRegex = /(?:^|\s)ONLY\b/
          const marketerOnlyRegex = /Marketer\s+ONLY\b/
          const hasOnly = onlyRegex.test(profile.last_name)
          const hasMarketerOnly = marketerOnlyRegex.test(profile.last_name)

          const isNotExpired = expirationMonthYearIsValid(
            profile.expiration_month,
            profile.expiration_year
          );

          // Allow through if it does not have "ONLY", or it specifically has "Marketer ONLY"
          // remove expired profiles
          return( !hasOnly || hasMarketerOnly) && isNotExpired
        })

        setPaymentProfiles((prevProfiles) => ({
          ...prevProfiles,
          [isTeam ? 'team' : 'personal']: filteredProfiles
        }))
      } else {
        console.error('Unexpected data format for profiles:', response)
      }
    })
  }

  useEffect(() => {
    if (queries.isAdmin === undefined || queries.billingUserId === undefined) {
      setSnackbarMessage('Missing payment information for this ad, please remake the ad.')
      setErrorSnackbar(true)
    }
  }, [queries.billingUserId, queries.isAdmin])

  useEffect(() => {
    const  {personal, team} = paymentProfiles
    if (personal.length === 0 && team.length === 0) {
      setSnackbarMessage('No payment profiles found, please check your payment settings in Brivity Marketer')
      setErrorSnackbar(true)
    } else {
      setErrorSnackbar(false)
    }
  }, [paymentProfiles])

  useEffect(() => {
    if (!queries.ad_id) {
      setSnackbarMessage('Missing query param in ad. Please remake the ad. If this persists, please contact support.')
      setErrorSnackbar(true)
    }
  }, [queries.ad_id])

  useEffect(() => { 
    if(validateAd(adSeed)) {
      setSnackbarMessage('Ad is missing vital details to create an ad. Please contact support if this continues.');
      setErrorSnackbar(true);
    }

    const getAdFormat = () => {
      if(adSeed?.mediaType === "image") {
        if(adSeed?.adType === 'readyMade') {
          return 'single'
        }
        if(adSeed?.assets?.length > 1 ) {
          return 'multiple'
        }
      }

      return 'single'
    }

    const adFormat = getAdFormat()

    setFormatValue(adFormat)
  },[adSeed])

  // load subscriptions and payment profiles from query params config:
  useEffect(() => {
    setPaymentProfiles({ personal: [], team: [] })
    if (!queries.billingUserId) return;
    const billingUserId = queries.billingUserId;
    const billingPeerId = queries.billingPeerId;

    // always get the team subscription / payment info with the billingUserId
    getTeamSubscription(billingUserId, data => setTeamSubscriptions(data?.subscriptionProfiles))
    updatePaymentProfiles(billingUserId, true)

    if (queries.isAdmin === 'true') {
      // Check if billingPeerId is available:
      if (billingPeerId) {
        // load peer payment info
        getUserSubscription(billingPeerId, data => setPersonalSubscriptions(data?.subscriptionProfiles))
        updatePaymentProfiles(billingPeerId)
      }
    } else {
      // Agent level payment profiles, load the personal payment profiles by billingUserId
      getUserSubscription(billingUserId, data => setPersonalSubscriptions(data?.subscriptionProfiles))
      updatePaymentProfiles(billingUserId)
    }
  }, [queries.billingUserId, queries.billingPeerId, queries.isAdmin])

  const activeSubsFilter = (subscription) => subscription?.product?.name === 'Brivity Marketer' && subscription?.state === 'active'

  const paymentOptions = useMemo(() => {
    const activeTeamMarketerSubscriptions = teamSubscriptions?.filter(activeSubsFilter)
    const activePersonalMarketerSubscriptions = personalSubscriptions?.filter(activeSubsFilter)
    const defaultTeamProfileId = activeTeamMarketerSubscriptions?.[0]?.credit_card?.id;
    const defaultPersonalProfileId = activePersonalMarketerSubscriptions?.[0]?.credit_card?.id;

    let profiles = [];

    if (paymentProfiles?.personal) {
      const defaultProfile = paymentProfiles.personal.find(profile => profile.id === defaultPersonalProfileId)
      const otherProfiles = paymentProfiles.personal.filter(profile => profile.id !== defaultProfile?.id)

      if (defaultProfile) {
        profiles.push({
          ...defaultProfile, 
          isPersonal: true, 
          isDefault: true, 
          label: `Personal Default (${defaultProfile.masked_card_number.substr(-4)})`
        })
      }

      if (otherProfiles.length) {
        profiles = profiles.concat(otherProfiles.map(profile => ({
          ...profile,
          isPersonal: true,
          isDefault: false,
          label: `Personal (${profile.masked_card_number.substr(-4)})`
        })))
      }
    }

    if (paymentProfiles?.team) {
      const defaultProfile = paymentProfiles.team.find(profile => profile.id === defaultTeamProfileId)
      const otherProfiles = paymentProfiles.team.filter(profile => profile.id !== defaultProfile?.id)

      if (defaultProfile) {
        profiles.push({
          ...defaultProfile, 
          isPersonal: false, 
          isDefault: true, 
          label: `Team Default (${defaultProfile.masked_card_number.substr(-4)})`
        })
      }

      if (otherProfiles.length) {
        profiles = profiles.concat(otherProfiles.map(profile => ({
          ...profile,
          isPersonal: false,
          isDefault: false,
          label: `Team (${profile.masked_card_number.substr(-4)})`
        })))
      }
    }

    return profiles
  }, [paymentProfiles?.personal, paymentProfiles?.team, personalSubscriptions, teamSubscriptions])

  const [selectedPaymentOption, setSelectedPaymentOption] = useState()

  const defaultPaymentOption = useMemo(() => {
    return paymentOptions.find((option) => option.isDefault === true)
  }, [paymentOptions])

  useEffect(() => {
    if (!selectedPaymentOption && defaultPaymentOption) {
      setSelectedPaymentOption({
        paymentProfileId: defaultPaymentOption.id,
        isPersonal: defaultPaymentOption.isPersonal,
      })

    } 
  }, [defaultPaymentOption, selectedPaymentOption])

  const handlePaymentOptionChange = (event) => {
    const selectedOptionId = event.target.value
    const selectedOption = paymentOptions.find((option) => option.id === selectedOptionId)

    setSelectedPaymentOption(selectedOption ? {
      paymentProfileId: selectedOptionId,
      isPersonal: selectedOption.isPersonal,
      maskedCardNumber: selectedOption.masked_card_number.substr(-4)
    } : undefined)
  }

  useEffect(() => {
    if (justLogged === true) {
      setJustLogged(false)
      let tmpLink = brUserInfo?.website
      if (tmpLink !== undefined) setLinkValue(tmpLink + '/homevalue')

      if (queries.website !== undefined) setLinkValue(queries.website + '/homevalue')
      if (queries.adType === 'readyMade') {
        if (tmpLink !== undefined) setLinkValue(tmpLink)

        if (queries.website !== undefined) setLinkValue(queries.website)
      }
    }
  }, [justLogged])

  useEffect(() => {
    var tmp_areas = ''
    selectedItem.map((item, index) => {
      tmp_areas = tmp_areas + (index ? ',' : '') + item.name
    })
    setAreas(tmp_areas)
  }, [selectedItem])
  useEffect(() => {
    var tmp_interests = ''
    selectedItemI.map((item, index) => {
      tmp_interests = tmp_interests + (index ? ',' : '') + item.name
    })
    setInterests(tmp_interests)
  }, [selectedItemI])

  let [images, setImages] = React.useState([])
  const maxNumber = 1
  const onChange = (imageList, addUpdateIndex) => {
    console.log(imageList, addUpdateIndex)
    postImageAd(imageList[0], setImageAd)
    setImages(imageList)
  }

  useEffect(() => {
    if (imageAd !== null) {
      if (imageAd.message) {
        setSnackbarMessage(imageAd.message)
        setErrorSnackbar(true)
      } else if (!!imageAd.image_url) {
        setErrorSnackbar(false)
        if (adtypeSelected === 'homeValue') {
          setFilteredPhotos(listingObj.photos)
          setIndex(0)
          let tempAdDetails = { ...adDetails, image_index: 0 }
          setAdDetails(tempAdDetails)
        }
        if (adtypeSelected === 'readyMade') {
          getGalleryPhotosReady('readyMade', setReadyMadePhotoListcpy)
        } else {
          let obj = { url: imageAd.image_url, order: 0 }
          const newArray = { ...listingObj }
          newArray.photos.unshift(obj)
          setListingObj(newArray)
        }
      }
    }
  }, [imageAd])
  const onChangeForm = (e) => {
    if (e.target.files[0] && e.target.files[0].type.includes('image')) {
      if (e.target.files[0].size >= eightMb) {
        setErrorSnackbar(true)
        setSnackbarMessage('The selected image is to large, must be less than 8 MB')
      } else {
        setErrorSnackbar(false)
        postImageAd(e.target.files[0], setImageAd)
      }
    } else {
      console.log('invalid file')
    }
  }
  const uploadFileClick = (event) => {
    if (queries.adType === 'readyMade') scrollList.current.scrollTo(0, 0)
    hiddenFileInput.current.click()
  }

  useEffect(() => {
    if (editAdText === false) {
      handleHeight(0)
    } else {
      handleHeight(1)
    }
  }, [editAdText])

  useEffect(() => {
    if (Boolean(anchorEl)) {
      setShowTooltip(false)
    }
  }, [anchorEl])

  useEffect(() => {
    setLocalSorted(localSelected)
  }, [localSelected])

  useEffect(() => {
    let x = headerList !== null && headerList
    if (typeof headerList !== 'undefined' && headerList != null && Object.keys(headerList).length !== 0) {
      DEBUG_MODE && console.log(x)
      if (x.headlines.filter((key) => key.ad_type === 'listed')[0] !== undefined)
        setHeaderValue(x.headlines.filter((key) => key.ad_type === 'listed')[0].text)
      if (queries.adType === 'sold' && x.headlines.filter((key) => key.ad_type === 'sold')[0] !== undefined)
        setHeaderValue(x.headlines.filter((key) => key.ad_type === 'sold')[0].text)
      if (queries.adType === 'openHouse' && x.headlines.filter((key) => key.ad_type === 'openHouse')[0] !== undefined)
        setHeaderValue(x.headlines.filter((key) => key.ad_type === 'openHouse')[0].text)
      if (queries.adType === 'homeValue' && x.headlines.filter((key) => key.ad_type === 'homeValue')[0] !== undefined)
        setHeaderValue(x.headlines.filter((key) => key.ad_type === 'homeValue')[0].text)
      if (queries.adType === 'buyerSearch') setHeaderValue('View homes for sale near you')
      if (queries.adType === 'readyMade') setHeaderValue('Take your real estate career to the next level')
      if (queries.adType === 'multiple') setHeaderValue(multipleLisObj[0]?.fieldValues?.fullAddress)
    }
  }, [headerList])

  useEffect(() => {
    if (standalone) {
      setListingObj(listing)

      if (queries.userId) {
        getTeamAgents(queries.userId, setTeamAgents)
      }
    } else {
      let mlsId = JSON.parse(localStorage.getItem('mlsSettings'))
      console.log(listingId)
      getListing(mlsId.settings.mls_id, listingId, handleListingObj)
    }

    let tmpLink = brUserInfo?.website
    if (tmpLink !== undefined) setLinkValue(tmpLink + '/homevalue')

    if (queries.website !== undefined) setLinkValue(queries.website + '/homevalue')

    if (queries.adType === 'readyMade') {
      if (tmpLink !== undefined) setLinkValue(tmpLink)

      if (queries.website !== undefined) setLinkValue(queries.website)
    }

    if (queries.website === undefined) {
      setErrorSnackbar(true)
      setSnackbarMessage('Missing website link, please update the website link in your Brivity Marketer Profile')
    }

    getGalleryPhotos('homeValue', setHomeValuePhotoList)
    getCounterAd(setCounters)
    getReadyTargetInterest('real estate', setInterestReadyObj)

    setAdTypeSelected(queries.adType)
  }, [])

  useEffect(() => {
    if (facebookAdEdit !== null) {
      if (adtypeSelected === 'homeValue') {
        let obj = { url: facebookAdEdit.image, order: 0 }
        const newArray = { ...listingObj }
        newArray.photos.unshift(obj)
        setListingObj(newArray)
        setFilteredPhotos(newArray.photos)
        setIndex(0)
        newArray.remarks = facebookAdEdit.description
        newArray.ad_daily_budget = facebookAdEdit.dailyBudget
        newArray.ad_duration = facebookAdEdit.days
        let tempAdDetails = {
          ...adDetails,
          image_index: 0,
          ad_description: newArray.remarks,
          ad_duration: newArray.ad_duration,
          ad_daily_budget: newArray.ad_daily_budget
        }

        setAdDetails(tempAdDetails)
        setHeaderValue(facebookAdEdit.title)
        setUpdatedAdText(newArray.remarks)
        setDailyBudget(newArray.ad_daily_budget)
        setAdDuration(newArray.ad_duration)
      } else {
        let obj = { url: facebookAdEdit.image, order: 0 }
        const newArray = { ...listingObj } // Create a copy
        newArray.photos.unshift(obj)
        setListingObj(newArray)
        newArray.remarks = facebookAdEdit.description
        newArray.ad_daily_budget = facebookAdEdit.dailyBudget
        newArray.ad_duration = facebookAdEdit.days
        let tempAdDetails = {
          ...adDetails,
          ad_description: newArray.remarks,
          ad_duration: newArray.ad_duration,
          ad_daily_budget: newArray.ad_daily_budget
        }

        setAdDetails(tempAdDetails)
        setHeaderValue(facebookAdEdit.title)
        setUpdatedAdText(newArray.remarks)
        setDailyBudget(newArray.ad_daily_budget)
        setAdDuration(newArray.ad_duration)
      }
    }
  }, [facebookAdEdit])

  useEffect(() => {
    if (homeValuePhotoList.images !== undefined) {
      DEBUG_MODE &&
        console.log(
          homeValuePhotoList.images.map((item, index) => {
            return { url: item.image_url, order: index }
          })
        )

      if (adtypeSelected === 'homeValue') {
        let x3 = { ...listingObj }
        setListingObjBck(x3)
        setImageIndexBck(0)
        setCurrentAdText(listingObj.remarks)

        setFilteredPhotos(
          homeValuePhotoList.images.map((item, index) => {
            return { url: item.image_url, order: index }
          })
        )
        setIndex(0)
        let x = { ...listingObj }
        x.photos = homeValuePhotoList.images.map((item, index) => {
          return { url: item.image_url, order: index }
        })
        x.image_index = 0
        x.remarks =
          'Are you considering selling your home in the near future? Your home may be worth more than you think.'
        let newAdDetails = { ...adDetails, ad_description: x.remarks }
        setAdDetails(newAdDetails)
        setUpdatedAdText(x.remarks)
        setListingObj(x)
      }
    }
  }, [homeValuePhotoList])
  useEffect(() => {
    if (readyMadePhotoListcpy !== undefined || readyMadePhotoListcpy !== null) {
      setReadyMadePhotoList(readyMadePhotoListcpy)
    }
  }, [readyMadePhotoListcpy])

  const isVideoSeededAd = adSeed?.mediaType === 'video'

  useEffect(() => {
    if (readyMadePhotoList.images !== undefined && readyMadePhotoList.images.length > 0) {
      DEBUG_MODE &&
        console.log(
          readyMadePhotoList.images.map((item, index) => {
            return { url: item.image_url, order: index }
          })
        )
      if (adtypeSelected === 'readyMade') {
        let x3 = { ...listingObj }
        setListingObjBck(x3)
        setImageIndexBck(0)
        setCurrentAdText(listingObj.remarks)
        const reverse_imageuser_arr = readyMadePhotoListcpy.userImages.reverse()
        setFilteredPhotos(
          [...reverse_imageuser_arr, ...readyMadePhotoList.images]
            .filter(
              (key) =>
                key.image_url.includes('.jpg') || key.image_url.includes('.jpeg') || key.image_url.includes('.png')
            )
            .map((item, index) => {
              return {
                url: item.image_url,
                order: index,
                category: item?.category !== undefined ? item?.category : ''
              }
            })
        )
        setIndex(0)
        let x = { ...listingObj }
        x.photos = [...readyMadePhotoListcpy.userImages, ...readyMadePhotoList.images]
          .filter(
            (key) => key.image_url.includes('.jpg') || key.image_url.includes('.jpeg') || key.image_url.includes('.png')
          )
          .map((item, index) => {
            return {
              url: item.image_url,
              order: index,
              category: item?.category !== undefined ? item?.category : ''
            }
          })
        x.image_index = 0
        setListingObj(x)

        setEditAdText(true)
        setSelectPhotosMode(!isVideoSeededAd)
      }
    }

    if (queries.adType === 'readyMade') {
      setEditAdText(true)
      setSelectPhotosMode(!isVideoSeededAd)
    }
  }, [readyMadePhotoList, isVideoSeededAd])
  useEffect(() => {
    DEBUG_MODE && console.log('rac: ', listingObjBck?.photos)
  }, [listingObjBck])
  useEffect(() => {
    if (counters.adsCounts !== undefined && queries.adType === 'readyMade') {
      counters.adsCounts.map((ad) => {
        if (ad.ad_type === 'readyMade') setCounter(ad.total + 1)
      })
    }
  }, [counters])

  useEffect(() => {
    if (listingObj) {
      const excludedAdTypes = ["openHouse", "listed", "sold"];
      if (!!adDetails.ad_description && !excludedAdTypes.includes(queries.adType)) {
        setUpdatedAdText(adDetails.ad_description)
      }
      if (!adDetails.ad_description) {
        if (queries.adType === 'buyerSearch') {
          let search = ''

          buyerSearchObj.searchOptions.map((item, index) => {
            search = search + (index ? ',' : '') + item.address
          })

          let defaultText = 'Searching for the perfect home in ' + search + '? Search no further.'
          let newAdDetails = { ...adDetails, ad_description: defaultText }
          setAdDetails(newAdDetails)
          setUpdatedAdText(defaultText)
        } else if (queries.adType === 'multiple') {
          let defaultText =
            "Finding your dream home is just a click away. Browse listings for sale in your area now to find the home that's right for you!"
          let newAdDetails = { ...adDetails, ad_description: defaultText }
          setAdDetails(newAdDetails)
          setUpdatedAdText(defaultText)
        } else if (queries.adType === 'readyMade') {
          let defaultText =
            'Ready to level up your career in real estate? Apply with Brivity! We provide cutting-edge technology and tools to leverage your experience and achieve greater success.'

          let newAdDetails = { ...adDetails, ad_description: defaultText }
          setAdDetails(newAdDetails)
          setUpdatedAdText(defaultText)
          setHeaderValue('Take your real estate career to the next level')
        } else {
          let newAdDetails = {
            ...adDetails,
            ad_description: listingObj.remarks
          }
          setAdDetails(newAdDetails)
          setUpdatedAdText(listingObj.remarks)
        }
      }

      if (queries.adType !== 'homeValue') {
        if (adSeed?.assets?.length && adSeed?.mediaType === 'image') {
          const formattedAssets = formatAssetsForPhotos(adSeed.assets)

          setFilteredPhotos(formattedAssets)
        } else {
          setFilteredPhotos(listingObj.photos)
        }
        setIndex(adDetails.image_index)
      }

      setIndexPreview(0)
      setAdDuration(adDetails.ad_duration)
      setDailyBudget(adDetails.ad_daily_budget)
    }
  }, [listingObj, adSeed])
  useEffect(() => {
    if (!!teamAgents) {
      let newAdDetails = {
        ...adDetails,
        brivityAgentId: teamAgents.agents[0].userId
      }
      setAdDetails(newAdDetails)
    }
  }, [teamAgents])
  useEffect(() => {
    if (contentIndex === 0) {
      setTimeout(() => {
        setShowTooltip(true)
      }, 300)
    } else {
      setShowTooltip(false)
    }
  }, [contentIndex])

  useEffect(() => {
    if (termsOpen) {
      setShowTooltip(false)
    } else {
      setTimeout(() => {
        setShowTooltip(true)
      }, 300)
    }
  }, [termsOpen])

  useEffect(() => {}, [formatValue])

const base = window.location.hostname === 'facebooktool.brivitymarketer.com' ? 'brivitymarketer' : 'beta.brivitymarketer'

  useEffect(() => {
    if (facebookAd) {
      console.log(facebookAd)
      setCreatingAd(false)
      if (facebookAd.error) {
        if (facebookAd.error.error) {
          if (facebookAd.error.error.includes('#803')) {
            setSnackbarMessage('There was an error while creating your ad. Facebook error (#803).')
          }
        }
        if (facebookAd.error.code === 406) {
          if(facebookAd.message === "payment exists"){
            setSnackbarMessage("This ad has already been completed. Please return to Brivity Marketer and start a new one there.")
          } else {
            setSnackbarMessage(facebookAd.error.message)
          }
        } else {
          setSnackbarMessage("We couldn't create your ad. Please try again later")
        }
        setErrorSnackbar(true)
        setSnackbarMessage(facebookAd.error.message)
        console.log("Ad couldn't be created because: " + facebookAd.error.message)
      } else {
        window.location.href = `https://${base}.com/v2/ads?success=true`
      }
    }
  }, [base, facebookAd, history, onAdCreated, standalone])
  const controlledAutoCompleteI = (event, value) => {}
  const handleDeleteI = (chipToDelete) => () => {
    setSelectedItemI((chips) => chips.filter((chip) => chip.name !== chipToDelete.name))
  }
  const handleChangeI = (event, newValue) => {
    if (handleCheckI(newValue) === false) {
      setSelectedItemI([...selectedItemI, newValue])
    }
    setInputValueI('')
  }
  const handleCheckI = (val) => {
    return selectedItemI.some((item) => val.name === item.name)
  }

  const askServerAutoComplete = (event, value) => {
    setInputValue(value)
    if (value !== '' && value !== ' ') {
      getReadyAutoComplete(value, targetValue, 'A', setLocationReadyObj)
    } else {
      setLocationObj(null)
      setLocationAuto('')
      setInputValue('')
    }
  }
  const handleDelete = (chipToDelete) => () => {
    setSelectedItem((chips) =>
      chips.filter((chip) => chip.name !== chipToDelete.name || chip.region !== chipToDelete.region)
    )
  }
  const handleChange = (event, newValue) => {
    if (newValue.region !== '' && handleCheck(newValue) === false) {
      setSelectedItem([...selectedItem, newValue])
    }
    setInputValue('')
  }
  const handleCheck = (val) => {
    return selectedItem.some((item) => val.name === item.name && val.region === item.region)
  }

  const onOpenHeaderList = (event) => {
    if (
      headerList !== null &&
      queries.adType !== 'buyerSearch' &&
      queries.adType !== 'multiple' &&
      queries.adType !== 'readyMade'
    ) {
      setOpenHeaderList(true)
      setShowTooltip(false)
    }
  }

  const onChangeHeaderList = (event, newValue) => {
    setListHeaderValue(newValue)
    setHeaderValue(newValue.text)
    if (newValue.ad_type === 'listed') setAdTypeSelected('listed')
    if (newValue.ad_type === 'sold') setAdTypeSelected('sold')
    if (newValue.ad_type === 'Open House') setAdTypeSelected('openHouse')
    if (newValue.ad_type === 'Buyer Search') setAdTypeSelected('buyerSearch')
    if (newValue.ad_type === 'Home Value') setAdTypeSelected('homeValue')
    if (
      (adtypeSelected === 'homeValue' && newValue.ad_type === 'Home Value') ||
      (currentAdText === null && newValue.ad_type !== 'Home Value')
    ) {
      return null
    }

    if (newValue.ad_type === 'Home Value') {
      let xx = { ...listingObj }
      setListingObjBck(xx)

      setImageIndexBck(adDetails.image_index)
      setAdTypeSelected('homeValue')
      setFilteredPhotos(
        homeValuePhotoList.images.map((item, index) => {
          return { url: item.image_url, order: index }
        })
      )
      setIndex(0)
      let x = { ...listingObj }
      x.photos = homeValuePhotoList.images.map((item, index) => {
        return { url: item.image_url, order: index }
      })
      x.image_index = 0
      x.remarks =
        'Are you considering selling your home in the near future? Your home may be worth more than you think.'
      let newAdDetails = {
        ...adDetails,
        ad_description: x.remarks,
        image_index: 0
      }
      setAdDetails(newAdDetails)
      setCurrentAdText(updatedAdText)
      setUpdatedAdText(x.remarks)

      setListingObj(x)
    } else {
      if (newValue.ad_type === 'listed') setAdTypeSelected('listed')
      if (newValue.ad_type === 'sold') setAdTypeSelected('sold')
      if (newValue.ad_type === 'Open House') setAdTypeSelected('openHouse')
      if (newValue.ad_type === 'Buyer Search') setAdTypeSelected('buyerSearch')

      setFilteredPhotos(listingObjBck.photos)
      let x2 = { ...listingObj }
      x2.photos = listingObjBck.photos
      x2.image_index = imageIndexBck
      x2.remarks = currentAdText
      let newAdDetails = {
        ...adDetails,
        ad_description: x2.remarks,
        image_index: imageIndexBck
      }
      setAdDetails(newAdDetails)
      setUpdatedAdText(currentAdText)
      setListingObj(x2)

      setIndex(imageIndexBck)
    }
  }
  const onChangeHeaderText = (event) => {
    if (editHeaderValue) setHeaderValue(event.target.value)
  }
  const onChangeLinkText = (event) => {
    if (editLinkValue) setLinkValue(event.target.value)
  }
  const handleDeleteTags = (chipToDelete) => () => {
    setSelectedItemTags((chips) => chips.filter((chip) => chip.name !== chipToDelete.name))
  }
  const handleChangeTags = () => {
    if (handleCheckTag({ name: tagsValue }) === false) {
      setSelectedItemTags([...selectedItemTags, { name: tagsValue }])
    }
    setTagsValue('')
  }
  const handleCheckTag = (val) => {
    return selectedItemTags.some((item) => val.name === item.name)
  }
  const onChangeTagsText = (event) => {
    const value = event.target.value ? event.target.value.replace(/[^0-9a-zA-Z]+/gi, '') : ''
    setTagsValue(value)
  }
  const onCloseHeaderList = (event) => {
    setShowTooltip(true)
    setOpenHeaderList(false)
  }

  const handleEditPageAction = () => {
    setShowTooltip(true)
    setOpenHeaderList(false)
    setSubmitAttempt(false)
    editPageAction()
  }
  const handleSelectPhotos = () => {
    if (formatValue === 'multiple' || queries.adType === 'readyMade') setSelectPhotosMode(true)
  }
  const handleFilterReadyMade = (event, { value }) => {
    setFilterReadyValue(value)
    setIsFirstRender(false)
    setLocalSelected([])
    const reverse_imageuser_arr = readyMadePhotoListcpy.userImages.reverse()
    let tmp_arr = {
      images: [...reverse_imageuser_arr, ...readyMadePhotoListcpy.images].filter((ad) => ad.category === value)
    }

    if (value === 'all') {
      setReadyMadePhotoList(readyMadePhotoListcpy)
      scrollList.current.scrollTo(0, 0)
      return null
    }
    setReadyMadePhotoList(tmp_arr)
    const howMuchToScroll = Math.floor(readyMadePhotoListcpy.userImages.length / 2) * 310

    scrollList.current.scrollTo(0, howMuchToScroll)
  }
  const handleListingObj = (data) => {
    if (data) {
      setListingObj(data.listing)
    }
  }
  const handleAdDuration = (e, newVal) => {
    if (newVal) {
      let newAdDetails = { ...adDetails, ad_duration: newVal }
      setAdDetails(newAdDetails)
      setAdDuration(newVal)
    } else {
      let newAdDetails = {
        ...adDetails,
        ad_duration: Math.floor(e.target.value)
      }
      setAdDetails(newAdDetails)
      setAdDuration(Math.floor(e.target.value))
    }
  }
  const recalculateDays = () => {
    if (adDuration < 3) {
      let newAdDetails = { ...adDetails, ad_duration: 3 }
      setAdDetails(newAdDetails)
      setAdDuration(3)
    } else if (adDuration > 30) {
      let newAdDetails = { ...adDetails, ad_duration: 30 }
      setAdDetails(newAdDetails)
      setAdDuration(30)
    }
  }
  const handleDailyBudget = (e, newVal) => {
    if (newVal) {
      let newAdDetails = { ...adDetails, ad_daily_budget: newVal }
      setAdDetails(newAdDetails)
      setDailyBudget(newVal)
    } else {
      let val = Math.floor(e.target.value)
      let newAdDetails = { ...adDetails, ad_daily_budget: val }
      setAdDetails(newAdDetails)
      setDailyBudget(val)
    }
  }
  const recalculateBudget = () => {
    if (dailyBudget < 10) {
      let newAdDetails = { ...adDetails, ad_daily_budget: 10 }
      setAdDetails(newAdDetails)
      setDailyBudget(10)
    } else if (dailyBudget > 1000) {
      let newAdDetails = { ...adDetails, ad_daily_budget: 30 }
      setAdDetails(newAdDetails)
      setDailyBudget(1000)
    }
  }
  const handelEditHeaderText = () => {
    setEditHeaderValue(!editHeaderValue)
  }
  const handleEditLinkText = () => {
    setEditLinkValue(!editLinkValue)
  }

  const getVideoAssets = () => {
    if (adSeed?.mediaType === 'video' && adSeed?.assets?.length > 0) {
      return adSeed.assets.map(video => ({
        id: video.fbVideoId,
        imageUrl: video.imageUrl,
        aspectRatio: video.aspectRatio,
      }));
    }
    return undefined; 
  }

  const getVideoAdsForCheckoutPage = () => {
    if (adSeed?.mediaType === 'video' && adSeed?.assets?.length > 0) {
      return adSeed.assets.map(video => ({
        id: video.fbVideoId,
        imageUrl: video.imageUrl,
        aspectRatio: video.aspectRatio,
        videoUrl: video.url
      }));
    }
    return undefined; 
  }

  const handleCreateAd = () => {
    if (!queries.ad_id) {
      setErrorSnackbar(true)
      setSnackbarMessage('Ad needs to be created in marketer and be valid.')

      return null
    }

    if (updatedAdText.length > 500) {
      setErrorSnackbar(true)
      setSnackbarMessage("The property description can't be more than 500 characters long.")

      return null
    }

    if (imageAd && imageAd.message) {
      setErrorSnackbar(true)
      setSnackbarMessage(imageAd.message)

      return null
    }

    if (queries.adType === 'readyMade' && (selectedItem.length === 0 || selectedItemI.length === 0)) {
      setErrorSnackbar(true)
      setSnackbarMessage('You need to select at least 1 interest group and target area')

      return null
    }

    if (!adDetails.page_id) {
      setErrorSnackbar(true)
      setSnackbarMessage('You need to select a Facebook Page before proceeding.')

      return null
    }

    if (!termsCheckbox) {
      setErrorSnackbar(true)
      setSnackbarMessage('You need to agree to the terms of use before proceeding.')

      return null
    }

    let arrayLocations_tmp = []
    let arrayInterests_tmp = []
    if (queries.adType === 'readyMade') {
      selectedItem.map((el, i) => {
        arrayLocations_tmp = [...arrayLocations_tmp, { key: el.key, type: el.type, regionID: parseInt(el.region_id) }]
      })
      selectedItemI.map((el, i) => {
        arrayInterests_tmp = [...arrayInterests_tmp, { id: el.id, name: el.name }]
      })
    }

    let arrayMultiple_tmp = []

    if (queries.adType === 'buyerSearch') {
      buyerSearchObj.data.map((el, i) => {
        arrayMultiple_tmp = [...arrayMultiple_tmp, { mls: el.mlsId, mlsNum: el.mlsNum }]
      })
    }

    if (queries.adType === 'multiple') {
      multipleLisObj.map((el, i) => {
        arrayMultiple_tmp = [
          ...arrayMultiple_tmp,
          {
            mls: el.fieldValues !== undefined ? el.fieldValues.blueroofMlsId : el.listing.blueroofMlsId,
            mlsNum: el.listing.mlsNum
          }
        ]
      })
    }
    let tags_tmp = ''
    selectedItemTags.map((item, index) => {
      tags_tmp = tags_tmp + (index ? ',' : '') + item.name
    })

    let array_tmp = []
    if (formatValue === 'multiple' || queries.adType === 'readyMade') {
      localSorted.map((item) => {
        array_tmp = [...array_tmp, item.url]
      })
    }
    setSubmitAttempt(true)

    const formattedPhotos = isSeededAd ? formatAssetsForPhotos(adSeed.assets) : undefined

    const getImageUrl = () => {
      // Check for a seeded ad and it's not a readyMade ad
      if (adSeed?.mediaType === "video") {
        return adSeed?.assets?.length > 0 && adSeed?.assets[0].imageUrl
      }

      // Directly return the image URL if it's a readyMade ad
      if (queries.adType === 'readyMade') {
        return array_tmp[0]
      }

      // Check for a seeded ad and it's not a readyMade ad
      if (isSeededAd) {
        return formattedPhotos[adDetails.image_index].url
      }

      // Check if the format is 'single' and not 'multiple', and it's not a readyMade ad
      if (formatValue === 'single' && queries.adType !== 'multiple') {
        return listingObj.photos[adDetails.image_index].url
      }

      // Default to an empty string if none of the conditions above are met
      return ''
    }

    const getAdCategory = () => {
      if(queries.adType === 'readyMade' && !isSeededAd && !isVideoSeededAd) {
        return localSorted[0].category
      }

      return ""
    }

    const getImagesUrl = () => {
      if(formatValue === 'multiple') {
        if(isSeededAd && array_tmp.length === 0) {
          return adSeed?.assets
        }

        return array_tmp;
      }

      return []
    }

    const image = getImageUrl()
    
    const images = getImagesUrl() 

    const videoAssets = getVideoAssets()

    const videoAssetsForCheckoutPage = getVideoAdsForCheckoutPage()

    const adCategory = getAdCategory() 

    if (userFbAuth) {
      if (termsCheckbox && adDetails.page_id) {
        let newAdDetails = { ...adDetails, from_listing: true }
        setAdDetails(newAdDetails)
        if (standalone) {
          let total = adDetails.ad_duration * dailyBudget * 0.125 + adDetails.ad_duration * dailyBudget

          let creditMod = 0
          if (total < adDetails.credit) {
            creditMod = adDetails.credit - total
            total = 0
          } else {
            total = total - adDetails.credit
          }
          let data = {
            days: adDetails.ad_duration,
            dailyBudget: dailyBudget,
            total,
            credit: adDetails.credit,
            targetLocations: arrayLocations_tmp,
            targetInterests: arrayInterests_tmp,
            adCategory,
            name: headerValue,
            message: updatedAdText,
            image,
            images,
            tags: tags_tmp,
            mls: listingObj.mlsId,
            mlsNum: listingObj.mlsNum,
            page_id: adDetails.page_id ? adDetails.page_id : '',
            status: 'PAUSED',
            website: adDetails.website,
            callToActionUrl: adtypeSelected === 'homeValue' || queries.adType === 'readyMade' ? linkValue : '',
            description:
              adtypeSelected === 'homeValue' ? 'Enter your address and get instant access to your home’s value.' : '',
            adType:
              queries.adType === 'readyMade'
                ? 'readyMade'
                : queries.adType === 'buyerSearch'
                  ? 'buyerSearch'
                  : queries.adType === 'multiple'
                    ? 'multiple'
                    : adtypeSelected,
            mediaType: adSeed?.mediaType || "",
            multipleHomes: arrayMultiple_tmp,
            mlsAddress:
              queries.adType === 'buyerSearch'
                ? searchCriteria
                : queries.mlsAddress !== undefined
                  ? queries.mlsAddress
                  : '',
            latitude: queries.adType === 'homeValue' ? queries.lat : '',
            longitude: queries.adType === 'homeValue' ? queries.lng : '',
            searchOptions: queries.adType === 'buyerSearch' ? buyerSearchObj?.searchOptions : [],
            agent_email: queries.agent_email !== undefined ? queries.agent_email : '',
            pageToken: adDetails.pageToken,
            isPersonal: selectedPaymentOption.isPersonal,
            paymentProfileId: selectedPaymentOption.paymentProfileId,
            internalAdId: queries.ad_id,
            ...(videoAssets && { videoAssets }) // only add the field + data if not undefined.
          }
          if (adDetails.page_id) data.page_id = adDetails.page_id
          setCreatingAd(true)
          setErrorSnackbar(false)
          setSnackbarMessage('')
          adCreateAction()
          if (queries.debug !== undefined && queries.debug === 'true') {
            data = { ...data, debug: true }
          }
          if (queries.adType === 'buyerSearch')
            setDataFacebook({
              ...data,
              checkoutVideo: videoAssetsForCheckoutPage,
              first_buyer_photo: buyerSearchObj?.data[0].main_photo
            })
          else if (queries.adType === 'multiple')
            setDataFacebook({
              ...data,
              checkoutVideo: videoAssetsForCheckoutPage,
              first_buyer_photo: multipleLisObj[0].listing.photos[0]?.url,
              headerTitleMultiple: multipleLisObj[0]
            })
          else if (queries.adType === 'readyMade')
            setDataFacebook({
              ...data,
              checkoutVideo: videoAssetsForCheckoutPage,
              first_buyer_photo: encodeURI(array_tmp[0]),
              headerTitleMultiple: selectedItem
            })
          else setDataFacebook({...data, checkoutVideo: videoAssetsForCheckoutPage,})
          postFacebookAd(data, setFacebookAd)
          console.log(data, selectedItem)
        } else {
          history.push({ pathname: `/checkout/${listingId}` })
        }
      }
    } else {
      if (termsCheckbox && adDetails.page_id) {
        let newAdDetails = { ...adDetails, from_listing: true }
        setAdDetails(newAdDetails)
        if (standalone) {
          let total =
            adDetails.ad_duration * adDetails.ad_daily_budget * 0.2 + adDetails.ad_duration * adDetails.ad_daily_budget
          let data = {
            days: adDetails.ad_duration,
            dailyBudget: dailyBudget,
            total,
            credit: adDetails.credit,
            targetLocations: arrayLocations_tmp,
            targetInterests: arrayInterests_tmp,
            name: headerValue,
            message: updatedAdText,
            image,
            images,
            mls: listingObj.mlsId,
            mlsNum: listingObj.mlsNum,
            page_id: adDetails.page_id ? adDetails.page_id : '',
            status: 'PAUSED',
            website: adDetails.website,
            callToActionUrl: adtypeSelected === 'homeValue' ? linkValue : '',
            description:
              adtypeSelected === 'homeValue' ? 'Enter your address and get instant access to your home’s value.' : '',
            adType:
              queries.adType === 'readyMade'
                ? 'readyMade'
                : queries.adType === 'buyerSearch'
                  ? 'buyerSearch'
                  : queries.adType === 'multiple'
                    ? 'multiple'
                    : adtypeSelected,
            mediaType: adSeed?.mediaType || "",
            multipleHomes: arrayMultiple_tmp,
            mlsAddress:
              queries.adType === 'buyerSearch'
                ? searchCriteria
                : queries.mlsAddress !== undefined
                  ? queries.mlsAddress
                  : '',
            latitude: queries.adType === 'homeValue' ? queries.lat : '',
            longitude: queries.adType === 'homeValue' ? queries.lng : '',
            searchOptions: queries.adType === 'buyerSearch' ? buyerSearchObj?.searchOptions : [],
            agent_email: queries.agent_email !== undefined ? queries.agent_email : '',
            isPersonal: selectedPaymentOption.isPersonal,
            paymentProfileId: selectedPaymentOption.paymentProfileId,
            internalAdId: queries.ad_id,
            ...(videoAssets && { videoAssets }) // only add the field + data if not undefined.
          }
          if (adDetails.page_id) data.page_id = adDetails.page_id
          setCreatingAd(true)
          setErrorSnackbar(false)
          setSnackbarMessage('')
          adCreateAction()
          if (queries.debug !== undefined && queries.debug === 'true') {
            data = { ...data, debug: true }
          }
          if (queries.adType === 'buyerSearch')
            setDataFacebook({
              ...data,
              first_buyer_photo: buyerSearchObj?.data[0].main_photo,
            })
          else if (queries.adType === 'multiple')
            setDataFacebook({
              ...data,
              first_buyer_photo: multipleLisObj[0].listing.photos[0]?.url,
              headerTitleMultiple: multipleLisObj[0]
            })
          else setDataFacebook(data)
          postFacebookAd(data, setFacebookAd)
        } else {
          history.push({ pathname: `/checkout/${listingId}` })
        }
      }
    }
  }
  const handleAdLayoutChange = (id) => {
    setAdLayout(id)
    if (id === 0) {
      let newAdDetails = { ...adDetails, ad_name: 'Checkout this hot home!' }
      setAdDetails(newAdDetails)
    } else if (id === 1) {
      let newAdDetails = { ...adDetails, ad_name: 'New home for sale!' }
      setAdDetails(newAdDetails)
    } else if (id === 2) {
      let newAdDetails = { ...adDetails, ad_name: 'See all the photos NOW!' }
      setAdDetails(newAdDetails)
    } else if (id === 3) {
      let newAdDetails = {
        ...adDetails,
        ad_name: 'Take a virtual tour of this home'
      }
      setAdDetails(newAdDetails)
    } else if (id === 4) {
      let newAdDetails = {
        ...adDetails,
        ad_name: 'View the photos, price, and more instantly!'
      }
      setAdDetails(newAdDetails)
    } else if (id === 5) {
      let newAdDetails = {
        ...adDetails,
        ad_name: `You're invited to this open house!`
      }
      setAdDetails(newAdDetails)
    }
  }
  const openEmojiPicker = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const onPickerSelect = (e, emojiObject) => {
    setShowTooltip(true)
    setAnchorEl(null)

    if (anchorEl.id === 'insert-emoticon') {
      if (updatedAdText.length > 0) {
        const { selectionStart, selectionEnd } = inputEl.current
        const newVal = updatedAdText.slice(0, selectionStart) + emojiObject.emoji + updatedAdText.slice(selectionEnd)

        setUpdatedAdText(newVal)
      } else {
        const { selectionStart, selectionEnd } = inputEl.current
        const newVal = emojiObject.emoji

        setUpdatedAdText(newVal)
      }
    }

    if (anchorEl.id === 'insert-emoticon-ad') {
      if (headerValue.length > 0) {
        const { selectionStart, selectionEnd } = inputElAd.current
        const newVal = headerValue.slice(0, selectionStart) + emojiObject.emoji + headerValue.slice(selectionEnd)
        setHeaderValue(newVal)
      } else {
        const { selectionStart, selectionEnd } = inputElAd.current
        const newVal = emojiObject.emoji
        setHeaderValue(newVal)
      }
    }
  }

  const updateAdText = (val) => {
    if (updatedAdText && updatedAdText.length < 500) {
      setOpen(false)
    }
    setUpdatedAdText(val)
  }
  const resetUpdatedAdtext = () => {
    setUpdatedAdText(listingObj.remarks)
  }
  const handleTermsOpen = () => setTermsOpen(true)
  const handleTermsClose = () => setTermsOpen(false)
  const handleOpenRequirement = () => setOpenRequirements(true)
  const handleCloseRequirement = () => setOpenRequirements(false)
  const closeEditAdText = () => {
    DEBUG_MODE && console.log({ adDetails })
    DEBUG_MODE && console.log({ listingObj })
    setUpdatedAdText(cancelText)
    setEditAdText(false)
    setIsFirstRender(false)
  }

  const closeSelectReadyPhotoMode = () => {
    if (queries.adType === 'readyMade' && localSorted.length === 0) {
      setErrorSnackbar(true)
      setSnackbarMessage('Please select an item to continue.')
      return null
    }
    setFilteredPhotos(localSorted)
    setIndexPreview(0)
    setSelectPhotosMode(false)
    setIsFirstRender(false)
  }
  const closeSelectPhotoMode = () => {
    setIsFirstRender(false)
    if (localSorted.length > 1) {
      setIndexPreview(0)
      setFilteredPhotos(localSorted)
      setSelectPhotosMode(false)
    } else {
      setErrorSnackbar(true)
      setSnackbarMessage('Please select at least two photos to continue.')
      return null
    }
  }
  const cancelSelectPhotoMode = () => {
    setSelectPhotosMode(false)
    setIsFirstRender(false)
  }
  const startInstaMode = () => {
    setInstaPreviewMode(true)
    DEBUG_MODE && console.log(instaPreviewMode)
  }
  const closeInstaMode = () => {
    setInstaPreviewMode(false)
  }

  const updateEditAdText = () => {
    setInstaPreviewMode(false)
    setErrorSnackbar(false)
    if (editAdText) {
      let tempAdDetails = { ...adDetails, ad_description: updatedAdText }
      setAdDetails(tempAdDetails)
      if (formatValue === 'multiple' && localSorted.length > 0) {
        setFilteredPhotos(localSorted)
        setIndexPreview(0)
      }
      if (formatValue === 'single' && queries.adType !== 'homeValue') {
        if (adSeed?.assets?.length && adSeed?.mediaType === 'image') {
          const formattedAssets = formatAssetsForPhotos(adSeed.assets)

          setFilteredPhotos(formattedAssets)
        } else {
          setFilteredPhotos(listingObj.photos)
        }
      }
      if (queries.adType === 'readyMade' && localSorted.length > 0) {
        setFilteredPhotos(localSorted)
        setIndexPreview(0)
      }

      if (updatedAdText.length > 499) {
        setErrorSnackbar(true)
        setSnackbarMessage("The property description can't be more than 500 characters long.")
        return null
      } else {
        setOpen(false)
      }
    } else {
      setCancelText(updatedAdText)
    }

    setEditAdText(!editAdText)
  }
  const handlePrevIndex = () => {
    if (index > 0) {
      let tempAdDetails = {
        ...adDetails,
        image_index: adDetails.image_index - 1
      }
      setAdDetails(tempAdDetails)
      setIndex(index - 1)
    }
  }
  const handleNextIndex = () => {
    if (listingObj && index + 1 < listingObj.photos.length) {
      let tempAdDetails = {
        ...adDetails,
        image_index: adDetails.image_index + 1
      }
      setAdDetails(tempAdDetails)
      setIndex(index + 1)
    }
  }
  const handlePrevIndexPreview = () => {
    if (indexPreview > 0) {
      setIndexPreview(indexPreview - 1)
    }
  }
  const handleNextIndexPreview = () => {
    if (filteredPhotos && indexPreview + 1 < filteredPhotos.length) {
      setIndexPreview(indexPreview + 1)
    }
  }
  const handleAgentSelect = (e) => {
    let newAdDetails = {
      ...adDetails,
      brivityAgentId: teamAgents.agents[e.target.value].userId
    }
    setSelectedAgent(e.target.value)
    setAdDetails(newAdDetails)
  }
  const handleTermsCheckbox = () => (setTermsCheckbox(!termsCheckbox), setSubmitAttempt(false))
  const handleSubmitAttempt = () => setSubmitAttempt(submitAttempt)

  const handleFormatChange = (event) => {
    setShowTooltip(true)
    setOpenHeaderList(false)
    setFormatValue(event.target.value)
    if (event.target.value === 'multiple' && localSorted.length > 0) {
      setFilteredPhotos(localSorted)
    }
    if (event.target.value === 'single') {
      if (adSeed?.mediaType === 'image' && adSeed?.assets) {
        const formattedAssets = formatAssetsForPhotos(adSeed.assets)
        setFilteredPhotos(formattedAssets)
      } else {
        setFilteredPhotos(listingObj.photos)
      }
    }
  }
  const handleTargetChange = (event, data) => {
    setTargetValue(data)
  }

  //========================== SORTED PHOTO LOGIC DND=====================================

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }
  /*********************************** */
  const portal = document.createElement('div')
  portal.classList.add('my-super-cool-portal')
  document.body.appendChild(portal)
  /*********************************** */
  const grid = 8
  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    padding: 0,
    margin: `0 1px 0 0`,
    position: isDragging ? 'absolute' : 'static',
    ...draggableStyle
  })
  const getListStyle = (isDraggingOver) => ({
    background: 'white',
    display: 'flex',
    padding: grid,
    height: '5rem',
    overflow: 'auto'
  })

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    const items_temp = reorder(localSorted, result.source.index, result.destination.index)
    setLocalSorted(items_temp)
  }

  function sleep(delay = 0) {
    return new Promise((resolve) => {
      setTimeout(resolve, delay)
    })
  }

  //========================== SELECT PHOTO LOGIC =====================================
  const selectedHandler = (objSel) => (event) => {
    if (localSelected.length < 10) {
      if (!isSelected(objSel)) {
        setLocalSelected((localSelected) => [...localSelected, objSel])
        DEBUG_MODE && console.log('added: ', objSel)
      } else {
        if (localSelected.length > 2) {
          removeFromSelected(objSel)
          DEBUG_MODE && console.log('Already on list: ', localSelected.indexOf(objSel))
        }
      }

      DEBUG_MODE && console.log('Already on list?: ', isSelected(objSel))
    }

    if (isSelected(objSel) && localSelected.length === 10) {
      removeFromSelected(objSel)
      DEBUG_MODE && console.log('Already on list: ', localSelected.indexOf(objSel))
    }
  }
  const selectedHandlerReady = (objSel) => (event) => {
    setLocalSelected([objSel])
    if (objSel.category === 'recruiting') {
      let defaultText =
        'Dedicated real estate professionals wanted! Apply today and help us deliver the dream of home ownership.'

      let newAdDetails = { ...adDetails, ad_description: defaultText }
      setAdDetails(newAdDetails)
      setUpdatedAdText(defaultText)
      setHeaderValue('Ready to take your real estate career to the next level?')
    } else if (objSel.category === 'branding') {
      let defaultText = 'Buying or selling a home can seem daunting, but don’t worry - we’re here to help!'

      let newAdDetails = { ...adDetails, ad_description: defaultText }
      setAdDetails(newAdDetails)
      setUpdatedAdText(defaultText)
      setHeaderValue('Need a real estate pro? Look no further!')
    } else {
      let defaultText = 'Don’t wait to chase your dreams, start searching for your new home today!'

      let newAdDetails = { ...adDetails, ad_description: defaultText }
      setAdDetails(newAdDetails)
      setUpdatedAdText(defaultText)
      setHeaderValue('Ready to make your dream home a reality?')
    }
  }
  const ddnSt = dropdownStyles()
  const selectedTile = (tile) => (event) => {
    if (tile !== currentSelected) setCurrentSelected(tile)
  }

  const isSelected = (objSel) => {
    const array = [...localSelected]

    if (localSelected?.[0]?.order === objSel?.order) {
      return true
    }
    if (array.indexOf(objSel) !== -1) {
      return true
    } else {
      return false
    }
  }
  const isCurrentSelected = (objSel) => {
    if (currentSelected === objSel) {
      return true
    }
    return false
  }
  const removeFromSelected = (objSel) => {
    const array = [...localSelected]

    var index = array.indexOf(objSel)
    if (index !== -1) {
      array.splice(index, 1)
      setLocalSelected(array)
    }
  }

  //----------------------------------------------------------------------------------

  // checkout handlers and helpers
  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setErrorSnackbar(false)
    setSnackbarMessage('')
  }

  const handleCloseAdTool = useCallback(() => {
    if (window.location.hostname === 'facebooktool.brivitymarketer.com') {
      window.location.href = 'https://brivitymarketer.com/v2/listings'
      return null
    }
    if (adDetails.appBMContext === 'ads') {
      window.location.href = 'https://ads.brivitymarketer.com/v2/listings'
      return null
    }

    if (
      window.location.hostname === 'listings.staging.brivitymarketer.com' ||
      window.location.hostname === 'localhost'
    ) {
      window.location.href = 'https://beta.brivitymarketer.com/v2/listings'
      return null
    }

    if (adDetails.appBMContext === 'beta') {
      window.location.href = 'https://beta.brivitymarketer.com/v2/listings'
    } else {
      window.location.href = 'https://brivitymarketer.com/v2/listings'
    }
  }, [])

  const getModalHeaderText = () => {
    let header = `Create${adSeed?.mediaType === 'video' ? ' Video' : ''} Ad`
    if (queries?.debug === 'true') {
      header += ' - DEBUG MODE'
    }
    return header;
  }

  const isSeededAd = adSeed?.mediaType === 'image'

  const carouselPhotos = isSeededAd ? formatAssetsForPhotos(adSeed.assets) : listingObj?.photos

  // render
  if (listingObj === null) {
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress color="primary" size={75} />
      </div>
    )
  } else {
    return (
      <Fragment>
        {open === true && (
          <Mes negative style={{ margin: 0, display: 'flex', flexWrap: 'wrap' }}>
            <Mes.Header style={{ flexShrink: 0, paddingRight: '1rem' }}>500 character limit reached.</Mes.Header>
            <Mes.Content>The property description can't be more than 500 characters long.</Mes.Content>
          </Mes>
        )}
        <Popover
          id={'id-popover'}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <Picker onEmojiClick={onPickerSelect} />
        </Popover>
        <Grid container spacing={3} className={standalone ? classes.mainContainerSmall : classes.mainContainer}>
          <Grid item xs={standalone ? 6 : 12} className={classes.noBottomPadding}>
            <Typography
              variant="h4"
              style={{
                fontFamily: 'Open Sans',
                color: theme.palette.primary.main,
                fontSize: '32px'
              }}
            >
              {getModalHeaderText()}
            </Typography>
          </Grid>

          <Grid item xs={5} className={`${classes.alignStart} ${classes.noBottomPadding}`}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="large" />}
              aria-label="breadcrumb"
              className={classes.noBottomPadding}
            >
              <Typography
                style={{
                  fontFamily: 'Open Sans',
                  color: 'black',
                  fontWeight: '350',
                  fontSize: '19px'
                }}
              >
                Import Listing
              </Typography>
              <Typography
                style={{
                  fontFamily: 'Open Sans',
                  color: 'black',
                  fontWeight: '500',
                  fontSize: '19px'
                }}
              >
                Ad Setting
              </Typography>
              <Typography
                style={{
                  fontFamily: 'Open Sans',
                  color: 'black',
                  fontWeight: '350',
                  fontSize: '19px'
                }}
              >
                Payment
              </Typography>
            </Breadcrumbs>
          </Grid>

          {contentIndex !== 1 && editAdText !== true && (
            <Grid item xs={1} className={`${classes.alignEnd} ${classes.noBottomPaddingX}`}>
              <IconButton onClick={handleCloseAdTool} aria-label="close ad creation modal">
                <CloseIcon />
              </IconButton>
            </Grid>
          )}

          <Grid item xs={12} className={classes.yesBottomPadding}>
            <div
              style={{
                width: '100%!important',
                borderBottom: `1px solid #E6E6E6`
              }}
            />
          </Grid>

          <Snackbar
            open={errorSnackbar}
            autoHideDuration={null}
            onClose={handleErrorSnackbarClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            className={classes.snackbarComponent}
          >
            <Alert onClose={handleErrorSnackbarClose} severity="error">
              <span>
                {snackbarMessage}
                {snackbarMessage.includes("You're not a owner of this facebook page: ") && (
                  <Button color="primary" size="small" onClick={handleOpenRequirement}>
                    Learn More
                  </Button>
                )}
              </span>
            </Alert>
          </Snackbar>

          {selectPhotosMode && [
            <Grid item xs={8} className={classes.fullContainer} classes={{ root: classes.smallBottomPaddingRow }}>
              <Typography
                id="ad-duration-label"
                variant="body2"
                gutterBottom
                className={`${classes.marketerGrey}`}
                style={{ fontWeight: '600', fontSize: '15px' }}
              >
                {queries.adType !== 'readyMade' && 'PHOTO SELECTION'}
              </Typography>
              <Typography fullWidth variant="subtitle2" className={classes.darkestGrey} style={{ fontSize: '13px' }}>
                {queries.adType !== 'readyMade' &&
                  'Select at least 2 photos you would like to use in your carousel ad (10 photo maximum). When uploading a photo, ensure that any text on your image covers less than 20% of the area.'}
              </Typography>
              {queries.adType === 'readyMade' && !isSeededAd && (
                <Dropdown
                  inline
                  style={{
                    fontWeight: '600',
                    fontSize: '18px',
                    fontFamily: 'Open Sans',
                    width: '200px',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                  options={filterOptions}
                  onChange={handleFilterReadyMade}
                  value={filterReadyValue}
                  defaultValue={filterOptions[0].value}
                />
              )}
            </Grid>,
            <Grid
              item
              xs={4}
              className={`${classes.fullWidth} ${classes.alignEnd}`}
              classes={{ root: classes.smallBottomPaddingRow }}
            >
              {queries.adType !== 'readyMade' && adSeed?.mediaType !== "image" && (
                <Tooltip
                  arrow
                  placement="left"
                  className={classes.imgArrowContainerLarge}
                  classes={{ tooltip: classes.customWidth }}
                  title={
                    <span className={classes.customTooltip}>
                      Your image needs to be at least 600 x 600 pixels and under 30 MB. Recommended size is 1200x1200
                      pixels.
                    </span>
                  }
                  aria-label="Select or upload your ad photo (exterior recommended)"
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    size={'large'}
                    className={queries.adType === 'readyMade' ? classes.UploadReadyButtonGallery : classes.UploadButton}
                    onClick={uploadFileClick}
                    style={{
                      border: '3px solid',
                      fontSize: '14px',
                      fontWeight: 'bold'
                    }}
                  >
                    {queries.adType === 'readyMade' ? 'Upload Custom Ad' : 'Upload Photo'}
                  </Button>
                </Tooltip>
              )}
              {!isSeededAd && queries.adType === 'readyMade' && (
                <div id="DragAndDropGridWrapper">
                  <DragAndDrop
                    onFileUpload={(image) => {
                      if (image.size >= eightMb) {
                        setErrorSnackbar(true)
                        setSnackbarMessage('The selected image is to large, must be less than 8 MB')
                      } else if (!allowedImageTypes.includes(image.type)) {
                        setErrorSnackbar(true)
                        setSnackbarMessage('Failed to upload image, please ensure the file format is PNG or JPG.')
                      } else {
                        setErrorSnackbar(false)
                        postImageAd(image, setImageAd)
                      }
                    }}
                  />
                </div>
              )}
            </Grid>
          ]}
          {selectPhotosMode && (
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              spacing={1}
              className={`${classes.editIconContainer} ${classes.opacityActive}`}
            >
              <Grid item xs={12} style={{ paddingTop: '25px', paddingBottom: '25px' }}>
                {listingObj && (
                  <GridList
                    ref={scrollList}
                    cellHeight={queries.adType === 'readyMade' ? 306 : 180}
                    className={classes.gridListCss}
                    style={{
                      overflow: 'scroll',
                      height: queries.adType === 'readyMade' ? 630 : 400
                    }}
                    cols={queries.adType === 'readyMade' ? 3 : 4}
                    rows={queries.adType === 'readyMade' ? 2 : 3}
                    spacing={10}
                  >
                    {carouselPhotos.map((tile, i) => {
                      return (
                        <GridListTile
                          key={`listing-photos-${i}`}
                          onMouseOver={selectedTile(tile)}
                          onClick={queries.adType === 'readyMade' ? selectedHandlerReady(tile) : selectedHandler(tile)}
                          style={{
                            border: '1px solid #E6E6E6'
                          }}
                        >
                          <img
                          style={(isCurrentSelected(tile) ? { opacity: 0.5 } : { opacity: 1.0 }, { objectFit: 'contain' })}
                            src={tile.url}
                            alt={tile.url}
                            width={100}
                            height={queries.adType === 'readyMade' ? 306 : 180}
                          />

                          {isSelected(tile) && (
                            <GridListTileBar
                              classes={{
                                root: classes.titleBar,
                                title: classes.title
                              }}
                              style={{ background: 'rgba(0,0,0,0)' }}
                              actionIcon={
                                <IconButton aria-label={`info about ${tile.url}`} className={classes.icono}>
                                  <img src={process.env.PUBLIC_URL + '/onhoverselect.png'} />
                                </IconButton>
                              }
                            />
                          )}
                        </GridListTile>
                      )
                    })}
                  </GridList>
                )}
              </Grid>
            </Grid>
          )}
          {selectPhotosMode && queries.adType !== 'readyMade' && (
            <Grid
              item
              xs={12}
              className={classes.fullContainer}
              classes={{ root: classes.smallBottomPhotoOrderPaddingRow }}
            >
              <Typography
                id="ad-duration-label"
                variant="body2"
                gutterBottom
                className={`${classes.trueBoldFontWeight} ${classes.marketerGrey}`}
                style={{ fontWeight: '600', fontSize: '15px' }}
              >
                PHOTO ORDER
              </Typography>
              <Typography fullWidth variant="subtitle2" className={classes.darkestGrey} style={{ fontSize: '13px' }}>
                Drag and drop the photo previews below to change photo presentation order in your carousel{' '}
              </Typography>
            </Grid>
          )}
          {selectPhotosMode && queries.adType !== 'readyMade' && (
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              spacing={1}
              className={`${classes.editIconContainer} ${classes.opacityActive}`}
            >
              <Grid
                item
                xs={12}
                style={{
                  paddingTop: '5px',
                  paddingBottom: '5px',
                  gridTemplateRows: '50%'
                }}
              >
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable" direction="horizontal">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                        {...provided.droppableProps}
                      >
                        {localSorted.map((item, index) => (
                          <Draggable key={item.url + index} draggableId={item.url + index} index={index}>
                            {(provided, snapshot) =>
                              snapshot.isDragging ? (
                                ReactDOM.createPortal(
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                  >
                                    <img
                                      className={classes.rotateK}
                                      src={item.url}
                                      alt={item.url}
                                      width="100"
                                      height="60"
                                    />
                                  </div>,
                                  portal
                                )
                              ) : (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                >
                                  <img src={item.url} alt={item.url} width="100" height="60" style={{
                                      objectFit: 'contain'
                                    }}/>
                                </div>
                              )
                            }
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Grid>
            </Grid>
          )}
          {!selectPhotosMode && (
            <Grid item xs={6} className={classes.noTopPadding}>
              <div className={`${classes.paperStyles} ${classes.evenCardHeight}`}>
                <AdPreviewCard
                  classes={classes}
                  adDetails={adDetails}
                  setAdDetails={setAdDetails}
                  contentIndex={contentIndex}
                  listHeaderValue={listHeaderValue}
                  buyerSearchObj={buyerSearchObj}
                  multipleLisObj={multipleLisObj}
                  linkValue={linkValue}
                  setListHeaderValue={setListHeaderValue}
                  handelEditHeaderText={handelEditHeaderText}
                  setEditHeaderValue={setEditHeaderValue}
                  adtypeSelected={adtypeSelected}
                  editHeaderValue={editHeaderValue}
                  valueHeaderList={valueHeaderList}
                  openHeaderList={openHeaderList}
                  onOpenHeaderList={onOpenHeaderList}
                  onChangeHeaderList={onChangeHeaderList}
                  onChangeHeaderText={onChangeHeaderText}
                  onCloseHeaderList={onCloseHeaderList}
                  headerValue={headerValue}
                  setHeaderValue={setHeaderValue}
                  adLayout={adLayout}
                  startInstaMode={startInstaMode}
                  closeInstaMode={closeInstaMode}
                  openEmojiPicker={openEmojiPicker}
                  inputEl={inputEl}
                  inputElAd={inputElAd}
                  headerAdLines={headerList !== null && headerList.headlines}
                  instaPreviewMode={instaPreviewMode}
                  handleAdLayoutChange={handleAdLayoutChange}
                  filteredPhotos={filteredPhotos}
                  listingObj={listingObj}
                  localSorted={localSorted}
                  updateAdEdit={updateEditAdText}
                  closeAdEdit={closeEditAdText}
                  editAdText={editAdText}
                  handleAdTextUpdate={updateAdText}
                  setUpdatedAdText={setUpdatedAdText}
                  updatedAdText={updatedAdText}
                  resetUpdatedAdtext={resetUpdatedAdtext}
                  prevIndexPreview={handlePrevIndexPreview}
                  nextIndexPreview={handleNextIndexPreview}
                  indexPreview={indexPreview}
                  prevIndex={handlePrevIndex}
                  nextIndex={handleNextIndex}
                  formatValue={formatValue}
                  index={index}
                  theme={theme}
                  standalone={standalone}
                  userFbAuth={userFbAuth}
                  editPageAction={handleEditPageAction}
                  selectPhotosAction={handleSelectPhotos}
                  queries={queries}
                  showTooltip={showTooltip}
                  uploadFileClick={uploadFileClick}
                  isFirstRender={isFirstRender}
                  adSeed={adSeed}
                />
              </div>
            </Grid>
          )}
          {!selectPhotosMode && (
            <Grid item xs={6} className={classes.noTopPadding}>
              <div className={classes.paperStyles}>
                <AdSettingsCard
                  classes={classes}
                  openRequirements={openRequirements}
                  handleCloseRequirement={handleCloseRequirement}
                  adDuration={adDuration}
                  handleAdDuration={handleAdDuration}
                  queries={queries}
                  counter={counter}
                  userInfo={userInfo}
                  locationObj={locationObj}
                  locationReadyObj={locationReadyObj}
                  interestReadyObj={interestReadyObj}
                  handleChange={handleChange}
                  locationAuto={locationAuto}
                  areas={areas}
                  interests={interests}
                  inputValue={inputValue}
                  selectedItem={selectedItem}
                  handleDelete={handleDelete}
                  askServerAutoComplete={askServerAutoComplete}
                  controlledAutoCompleteI={controlledAutoCompleteI}
                  handleChangeI={handleChangeI}
                  inputValueI={inputValueI}
                  selectedItemI={selectedItemI}
                  handleDeleteI={handleDeleteI}
                  // adType={queries.adType}
                  handleChangeTags={handleChangeTags}
                  selectedItemTags={selectedItemTags}
                  handleDeleteTags={handleDeleteTags}
                  buyerSearchObj={buyerSearchObj}
                  adType={adtypeSelected}
                  dailyBudget={dailyBudget}
                  handleDailyBudget={handleDailyBudget}
                  listingObj={listingObj}
                  updateAdEdit={updateEditAdText}
                  closeAdEdit={closeEditAdText}
                  editAdText={editAdText}
                  onChangeLinkText={onChangeLinkText}
                  onChangeTagsText={onChangeTagsText}
                  linkValue={linkValue}
                  tagsValue={tagsValue}
                  editLinkValue={editLinkValue}
                  handleEditLinkText={handleEditLinkText}
                  handleOpen={handleTermsOpen}
                  handleClose={handleTermsClose}
                  open={termsOpen}
                  formatValue={formatValue}
                  targetValue={targetValue}
                  handleFormatChange={handleFormatChange}
                  handleTargetChange={handleTargetChange}
                  terms={termsCheckbox}
                  handleTermsCheckbox={handleTermsCheckbox}
                  handleCreateAd={handleCreateAd}
                  submitAttempt={submitAttempt}
                  selectPhotosAction={handleSelectPhotos}
                  handleSubmitAttempt={handleSubmitAttempt}
                  standalone={standalone}
                  theme={theme}
                  adDetails={adDetails}
                  userFbAuth={userFbAuth}
                  recalculateDays={recalculateDays}
                  recalculateBudget={recalculateBudget}
                  contentIndex={contentIndex}
                  teamAgents={teamAgents}
                  selectedAgent={selectedAgent}
                  handleAgentSelect={handleAgentSelect}
                  creatingAd={creatingAd}
                  setSelectedPaymentOption={setSelectedPaymentOption}
                  handlePaymentOptionChange={handlePaymentOptionChange}
                  paymentOptions={paymentOptions}
                  selectedPaymentOption={selectedPaymentOption}
                  defaultPaymentOption={defaultPaymentOption}
                  adSeed={adSeed}
                />
              </div>
            </Grid>
          )}
          {selectPhotosMode && (
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              spacing={1}
              className={`${
                queries.adType === 'readyMade'
                  ? classes.paperStyles_bottomButtons_ready
                  : classes.paperStyles_bottomButtons
              } ${classes.editIconContainer} ${classes.opacityActive}`}
            >
              <Grid item xs={1} className={classes.alignEnd}>
                <Button
                  variant="contained"
                  size="large"
                  className={classes.roundButtonCancel}
                  onClick={queries.adType === 'readyMade' ? closeSelectReadyPhotoMode : cancelSelectPhotoMode}
                  style={{ color: marketerGrey, fontSize: '14px' }}
                  disableElevation
                >
                  Cancel
                </Button>
              </Grid>

              <Grid item xs={queries.adType === 'readyMade' ? 9 : 10} className={classes.alignEnd}>
                {queries.adType !== 'readyMade' && (
                  <Typography
                    id="ad-duration-label"
                    variant="body2"
                    gutterBottom
                    className={`${classes.trueBoldFontWeight} ${classes.marketerGrey}`}
                    style={{ fontSize: '13px' }}
                  >
                    {localSorted.length} Photos Selected
                  </Typography>
                )}
              </Grid>

              <Grid item xs={queries.adType === 'readyMade' ? 2 : 1} className={classes.alignEnd}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.roundButtonDone}
                  onClick={queries.adType === 'readyMade' ? closeSelectReadyPhotoMode : closeSelectPhotoMode} // TO DO: DONE LOGIC
                  disableElevation
                  style={{ fontSize: '14px' }}
                >
                  {queries.adType === 'readyMade' ? 'CREATE AD' : 'Done'}
                </Button>
              </Grid>
            </Grid>
          )}
          {editAdText && selectPhotosMode === false && (
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              spacing={1}
              className={`${classes.paperStyles_bottomButtons} ${classes.editIconContainer} ${classes.opacityActive}`}
            >
              <Grid item xs={11} className={classes.alignStart}>
                <Button
                  variant="contained"
                  size="large"
                  className={classes.roundButton}
                  onClick={closeEditAdText}
                  style={{ color: marketerGrey }}
                  disableElevation
                >
                  Cancel
                </Button>
              </Grid>

              <Grid item xs={1} className={classes.alignEnd}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.roundButtonSave}
                  onClick={updateEditAdText}
                  disableElevation
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>

        <form onSubmit={() => false}>
          <div>
            <input
              ref={hiddenFileInput}
              id="file"
              type="file"
              accept=".jpg,.jpeg,.png"
              onChange={onChangeForm}
              style={{ display: 'none' }}
              onClick={(e) => (e.target.value = null)}
            />
          </div>
        </form>
      </Fragment>
    )
  }
}
export default withRouter(Listing)
