import React, { Fragment, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'

import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import PauseIcon from '@material-ui/icons/Pause'
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled'
import VisibilityIcon from '@material-ui/icons/Visibility'
import CancelIcon from '@material-ui/icons/Cancel'
import RefreshIcon from '@material-ui/icons/Refresh'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import RepeatOneIcon from '@material-ui/icons/RepeatOne'
import ErrorIcon from '@material-ui/icons/Error'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

import { trimText, humanTime } from '../util/Helpers'
import { green, yellow, facebookBlue, red, darkGrey } from '../assets/colorPalette'

import api from '../services/api'

const useStyles = createUseStyles({
  paperStyles: {
    padding: 8,
    margin: 16
  },
  mainListingImageContainer: {
    width: 120,
    height: 86
  },
  bottomSmallMargin: {
    marginBottom: 8
  },
  alignCenter: {
    textAlign: 'center'
  },
  mainListingImage: {
    height: '100%',
    width: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: 8,
    marginLeft: 8
  },
  actionContainer: {
    marginBottom: 8,
    display: 'flex',
    justifyContent: 'space-around'
  },
  tableHeaderContainer: {
    borderBottom: '1px solid'
  }
})

const AdCard = ({ ad, status, deleteAction, typeAction, tooltip }) => {
  const classes = useStyles()

  let adDate = new Date(ad.created_at)
  let day = adDate.getUTCDate()
  let month = adDate.getMonth() + 1
  let year = adDate.getUTCFullYear()
  let time = adDate.toLocaleTimeString(['en-US'], {
    hour: '2-digit',
    minute: '2-digit'
  })
  let humanizedDate = humanTime(adDate)

  let variants = {}
  let actions = {
    pause: (
      <Tooltip title="Pause ad" placement="top" arrow>
        <IconButton aria-label="pause ad" color="primary" onClick={() => typeAction(ad.mls_num)}>
          <PauseIcon size="large" />
        </IconButton>
      </Tooltip>
    ),
    resume: (
      <Tooltip title="Resume ad" placement="top" arrow>
        <IconButton aria-label="resume ad" color="primary" onClick={() => typeAction(ad.mls_num)}>
          <PlayArrowIcon size="large" />
        </IconButton>
      </Tooltip>
    ),
    resubmit: (
      <Tooltip title="Resubmit ad" placement="top" arrow>
        <IconButton aria-label="resubmit ad" color="primary" onClick={() => typeAction(ad.mls_num)}>
          <RefreshIcon size="large" />
        </IconButton>
      </Tooltip>
    ),
    rerun: (
      <Tooltip title="Create new ad from this one" placement="top" arrow>
        <IconButton aria-label="Create new ad from this one" color="primary" onClick={() => typeAction(ad.mls_num)}>
          <RepeatOneIcon size="large" />
        </IconButton>
      </Tooltip>
    )
  }

  if (status === 'ACTIVE') {
    variants = {
      chip: {
        icon: <CheckCircleRoundedIcon />,
        label: 'Running',
        color: green
      },
      action: actions.pause
    }
  }
  if (status === 'PAUSED') {
    variants = {
      chip: {
        icon: <PauseCircleFilledIcon />,
        label: 'Paused',
        color: yellow
      },
      action: actions.resume
    }
  }
  if (status === 'PENDING_REVIEW') {
    variants = {
      chip: {
        icon: <VisibilityIcon />,
        label: (
          <div>
            Pending
            <br />
            Facebook Review
          </div>
        ),
        color: facebookBlue
      },
      action: actions.pause
    }
  }
  if (status === 'REJECTED') {
    variants = {
      chip: {
        icon: <CancelIcon />,
        label: 'Rejected',
        color: red
      },
      action: actions.resubmit
    }
  }
  if (status === 'WITH_ISSUES') {
    variants = {
      chip: {
        tooltip,
        icon: <ErrorIcon />,
        label: 'Error',
        color: red
      },
      action: actions.resubmit
    }
  }
  if (status === 'DELETED') {
    variants = {
      chip: {
        icon: <DeleteForeverIcon />,
        label: 'Deleted',
        color: darkGrey
      },
      action: actions.rerun
    }
  }

  return (
    <Grid item xs={12}>
      <Paper className={classes.paperStyles} elevation={5}>
        <Grid container direciton="row" justify="flex-start" alignItems="center" spacing={2}>
          <Grid item xs>
            <div className={classes.mainListingImageContainer}>
              <div style={{ backgroundImage: `url(${ad.picture})` }} className={classes.mainListingImage} />
            </div>
          </Grid>
          <Grid item xs={3}>
            {/* //todo: uncomment and replace with new 4 request data properties in ads api response */}
            {/* <Typography>{trimText(`${ad.address.street} ${ad.address.city}, ${ad.address.state} ${ad.address.zip}`, 58)}</Typography> */}
            <Typography>{trimText(`${ad.message}`, 58)}</Typography>
          </Grid>
          <Grid item xs className={classes.alignCenter}>
            <Typography>${ad.total_cost.toFixed(2)}</Typography>
          </Grid>
          <Grid item xs className={classes.alignCenter}>
            {status === 'WITH_ISSUES' ? (
              <Tooltip title={variants.chip.tooltip} placement="top" arrow>
                <Chip
                  variant="outlined"
                  icon={variants.chip.icon}
                  label={variants.chip.label}
                  color="primary"
                  style={{
                    color: variants.chip.color,
                    borderColor: variants.chip.color
                  }}
                />
              </Tooltip>
            ) : (
              <Chip
                variant="outlined"
                icon={variants.chip.icon}
                label={variants.chip.label}
                color="primary"
                style={{
                  color: variants.chip.color,
                  borderColor: variants.chip.color
                }}
              />
            )}
          </Grid>
          <Grid item xs className={classes.alignCenter}>
            <Tooltip
              title={
                <div>
                  {`${month}-${day}-${year}`}
                  <br />
                  {`${time}`}
                </div>
              }
              placement="top"
              arrow
            >
              <Typography>{humanizedDate}</Typography>
            </Tooltip>
          </Grid>
          <Grid item xs>
            <Grid contaier direction="column" justify="center" alignItems="center">
              <Grid item xs={12}>
                <div className={classes.actionContainer}>
                  {status === 'DELETED' ? (
                    variants.action
                  ) : (
                    <Fragment>
                      <Tooltip title="Delete ad" placement="top" arrow>
                        <IconButton aria-label="delete ad" onClick={() => deleteAction(1)}>
                          <DeleteIcon size="large" />
                        </IconButton>
                      </Tooltip>
                      {variants.action}
                    </Fragment>
                  )}
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.actionContainer}>
                  <Button variant="contained" color="primary">
                    View Listing
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}
const AdList = ({ ads, adDelete, adPause, adResume, adResubmit, adRerun }) => {
  return (
    <Fragment>
      {ads.map((ad, i) => {
        if (ad.status === 'ACTIVE') {
          return <AdCard key={i} ad={ad} status={ad.status} deleteAction={adDelete} typeAction={adPause} />
        } else if (ad.status === 'PAUSED') {
          return <AdCard key={i} ad={ad} status={ad.status} deleteAction={adDelete} typeAction={adResume} />
        } else if (ad.status === 'PENDING_REVIEW') {
          return <AdCard key={i} ad={ad} status={ad.status} deleteAction={adDelete} typeAction={adPause} />
        } else if (ad.status === 'REJECTED') {
          return <AdCard key={i} ad={ad} status={ad.status} deleteAction={adDelete} typeAction={adResubmit} />
        } else if (ad.status === 'WITH_ISSUES') {
          return <AdCard key={i} ad={ad} status={ad.status} deleteAction={adDelete} typeAction={adResubmit} />
        } else if (ad.status === 'DELETED') {
          return <AdCard key={i} ad={ad} status={ad.status} deleteAction={adDelete} typeAction={adRerun} />
        }
      })}
    </Fragment>
  )
}
const Ads = ({ myAds }) => {
  const classes = useStyles()

  // action handlers
  const adDelete = (id) => {
    console.log('deleting ad: ' + id)
  }
  const adPause = (id) => {
    console.log('now pausing ad: ' + id)
  }
  const adResume = (id) => {
    console.log('now resuming ad: ' + id)
  }
  const adResubmit = (id) => {
    console.log('now resubmitting ad: ' + id)
  }
  const adRerun = (id) => {
    console.log('now rerunning ad: ' + id)
  }

  if (myAds === null) {
    return 'Loading...'
  }

  return (
    <Grid container direction="row" justify="space-between" alignItems="center" className={classes.mainContainer}>
      <Grid item xs={12}>
        <div className={classes.paperStyles}>
          <Grid
            container
            direciton="row"
            justify="flex-start"
            alignItems="center"
            spacing={2}
            className={classes.tableHeaderContainer}
          >
            <Grid item xs />
            <Grid item xs={3}>
              <Typography variant="subtitle2" className={classes.alignCenter}>
                Address
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="subtitle2" className={classes.alignCenter}>
                Total
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="subtitle2" className={classes.alignCenter}>
                Status
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="subtitle2" className={classes.alignCenter}>
                Created Date
              </Typography>
            </Grid>
            <Grid item xs />
          </Grid>
        </div>
      </Grid>
      <AdList
        ads={myAds.ads}
        adDelete={adDelete}
        adPause={adPause}
        adResume={adResume}
        adResubmit={adResubmit}
        adRerun={adRerun}
      />
    </Grid>
  )
}
export default Ads
