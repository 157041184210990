import React from 'react'

import { Redirect } from 'react-router-dom'

const FacebookPage = () => {
  if (localStorage.userInfo && localStorage.listings) return <Redirect to="/dashboard" />

  if (localStorage.isFBLoggedIn && !localStorage.listings) return <Redirect to="/check-mls" />

  return <Redirect to="/facebook-login" />
}

export default FacebookPage
