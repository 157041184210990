import React from 'react'
import { useSwipeable } from 'react-swipeable'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { withStyles, useTheme } from '@material-ui/core/styles'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import IconButton from '@material-ui/core/IconButton'
import { Wrapper, CarouselContainer, CarouselSlot, SlideButton, PREV, NEXT } from './components'
const CustomIconButton = withStyles({
  root: {
    background: 'rgba(255,255,255,0.75)',
    '&:hover': {
      backgroundColor: '#fff'
    }
  }
})(IconButton)

const getOrder = ({ index, pos, numItems }) => {
  const order = index - pos < 0 ? numItems - Math.abs(index - pos) : index - pos
  return order + 1 >= numItems ? 0 : order + 1
}

const initialState = { pos: 0, sliding: false, dir: NEXT }

const Carousel = (props) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const numItems = React.Children.count(props.children)
  props.statePos(state.pos)
  const slide = (dir) => {
    dispatch({ type: dir, numItems })
    setTimeout(() => {
      dispatch({ type: 'stopSliding' })
    }, 50)
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => slide(NEXT),
    onSwipedRight: () => slide(PREV),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  })

  return (
    <div {...handlers}>
      <Wrapper>
        <CarouselContainer dir={state.dir} sliding={state.sliding}>
          {props.children.map((child, index) => (
            <CarouselSlot key={index} order={getOrder({ index, pos: state.pos, numItems })}>
              {child}
            </CarouselSlot>
          ))}
        </CarouselContainer>
      </Wrapper>
      {/* <SlideButton onClick={() => slide(PREV)} float="left">
        Prev
      </SlideButton>
      <SlideButton onClick={() => slide(NEXT)} float="right">
        Next
      </SlideButton> */}
      <ButtonGroup className={props.imgArrowContainerMidLeft}>
        <CustomIconButton aria-label="previous ad image" onClick={() => slide(PREV)}>
          <ChevronLeftIcon />
        </CustomIconButton>
      </ButtonGroup>

      <ButtonGroup className={props.imgArrowContainerMidRight}>
        <CustomIconButton aria-label="next ad image" onClick={() => slide(NEXT)}>
          <ChevronRightIcon />
        </CustomIconButton>
      </ButtonGroup>
    </div>
  )
}

function reducer(state, { type, numItems }) {
  switch (type) {
    case 'reset':
      return initialState
    case PREV:
      return {
        ...state,
        dir: PREV,
        sliding: true,
        pos: state.pos === 0 ? numItems - 1 : state.pos - 1
      }
    case NEXT:
      return {
        ...state,
        dir: NEXT,
        sliding: true,
        pos: state.pos === numItems - 1 ? 0 : state.pos + 1
      }
    case 'stopSliding':
      return { ...state, sliding: false }
    default:
      return state
  }
}

export default Carousel
