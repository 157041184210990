import React, { useState, useEffect, useContext, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'

import CloseIcon from '@material-ui/icons/Close'
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone'

import { CheckoutDetails } from './Checkout'
import { AdContext } from '../components/Base/AppContainer'
import { MarketerAdContext } from '../components/Base/MarketerContainer'

const useStyles = createUseStyles({
  headerContainer: {
    width: '90%!important'
  },
  loaderContainer: {
    position: 'absolute',
    top: 'calc(50% - 38px)',
    left: 'calc(50% - 38px)'
  },
  dialogListingInfoContainer: {
    position: 'relative',
    marginTop: 48
  },
  dialogCheckIcon: {
    fontSize: 110,
    position: 'absolute',
    left: 'calc(250px - 55px)',
    top: '-55px',
    zIndex: 4,
    // https://github.com/cssinjs/jss/issues/1175#issuecomment-523029924
    '& > :first-child > path:nth-child(1)': {
      // backgroundColor: props => console.log(props !== undefined && props.mainColor),
      color: (props) => (props !== undefined ? props.mainColor : '#fff'),
      opacity: '1'
    },
    '& > :first-child > path:nth-child(2)': {
      color: '#fff!important',
      opacity: '1',
      '-webkit-box-shadow': '0px 3px 15px 0px #000000',
      boxShadow: '0px 3px 15px 0px #000000'
    }
  },
  dialogCheckIconShadowContainer: {
    position: 'absolute',
    left: 'calc(250px - 46px)',
    top: '-46px',
    zIndex: 3
  },
  dialogCheckIconShadow: {
    '-webkit-box-shadow': 'rgba(102, 102, 102, 0.46) 0px 2px 8px 1px;',
    '-moz-box-shadow': 'rgba(102, 102, 102, 0.46) 0px 2px 8px 1px;',
    boxShadow: 'rgba(102, 102, 102, 0.46) 0px 2px 8px 1px;',
    height: 92,
    width: 92,
    borderRadius: 70,
    color: 'transparent'
  },
  roundButton: {
    marginTop: '24px!important',
    borderRadius: '25px!important',
    fontWeight: '700!important'
  },
  dialogCheckoutPaper: {
    width: '472px!important',
  },
  adCreatedActionButtonContainer: {
    marginTop: '64px!important',
    marginBottom: '34px!important'
  },
  textAlignEnd: {
    textAlign: 'end!important'
  }
})

const AdCreatedPage = ({ listing, standaloneContext, standalone, dataFacebook }) => {
  const theme = useTheme()

  let mainColor = theme && theme.palette.primary.main
  const classes = useStyles({ mainColor })

  const [listingObj, setListingObj] = useState(null)

  const adDetails = useContext(standaloneContext === 'marketer' ? MarketerAdContext : AdContext)

  useEffect(() => {
    setListingObj(listing)
  }, [])

  const handlePostAdCreate = useCallback(() => {
    if (adDetails.appBMContext === 'ads') {
      window.location.href = 'https://ads.brivitymarketer.com/v2/ads'
    } else if (adDetails.appBMContext === 'beta') {
      window.location.href = 'https://beta.brivitymarketer.com/v2/ads'
    } else {
      window.location.href = 'https://brivitymarketer.com/v2/ads'
    }
  }, [])

  if (listingObj === null && dataFacebook === null) {
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress color="primary" size={75} />
      </div>
    )
  } else {
    // {/* <CheckoutDetails listingId={1} listing={listingObj} adDetails={adDetails} isConfirmation /> */}
    return (
      <Grid container direction="column" alignItems="center" >
        {/* {console.log({dataFacebook})} */}
        <Grid item xs={12} className={classes.headerContainer}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item xs={10}>
              <Typography variant="h4" style={{ color: theme.palette.primary.main }}>
                Your Ad Was Successfully Created!
              </Typography>
            </Grid>

            <Grid item xs={2} className={classes.textAlignEnd}>
              <IconButton aria-label="close successfully created ad modal" onClick={handlePostAdCreate}>
                <CloseIcon fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} style={{paddingBottom: 'unset'}}>
          <div className={classes.dialogListingInfoContainer}>
            <div>
              <div className={classes.dialogCheckIcon}>
                <CheckCircleTwoToneIcon fontSize="inherit" />
              </div>
              <div className={classes.dialogCheckIconShadowContainer}>
                <div className={classes.dialogCheckIconShadow} />
              </div>
              <Paper className={classes.dialogCheckoutPaper} elevation={5}>
                <CheckoutDetails
                  dataFacebook={dataFacebook}
                  listing={listingObj}
                  adDetails={adDetails}
                  isConfirmation
                />
              </Paper>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                className={classes.roundButton}
                onClick={handlePostAdCreate}
                fullWidth
                disableElevation
              >
                View Ads Dashboard!
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}
export default AdCreatedPage
