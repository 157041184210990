const dummyObj = {
  listing: {
    blossorId: '34-1722059',
    mlsId: 2,
    mlsNum: '1722059',
    _id: '6012ce31445f6ef0e8cfe5b1',
    mlsStatus: 'Sold',
    standardPropertyType: 'Residential',
    price: 655000,
    status: 4,
    bedrooms: 4,
    totalBaths: 4,
    sqFeet: 3526,
    acreage: 0.34,
    year: 2014,
    remarks:
      "Welcome home to Parkside Estates! This stunning  two story home is in a neighborhood that can not be beat.  Backing BLM land  means you will never have backyard neighbors and an elementary school a few houses away means you will never have to drive kids to school!  This meticulously cared for home has all the latest design trends including a two story family room,  gourmet kitchen with upgraded quartz countertops,  4' double deep island, huge walk in pantry, and all the natural light you could ask for. Upstairs we have 4 large bedrooms, including a master suite that steals the show, featuring an expansive bathroom with an even more expansive walk in closet! The newly finished basement enjoys built in cabinets, a cozy family room, a private office with french doors, a tucked away bedroom and full size bathroom. All information deemed reliable, but we ask the buyer and buyers agent to verify all information.",
    address: {
      street: '163 W Parkside Dr',
      city: 'Saratoga Springs',
      state: 'UT',
      zip: '84045',
      county: 'Utah',
      neighborhood: 'Parkside Estates',
      area: 'Am Fork; Hlnd; Lehi; Saratog.'
    },
    location: {
      Lat: 40.333156,
      Lng: -111.918391
    },
    photos: [
      {
        url: 'https://photos.brivity.com/images/34/photo/1/7/2/2/0/5/9/0.jpg?v=0',
        order: 1
      },
      {
        url: 'https://photos.brivity.com/images/34/photo/1/7/2/2/0/5/9/1.jpg?v=0',
        order: 2
      }
    ],
    communityFeatures: null,
    propertyFeatures: [
      'Curb & Gutter',
      'Road: Paved',
      'Secluded',
      'Sidewalks',
      'Sprinkler: Auto-Full',
      'Terrain: Grad Slope',
      'View: Lake',
      'View: Mountain',
      'Sewer: Connected',
      'Culinary',
      'Stone',
      'Stucco',
      'Cement Siding'
    ],
    exteriorFeatures: ['Double Pane Windows', 'Blt./Standing'],
    interiorFeatures: [
      'Bath: Master',
      'Bath: Sep. Tub/Shower',
      'Closet: Walk-In',
      'Den/Office',
      'Disposal',
      'Great Room',
      'Range/Oven: Free Stdng.',
      'Floor: 2nd',
      'Basement: Full',
      'Carpet',
      'Laminate',
      'Tile',
      'Electric Dryer Hookup'
    ],
    lotFeatures: [
      'Curb & Gutter',
      'Road: Paved',
      'Secluded',
      'Sidewalks',
      'Sprinkler: Auto-Full',
      'Terrain: Grad Slope',
      'View: Lake',
      'View: Mountain',
      'Secluded Yard'
    ],
    parkingFeatures: null,
    financialInformation: ['Cash', 'Conventional', 'FHA', 'VA Loan'],
    Agents: [
      {
        ID: '87923',
        Name: 'Lyndsey Ercanbrack',
        Type: ''
      },
      {
        ID: '0',
        Name: '',
        Type: ''
      }
    ],
    Office: {
      ID: '69162',
      Name: 'KW WESTFIELD'
    },
    DaysOnMarket: 5,
    ListDate: '2021-01-28T14:20:02Z',
    SoldDate: '2021-02-25T00:00:00Z',
    UpdateDate: '2021-04-02T14:37:42Z',
    History: [
      {
        Status: 4,
        StatusSet: '2021-01-28T14:20:09Z'
      },
      {
        Status: 4,
        StatusSet: '0001-01-01T00:00:00Z'
      },
      {
        Status: 4,
        StatusSet: '2021-02-02T02:59:52Z'
      }
    ]
  }
}
export default dummyObj
