import React, { useState, useEffect, useContext, useCallback, Fragment } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import { Redirect } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'

import MuiAlert from '@material-ui/lab/Alert'

import { AdContext } from '../components/Base/AppContainer'
import { MarketerAdContext } from '../components/Base/MarketerContainer'

import CloseIcon from '@material-ui/icons/Close'

import { blue, marketerGrey, darkestGrey } from '../assets/colorPalette'

import { trimText } from '../util/Helpers'
import api from '../services/api'
import VideoAdPreviewCard from './VideoAdPreviewCard'
//  * styles
const useStyles = createUseStyles({
  checkoutImage: {
    width: '100%', 
    height: '100%', 
    objectFit: 'contain',
    maxHeight: '375px'
  },
  mainContainer: {
    padding: 16
  },
  mainContainerSmallSA: {
    padding: '0px 8px'
  },
  smallMainContainer: {
    padding: 8,
    paddingTop: '35px!important'
  },
  cardContainer: {
    padding: 24
  },
  cardInfoContainer: {
    padding: '8px 16px'
  },
  fullContainer: {
    width: '100%',
    height: '100%'
  },
  mainImageContainer: {
    width: '100%'
  },
  alignStart: {
    textAlign: 'start!important'
  },
  alignEnd: {
    textAlign: 'end!important'
  },
  standardPadding: {
    padding: '16px!important'
  },
  cardDetailsPadding: {
    padding: '0px 16px 6px 16px!important'
  },
  topPadding: {
    paddingTop: '16px!important'
  },
  noTopPadding: {
    paddingTop: '0px!important'
  },
  topMargin: {
    marginTop: '16px!important'
  },
  cardTotalsContainer: {
    padding: '16px 0px'
  },
  cardConfirmationCTA: {
    padding: '6px 16px',
    border: `1px solid ${marketerGrey}`,
    borderRadius: 4,
    color: '#666',
    textAlign: 'center',
    fontFamily: 'font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
    textTransform: 'uppercase',
    fontSize: '0.8125rem',
    cursor: 'default',
    fontFamily: 'Open Sans'
  },
  bold: {
    fontWeight: '600!important'
  },
  heavyFontWeight: {
    fontWeight: '800!important'
  },
  darkestGrey: {
    color: `${darkestGrey}!important`
  },
  marketerGrey: {
    color: `${marketerGrey}!important`
  },
  mainImageStyles: {
    height: '100%',
    width: '100%',
    backgroundPosition: 'center',
    backgroundSize: '100% 100%',
    borderRadius: '4px 4px 0px 0px'
  },
  purchaseAdButtonWrapper: {
    margin: 8,
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  loaderContainer: {
    position: 'absolute',
    top: 'calc(50% - 38px)',
    left: 'calc(50% - 38px)'
  },
  // dialog stuff
  dialogPaper: {
    maxWidth: '75%!important',
    borderRadius: '11px!important'
    // transition: 'all .3s linear!important',
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '48px 64px 0px!important'
  },
  dialogContent: {
    padding: '30px 168px!important'
  },
  dialogListingInfoContainer: {
    position: 'relative',
    marginTop: 48
  },
  dialogCheckIcon: {
    fontSize: 110,
    position: 'absolute',
    left: 'calc(250px - 55px)',
    top: '-55px',
    zIndex: 4,
    // https://github.com/cssinjs/jss/issues/1175#issuecomment-523029924
    '& > :first-child > path:nth-child(1)': {
      // backgroundColor: props => console.log(props !== undefined && props.mainColor),
      color: (props) => (props !== undefined ? props.mainColor : '#fff'),
      opacity: '1'
    },
    '& > :first-child > path:nth-child(2)': {
      color: '#fff!important',
      opacity: '1',
      '-webkit-box-shadow': '0px 3px 15px 0px #000000',
      boxShadow: '0px 3px 15px 0px #000000'
    }
  },
  dialogCheckIconShadowContainer: {
    position: 'absolute',
    left: 'calc(250px - 46px)',
    top: '-46px',
    zIndex: 3
  },
  dialogCheckIconShadow: {
    '-webkit-box-shadow': 'rgba(102, 102, 102, 0.46) 0px 2px 8px 1px;',
    '-moz-box-shadow': 'rgba(102, 102, 102, 0.46) 0px 2px 8px 1px;',
    boxShadow: 'rgba(102, 102, 102, 0.46) 0px 2px 8px 1px;',
    height: 92,
    width: 92,
    borderRadius: 70,
    color: 'transparent'
  },
  customPaper: {
    '-webkit-box-shadow': '0px 2px 4px 0px #E0E0E0!important',
    boxShadow: '0px 2px 4px 0px #E0E0E0!important'
  },
  marketerButton: {
    fontWeight: '700!important',
    borderRadius: '25px!important'
  },
  dialogCheckoutPaper: {
    width: '472px!important'
  },
  adCreatedListingCardTitle: {
    padding: 16,
    textAlign: 'center'
  },
  adCreatedActionButtonContainer: {
    marginTop: '64px!important',
    marginBottom: '34px!important'
  },
  snackbarComponent: {
    position: 'relative!important',
    transform: 'translate(-50%, -41px)!important'
  }
  // TODO: ADD A 12PX MARGIN TOP TO xs=6 left side card grid item to equalize the height withimage on the right card
})
// * api actions
const postFacebookAd = (data, hook) => api.post(`/facebook/ad`, data, hook)
const getListing = (mls, mlsNum, setHook) => api.get(`/listings/mls/${mls}/mlsnum/${mlsNum}`, setHook)

// * custom components
const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

// * components
const PaymentDetails = ({ listingId, standalone, billingInfo }) => {
  const classes = useStyles()
  return (
    <Fragment>
      {billingInfo ? (
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="flex-start"
          spacing={3}
          className={`${classes.fullContainer} ${classes.cardDetailsPadding}`}
        >
          <Grid item xs={12}>
            <Typography variant="caption" className={classes.bold}>
              Credit / Debit Card
            </Typography>
            <Typography>{billingInfo.cardType}</Typography>
            <Typography>
              {billingInfo.cardNumber ? billingInfo.cardNumber.replace(/X/g, '•').replace(/-/g, ' ') : ''}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" className={classes.bold}>
              Billing Information
            </Typography>
            <Typography>{`${billingInfo.firstName} ${billingInfo.lastName}`}</Typography>
            <Typography>{billingInfo.address}</Typography>
            <Typography>{`${billingInfo.city}, ${billingInfo.state} ${billingInfo.zip}`}</Typography>
            {/* <Typography>United States</Typography> */}
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="flex-start"
          spacing={3}
          className={`${classes.fullContainer} ${classes.cardDetailsPadding}`}
        >
          <Grid item xs={12}>
            <Typography variant="caption" className={classes.bold}>
              Payment Method
            </Typography>
            <Typography>Default payment method</Typography>
          </Grid>
        </Grid>
      )}
    </Fragment>
  )
}
const CheckoutDetails = ({ listingId, listing, adDetails, isConfirmation, dataFacebook }) => {
  const classes = useStyles()
  const checkoutVideos = (dataFacebook?.checkoutVideo || []).map((video) => ({
    videoURL: video.videoUrl,
    videoRatio: video.aspectRatio,
    imageUrl: video.imageUrl,
  }))
  const videos = checkoutVideos.filter(video => video.videoRatio === '1:1') || checkoutVideos
  return (
    <Fragment>
      <Grid container item direction="column" justify="center" alignItems="center" className={classes.fullContainer}>
        {dataFacebook?.mediaType === 'video' ?(
            <VideoAdPreviewCard videos={videos} displayRatioSelector={false}/>
          ) :  dataFacebook?.mediaType === 'image' ?(
            <img alt="social-media-ad-result" src={`${dataFacebook?.image }`}
              className={classes.checkoutImage}
            />
          ) : dataFacebook?.adType === 'homeValue' ? (
            <img alt="homeValue-ad-result" src={`${dataFacebook?.image }`}
              className={classes.checkoutImage}
            />
        ) : dataFacebook?.adType === 'buyerSearch' || dataFacebook?.adType === 'multiple' ? (
          <img alt="multiple-ad-result" src={`${dataFacebook?.first_buyer_photo }`}
            className={classes.checkoutImage}
          />
        ) : dataFacebook?.adType === 'readyMade' ? (
          <img alt="readyMade-ad-result" src={`${dataFacebook?.first_buyer_photo }`}
            className={classes.checkoutImage}
          />
        ) : (
          <img alt="ad-result" src={`${dataFacebook?.images.length !== 0 ? dataFacebook?.images[0] : dataFacebook?.image }`}
            className={classes.checkoutImage}
          />
        )}
      </Grid>
      <Grid
        container
        item
        direction="column"
        justify="center"
        alignItems="center"
        className={`${classes.fullContainer} ${classes.topPadding} ${
          isConfirmation && classes.adCreatedListingCardTitle
        }`}
      >
        {dataFacebook?.adType === 'homeValue' || dataFacebook?.adType === 'buyerSearch' ? (
          <Typography variant="h6" className={`${classes.bold} ${isConfirmation && classes.marketerGrey}`}>
            {/* {console.log(dataFacebook)} */}
            {dataFacebook?.mlsAddress}
          </Typography>
        ) : dataFacebook?.adType === 'multiple' ? (
          <Typography variant="h6" className={`${classes.bold} ${isConfirmation && classes.marketerGrey}`}>
            {dataFacebook?.headerTitleMultiple.fieldValues?.fullAddress}
          </Typography>
        ) : dataFacebook?.adType === 'readyMade' ? (
          <Typography variant="h6" className={`${classes.bold} ${isConfirmation && classes.marketerGrey}`}>
            {dataFacebook?.headerTitleMultiple.map((item) => item.name + ' ')}
          </Typography>
        ) : (
          <Typography variant="h6" className={`${classes.bold} ${isConfirmation && classes.marketerGrey}`}>
            {listing.address.street} {listing.address.city}, {listing.address.state} {listing.address.zip}
          </Typography>
        )}

        {!isConfirmation && (
          <Typography variant="subtitle1">Facebook Ad, {adDetails.ad_duration} day duration</Typography>
        )}
        {isConfirmation && (
          <Grid container direction="row" justify="space-between" alignItems="center" className={classes.topMargin}>
            <Grid item xs={8} className={classes.alignStart}>
              <Typography variant="h6" className={classes.marketerGrey}>
                {/* {trimText("See All The Photos NOW!", 65)} */}
                {dataFacebook?.name}
              </Typography>
              {dataFacebook?.adType !== 'homeValue' &&
                dataFacebook?.adType !== 'buyerSearch' &&
                dataFacebook?.adType !== 'multiple' &&
                dataFacebook?.adType !== 'readyMade' && (
                  <Typography variant="subtitle2" className={classes.darkestGrey}>
                    {trimText(
                      `${listing.bedrooms} beds | ${
                        listing.totalBaths
                      } baths | ${listing.sqFeet.toLocaleString()} sqft`,
                      43
                    )}
                  </Typography>
                )}
            </Grid>
            <Grid item xs={4} className={classes.alignEnd}>
              {/* <Tooltip arrow placement="top" title="View the listing page your leads will see" aria-label="View the listing page your leads will see"> */}
              {isConfirmation ? (
                <div className={classes.cardConfirmationCTA}>Learn More</div>
              ) : (
                <Button variant="outlined">Learn More</Button>
              )}
              {/* </Tooltip> */}
            </Grid>
          </Grid>
        )}
      </Grid>
      {!isConfirmation && (
        <div className={classes.cardTotalsContainer}>
          <Grid
            container
            direction="row"
            justify="space-between"
            align="flex-end"
            className={`${classes.cardInfoContainer}`}
          >
            <Grid item xs={6}>
              <Typography variant="body1" className={classes.alignStart}>
                Ad Budget
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" className={classes.alignEnd}>
                $
                {(adDetails.ad_duration * adDetails.ad_daily_budget).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            align="flex-end"
            className={classes.cardInfoContainer}
          >
            <Grid item xs={6}>
              <Typography variant="body1" className={classes.alignStart}>
                Service Fee
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" className={classes.alignEnd}>
                $
                {(adDetails.ad_duration * adDetails.ad_daily_budget * 0.2).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justify="space-between"
            align="flex-end"
            className={`${classes.cardInfoContainer}`}
          >
            <Grid item xs={6}>
              <Typography variant="body1" className={`${classes.alignStart} ${classes.heavyFontWeight}`}>
                Total Due
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" className={`${classes.alignEnd} ${classes.heavyFontWeight}`}>
                $
                {(
                  adDetails.ad_duration * adDetails.ad_daily_budget * 0.2 +
                  adDetails.ad_duration * adDetails.ad_daily_budget
                ).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </Typography>
            </Grid>
          </Grid>
        </div>
      )}
    </Fragment>
  )
}
const CheckoutM = ({
  listingId,
  standalone,
  standaloneContext,
  adCreateAction,
  listing,
  cancelAction,
  onAdCreated,
  billingInfo,
  sliderIndex
}) => {
  // external hooks
  const history = useHistory()
  const theme = useTheme()
  let mainColor = theme && theme.palette.primary.main
  const classes = useStyles({ mainColor })
  // hooks
  let [listingObj, setListingObj] = useState(null)
  let [facebookAd, setFacebookAd] = useState(null)
  let [creatingAd, setCreatingAd] = useState(false)
  let [errorSnackbar, setErrorSnackbar] = useState(false)
  let [snackbarMessage, setSnackbarMessage] = useState('')
  let [adCreated, setAdCreated] = useState(false)

  // context
  const adDetails = useContext(standaloneContext === 'marketer' ? MarketerAdContext : AdContext)

  useEffect(() => {
    if (standalone) {
      setListingObj(listing)
    } else {
      let mlsId = JSON.parse(localStorage.getItem('mlsSettings'))
      getListing(mlsId.settings.mls_id, listingId, handleListingObj)
    }
  }, [])

  useEffect(() => {
    if (facebookAd) {
      console.log(facebookAd)
      setCreatingAd(false)
      if (facebookAd.error) {
        if (facebookAd.error.error) {
          if (facebookAd.error.error.includes('#803')) {
            setSnackbarMessage('There was an error while creating your ad. Facebook error (#803).')
          }
        }
        if (facebookAd.error.code === 406) {
          setSnackbarMessage(facebookAd.error.message)
        } else {
          setSnackbarMessage("We couldn't create your ad. Please try again later")
        }
        setErrorSnackbar(true)
        setSnackbarMessage(facebookAd.error.message)
        console.log("Ad couldn't be created because: " + facebookAd.error.message)
      } else {
        console.log('%cAd created successfuly', 'color: #bada55')
        if (standalone) {
          setAdCreated(true)
          onAdCreated(facebookAd)
        } else {
          history.push({ pathname: '/ads' })
        }
      }
    }
  }, [facebookAd])

  // handlers
  const handleListingObj = (data) => {
    if (data) {
      setListingObj(data.listing)
    }
  }
  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setErrorSnackbar(false)
    setSnackbarMessage('')
  }

  // util
  const handlePurchaseAd = () => {
    let total =
      adDetails.ad_duration * adDetails.ad_daily_budget * 0.2 + adDetails.ad_duration * adDetails.ad_daily_budget
    let data = {
      days: adDetails.ad_duration,
      dailyBudget: adDetails.ad_daily_budget,
      total,
      name: adDetails.ad_name,
      message: adDetails.ad_description,
      image: listingObj.photos[adDetails.image_index].url,
      mls: listingObj.mlsId,
      mlsNum: listingObj.mlsNum,
      page_id: adDetails.page_id ? adDetails.page_id : '',
      status: 'PAUSED',
      website: adDetails.website,
      pageToken: adDetails.pageToken
    }
    if (adDetails.page_id) data.page_id = adDetails.page_id

    setCreatingAd(true)
    setErrorSnackbar(false)
    setSnackbarMessage('')
    if (standalone) {
      adCreateAction()
    }
    postFacebookAd(data, setFacebookAd)
  }
  const handlePostAdCreate = useCallback(() => {
    if (adDetails.appBMContext === 'ads') {
      window.location.href = 'https://ads.brivitymarketer.com/v2/ads'
    } else if (adDetails.appBMContext === 'beta') {
      window.location.href = 'https://beta.brivitymarketer.com/v2/ads'
    } else {
      window.location.href = 'https://brivitymarketer.com/v2/ads'
    }
  }, [])
  const handleCloseAdTool = useCallback(() => {
    if (adDetails.appBMContext === 'ads') {
      window.location.href = 'https://ads.brivitymarketer.com/v2/listings'
    } else if (adDetails.appBMContext === 'beta') {
      window.location.href = 'https://beta.brivitymarketer.com/v2/listings'
    } else {
      window.location.href = 'https://brivitymarketer.com/v2/listings'
    }
  }, [])

  if (!adDetails.from_listing) return <Redirect to="/listings" />

  if (listingObj === null) {
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress color="primary" size={75} />
      </div>
    )
  } else {
    if (sliderIndex !== 3) return null

    return (
      <Fragment>
        <Grid container direction="row" justify="space-between" alignItems="center" className={classes.mainContainer}>
          <Grid item xs={6} className={classes.alignStart}>
            <Typography variant="h4" style={{ color: theme.palette.primary.main }}>
              Purchase Ad
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.alignEnd}>
            {standalone ? (
              <IconButton onClick={handleCloseAdTool} aria-label="close ad creation modal">
                <CloseIcon />
              </IconButton>
            ) : (
              <IconButton component={Link} to={`/listings`} aria-label="close ad creation modal">
                <CloseIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3} className={standalone ? classes.mainContainerSmallSA : classes.mainContainer}>
          <Grid item xs={6}>
            <Paper className={`${classes.topPadding} ${classes.customPaper} ${classes.marketerGrey}`} elevation={0}>
              <PaymentDetails listingId={listingId} standalone={standalone} billingInfo={billingInfo} />
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={`${classes.customPaper} ${classes.marketerGrey}`} elevation={0}>
              <CheckoutDetails
                listingId={listingId}
                listing={listingObj}
                adDetails={adDetails}
                themePrimaryMainColor="red"
              />
            </Paper>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={`${classes.smallMainContainer}`}
        >
          <Grid item xs={2} className={classes.alignStart}>
            {standalone ? (
              <Button
                variant="contained"
                className={classes.marketerButton}
                style={{ color: darkestGrey }}
                onClick={() => cancelAction(adCreated)}
                fullWidth
                disableElevation
              >
                Back
              </Button>
            ) : (
              <Button component={Link} to={`/listings/${listingId}`} variant="contained" fullWidth disableElevation>
                Back
              </Button>
            )}
          </Grid>
          <Grid item xs={2} className={classes.alignEnd}>
            <div className={classes.purchaseAdButtonWrapper}>
              {standalone ? (
                <Fragment>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={creatingAd}
                    onClick={creatingAd ? undefined : adCreated ? handlePostAdCreate : handlePurchaseAd}
                    className={classes.marketerButton}
                    fullWidth
                    disableElevation
                  >
                    {adCreated ? 'View Ads' : 'Pay Now'}
                  </Button>
                  {creatingAd && <CircularProgress size={24} color="primary" className={classes.buttonProgress} />}
                </Fragment>
              ) : (
                <Fragment>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={creatingAd}
                    onClick={creatingAd ? undefined : handlePurchaseAd}
                    fullWidth
                    disableElevation
                  >
                    Pay Now
                  </Button>
                  {creatingAd && <CircularProgress size={24} color="primary" className={classes.buttonProgress} />}
                </Fragment>
              )}
            </div>
          </Grid>
        </Grid>
        <Snackbar
          open={errorSnackbar}
          autoHideDuration={null}
          onClose={handleErrorSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          className={classes.snackbarComponent}
        >
          {/* <Snackbar open={true} autoHideDuration={null} onClose={handleErrorSnackbarClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} className={classes.snackbarComponent}> */}
          <Alert onClose={handleErrorSnackbarClose} severity="error">
            {/* We couldn't create your ad. Please try again later */}
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Fragment>
    )
  }
}
export { CheckoutDetails }
export default CheckoutM
