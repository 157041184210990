import React from 'react'
import { faGrin } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  HelpMeWriteRecruiterPopover,
  HelpMeWriteRecruiterTriggerSolid,
  HelpMeWriteRecruiterPopoverContent,
  HelpMeWriteRecruiterPromptInput,
  HelpMeWriteRecruiterDropdown,
  AiComponentsProvider
} from '@brivity/ai-components'
import { Typography } from '@material-ui/core'

export const HelpMeWrite = ({ handleHelpMeWrite, isHelpingMeWrite, style = { width: '100%' } }) => {
  const isLoading = isHelpingMeWrite['title'] || isHelpingMeWrite['description']
  return (
    <AiComponentsProvider>
      <div className="ai-tw-flex ai-tw-justify-end" style={style}>
        {isLoading && <Typography className="ai-tw-mt-1 ai-tw-w-max"> Helping you write! </Typography>}
        {!isLoading && (
          <HelpMeWriteRecruiterPopover modal>
            <HelpMeWriteRecruiterTriggerSolid label={'Improve Listing Ad'} />
            <HelpMeWriteRecruiterPopoverContent align="end">
              <HelpMeWriteRecruiterPromptInput onConfirm={(value) => handleHelpMeWrite(value, null)} />
              <HelpMeWriteRecruiterDropdown
                className="ai-tw-mt-1 ai-tw-w-max"
                onSelect={(value) => handleHelpMeWrite(null, value)}
                buildItems={(options) => {
                  const filteredOptions = options.filter((item) => item.title !== 'Formalize')

                  const customOptions = [
                    ...filteredOptions,
                    {
                      title: 'Add more emojis',
                      icon: <FontAwesomeIcon icon={faGrin} />
                    }
                  ]

                  return customOptions
                }}
              />
            </HelpMeWriteRecruiterPopoverContent>
          </HelpMeWriteRecruiterPopover>
        )}
      </div>
    </AiComponentsProvider>
  )
}
