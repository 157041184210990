import React, { useCallback, useState } from 'react'
import { Box, Paper, Typography, IconButton } from '@material-ui/core'
import { CloudUpload } from '@material-ui/icons'
export const DragAndDrop = ({ onFileUpload }) => {
  const [dragOver, setDragOver] = useState(false)
  const [isHovering, setIsHovering] = useState(false)

  const handleMouseEnter = () => {
    setIsHovering(true)
  }

  const handleMouseLeave = () => {
    setIsHovering(false)
  }

  const handleDragOver = useCallback((event) => {
    event.preventDefault()
    setDragOver(true)
  }, [])

  const handleDragLeave = useCallback((event) => {
    event.preventDefault()
    setDragOver(false)
  }, [])

  const handleDrop = useCallback(
    (event) => {
      event.preventDefault()
      setDragOver(false)
      if (event.dataTransfer.files && event.dataTransfer.files[0]) {
        onFileUpload(event.dataTransfer.files[0])
      }
    },
    [onFileUpload]
  )

  const handleChange = useCallback(
    (event) => {
      if (event.target.files && event.target.files[0]) {
        onFileUpload(event.target.files[0])
      }
    },
    [onFileUpload]
  )

  return (
    <div id="imageUploadWrapper">
      <Paper
        variant="outlined"
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        style={{
          border: dragOver ? '2px dashed #000' : '2px dashed #aaa',
          textAlign: 'center',
          cursor: 'pointer',
          background: dragOver || isHovering ? '#eee' : '#fafafa',
          display: 'flex',
          justifyContent: 'center',
          flex: 1
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="raised-button-file"
          multiple
          type="file"
          onChange={handleChange}
        />
        <label htmlFor="raised-button-file" style={{ width: '100%' }}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            style={{
              cursor: 'pointer'
            }}
          >
            <Typography>Upload custom images for ads</Typography>
          </Box>
        </label>
      </Paper>
    </div>
  )
}
