import React, { useEffect, useState, Fragment } from 'react'
import queryString from 'query-string'
import { createUseStyles } from 'react-jss'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'

import { offWhite, black } from '../assets/colorPalette'
import api from '../services/api'

const useStyles = createUseStyles({
  mainListingImageContainer: {
    width: '100%',
    height: '100%'
  },
  mainListingImage: {
    height: '100%',
    width: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  },
  fullContainer: {
    width: '100%',
    height: '100%'
  },
  mainPadding: {
    padding: 24
  },
  imgWrapper: {
    position: 'relative',
    height: '100%'
  },
  overlayContainer: {
    zIndex: '10',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },
  overlay: {
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.55)'
  },
  informationContainer: {
    backgroundColor: black,
    color: `${offWhite}!important`,
    height: '100%!important',
    marginTop: '24px!important'
  },
  infoSpace: {
    paddingTop: '40px!important'
  },
  alignCenter: {
    textAlign: 'center'
  },
  alignRight: {
    textAlign: 'end'
  },
  bigRow: {
    padding: '24px!important',
    height: '100vh!important'
  },
  smallRow: {
    paddingRight: '24px!important'
  },
  // https://stackoverflow.com/a/54788958/8460812
  // https://stackoverflow.com/a/52477460/8460812
  swipeable: {
    height: '100%',
    width: '100%',
    '& > :first-child': {
      height: '100%',
      width: '100%'
    }
  }
})

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const getListing = (mls, mlsNum, setHook) => api.get(`/listings/mls/${mls}/mlsnum/${mlsNum}`, setHook)

const Image = ({ listing, index, handleChangeIndex }) => {
  const classes = useStyles()
  return (
    <div className={classes.imgWrapper}>
      <AutoPlaySwipeableViews index={index} onChangeIndex={handleChangeIndex} className={classes.swipeable}>
        {listing.photos.map((el, i) => {
          return (
            <div className={classes.imgWrapper}>
              <div className={classes.mainListingImageContainer}>
                <div style={{ backgroundImage: `url(${el.url})` }} className={classes.mainListingImage} />
              </div>
              <div className={classes.overlayContainer}>
                <div className={classes.overlay} />
              </div>
            </div>
          )
        })}
      </AutoPlaySwipeableViews>
    </div>
  )
}

const InformationCard = ({ listing, children }) => {
  const classes = useStyles()
  return (
    <Grid item xs={12} className={`${classes.fullContainer}  ${classes.informationContainer}`}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
        spacing={2}
        className={classes.mainPadding}
      >
        {children}
      </Grid>
    </Grid>
  )
}
const Information = ({ listing }) => {
  const classes = useStyles()
  return (
    <Grid container direction="column" justify="flex-start" alignItems="flex-start" className={classes.smallRow}>
      <InformationCard listing={listing}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">{listing.address.zip}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3">{listing.address.street}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">
            {listing.address.city}, {listing.address.state}
          </Typography>
        </Grid>
        <Grid item xs={12} className={`${classes.fullContainer} ${classes.infoSpace}`}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item xs={4}>
              {listing.bedrooms} beds
            </Grid>
            <Grid item xs={4} className={classes.alignCenter}>
              {listing.totalBaths} baths
            </Grid>
            <Grid item xs={4} className={classes.alignRight}>
              {listing.sqFeet} SqFt.
            </Grid>
          </Grid>
        </Grid>
      </InformationCard>
      <InformationCard listing={listing}>
        <Grid item xs={12}>
          <Typography>{listing.remarks}</Typography>
        </Grid>
      </InformationCard>
      <InformationCard listing={listing}>
        <Grid item xs={12}>
          <Typography variant="h4">${listing.price.toLocaleString()}</Typography>
        </Grid>
      </InformationCard>
    </Grid>
  )
}

const ListingLandingPage = ({ location }) => {
  const classes = useStyles()
  const [listing, setListing] = useState(null)
  const [sliderIndex, setSliderIndex] = useState(0)

  useEffect(() => {
    let queries = queryString.parse(location.search)
    getListing(Number(queries.mls), Number(queries.listing), setListing)
  }, [])

  const handleChangeIndex = (index) => {
    setSliderIndex(index)
  }

  if (!!!listing) return 'Loading...'

  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
        backgroundColor: '#f1f1f1'
      }}
    >
      <Grid container direction="row" justify="flex-start" alignItems="flex-start">
        <Grid item xs={8} className={classes.bigRow}>
          <Image listing={listing.listing} index={sliderIndex} handleChangeIndex={handleChangeIndex} />
        </Grid>
        <Grid item xs={4} className={`${classes.fullContainer}`}>
          <Information listing={listing.listing} />
        </Grid>
      </Grid>
    </div>
  )
}
export default ListingLandingPage
