export const blue = '#009EEB'
export const hoverBlue = '#007DBA'
export const green = '#4caf50'
// todo: update hover green, yellow & red to match new green, yellow & red
export const hoverGreen = '#0f9f4f'
export const yellow = '#ff9800'
export const hoverYellow = '#f8b700'
export const red = '#f44336'
export const hoverRed = '#e23a00'
export const matchFields = '#7D83FF'
export const matchFieldsBg = '#DEE0FF'
// export const hoverMatchFields = 'darken(match-fields-bg, 2)';
export const facebook = '#1877f2'
export const facebookHover = '#4267b2'
export const lightBlue = '#85D7FF'
export const transparent = 'hsl(0,0%,100%)'
// old facebook -
export const facebookBlue = '#3578e5'
export const facebookHoverBlue = '#4267b2'
export const facebookDarkBlue = '#385898'
// old facebook-
export const offWhite = '#F8FAFA'
export const hoverOffWhite = '#dae5e5'
export const lightestGrey = '#F1F1F1'
export const hoverLightestGrey = '#E6E6E6'
export const lightGrey = '#D8D8D8'
export const darkGrey = '#A3A3A3'
export const darkestGrey = '#818181'
export const black = '#333333'
export const hoverBlack = '#6C6C6C'

// Marketer
export const marketerGrey = '#666'
export const marketerBlue = '#59c4c4'
