import React from 'react'
import { Redirect } from 'react-router-dom'

const IndexPage = () => {
  if (localStorage.brUserInfo && localStorage.listings) return <Redirect to="/dashboard" />

  return <Redirect to="/login" />
}

export default IndexPage
