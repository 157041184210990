import React, { useState, useEffect, Fragment } from 'react'
import { useHistory, Redirect } from 'react-router'
import { createUseStyles } from 'react-jss'

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import NavBar from '../components/Base/NavBar'
import BottomNavBar from '../components/Base/BottomNavBar'

import { blue, hoverBlue } from '../assets/colorPalette'

import api from '../services/api'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: blue,
      contrastText: 'white'
    }
  }
})

const useStyles = createUseStyles({
  paper: {
    position: 'absolute',
    top: 'calc(50% - 308px)',
    left: 'calc(50% - 400px)',
    height: 616,
    width: 800
  },
  mainContainerGrid: {
    margin: '0!important'
  },
  mainGridLoginItem: {
    width: '500px!important',
    height: '616px!important',
    padding: '0px!important',
    margin: '0px!important'
  },
  mainGridImageItem: {
    width: '300px!important',
    height: '616px!important',
    padding: '0px!important',
    margin: '0px!important'
  },
  innerGridRoot: {
    padding: '32px!important'
  },
  gridItem: {
    height: 'auto!important',
    width: '100%!important',
    textAlign: 'center'
  },
  headerGridItem: {
    height: '104px!important',
    width: '100%!important',
    padding: '24px!important'
  },
  headerInnerGridItem: {
    height: '80px!important'
  },
  headerLogoutItem: {
    paddingTop: '16px!important',
    textAlign: 'right',
    fontFamily: 'Roboto'
  },
  greeting: {
    color: blue,
    fontFamily: 'Roboto'
  },
  descriptionText: {
    fontFamily: 'Roboto',
    // margin: '0px 0px 0px 48px',
    padding: '16px 24px 32px 24px',
    width: 375,
    fontSize: 14,
    lineHeight: '2em'
  },
  formSelect: {
    width: 280
  },
  textInput: {
    width: 280
  },
  submitButton: {
    width: 278,
    marginTop: '32px!important'
  },
  logoutButton: {
    textTransform: 'initial!important'
  },
  image: {
    height: '121%',
    width: '136%',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  },
  overlay: {
    height: '100%',
    width: '100%',
    background: 'rgba(0,0,0,0.5)',
    position: 'absolute',
    top: 0
  }
})

//* api actions
const getStates = (hook) => api.get('/states', hook)
const getMLSByState = (state, hook) => api.get(`/mls/state/${state}`, hook)
const saveMLSSettings = (data, hook) => api.post('/mls-settings', data, hook)
const getListingsByAgent = (mls, agentIds, hook) => api.get(`/listings?mls=${mls}&limit=20&agent_ids=${agentIds}`, hook)
const getListingsByOffice = (mls, officeIds, hook) =>
  api.get(`/listings?mls=${mls}&limit=20&office_ids=${officeIds}`, hook)

const VerifyMLS = ({ location }) => {
  const classes = useStyles()
  const history = useHistory()
  // local states
  const [state, setState] = useState('')
  const [MLSId, setMLSId] = useState(null)
  // these two extra info fields are totally redundant, since the API should look up the ids that are sent for the MLS and State and attach this exact same info for the response on get, but oh well.
  const [extraStateInfo, setExtraStateInfo] = useState(null)
  const [extraMLSInfo, setExtraMLSInfo] = useState(null)
  const [agentID, setAgentID] = useState(null)
  const [listingFetchCounter, setListingFetchCounter] = useState(0)
  const [SDKLoaded, setSDKLoaded] = useState(false)

  // api values
  const [APIStates, setAPIStates] = useState(null)
  const [MLSByState, setMLSByState] = useState(null)
  const [listingsByMLS, setListingsByMLS] = useState(null)
  const [MLSSettings, setMLSSettings] = useState(null)

  // didUpdate
  useEffect(() => {
    console.log(APIStates)
  }, [APIStates])
  useEffect(() => {
    if (state !== '') {
      setMLSId(null)
      setMLSByState(null)
      getMLSByState(state, setMLSByState)
    }
  }, [state])
  useEffect(() => {
    console.log({ MLSByState })
  }, [MLSByState])
  useEffect(() => {
    if (listingsByMLS) {
      if (listingsByMLS.listings.length > 0) {
        localStorage.removeItem('listings', listingsByMLS)
        localStorage.setItem('listings', JSON.stringify(listingsByMLS))
        const brUser = JSON.parse(localStorage.getItem('brUserInfo'))
        const data = {
          user_id: brUser.user_id,
          state_id: state,
          state_abr: extraStateInfo.stateAbr,
          mls_id: MLSId,
          mls_prefix: extraMLSInfo.MLSPrefix,
          mls_name: extraMLSInfo.MLSName,
          filter: agentID
        }
        //TODO:  add mls_prefix, state_id & state_abr
        saveMLSSettings(data, setMLSSettings)
      } else {
        if (listingFetchCounter < 2) {
          let sanitizedIds = agentID.replace(/\s/g, '')
          getListingsByOffice(MLSId, sanitizedIds, setListingsByMLS)
          setListingFetchCounter(listingFetchCounter + 1)
        } else {
          console.log('No listings were found for this mls id')
          localStorage.removeItem('listings', listingsByMLS)
          localStorage.setItem('listings', JSON.stringify(listingsByMLS))
          const brUser = JSON.parse(localStorage.getItem('brUserInfo'))
          const data = {
            user_id: brUser.user_id,
            state_id: state,
            state_abr: extraStateInfo.stateAbr,
            mls_id: MLSId,
            mls_prefix: extraMLSInfo.MLSPrefix,
            mls_name: extraMLSInfo.MLSName,
            filter: agentID
          }
          saveMLSSettings(data, setMLSSettings)
        }
      }
    } else {
      // console.log('error, maybe?')
    }
  }, [listingsByMLS])
  useEffect(() => {
    if (MLSSettings) {
      localStorage.removeItem('mlsSettings')
      localStorage.setItem('mlsSettings', JSON.stringify(MLSSettings))
      console.log('==============THIS IS THE END====================')
      if (listingsByMLS) {
        if (listingsByMLS.listings.length > 0) {
          history.push({ pathname: '/dashboard' })
        } else {
          history.push({ pathname: '/no-listings' })
        }
      }
    }
  }, [MLSSettings])

  // didMount
  useEffect(() => {
    console.log('this happenned')
    getStates(setAPIStates)
  }, [])

  const handleStateChange = (id, states) => {
    setState(id)
    let state = states.find((obj) => obj.id === id)
    console.log({ state })
    setExtraStateInfo({ stateAbr: state.abr, stateName: state.name })
  }

  const handleMLSSettingsChange = (id, MLSByState) => {
    setMLSId(id)
    let selectedMLS = MLSByState.find((obj) => obj.id === id)
    console.log({ selectedMLS })
    setExtraMLSInfo({
      MLSPrefix: selectedMLS.prefix,
      MLSName: selectedMLS.name
    })
  }

  const handleClick = () => {
    if (MLSId && agentID) {
      let sanitizedIds = agentID.replace(/\s/g, '')
      setListingsByMLS(null)
      return getListingsByAgent(MLSId, sanitizedIds, setListingsByMLS)
    } else {
      console.log('youre missing inputs')
      return null
    }
  }
  const handleLogout = () => {
    window.FB.logout((res) => {
      console.log(res)
      console.log('Successfully Logged out')
      localStorage.clear()
      history.push({ pathname: '/facebook-login' })
    })
  }

  const fbinfo = JSON.parse(localStorage.getItem('fb_info'))
  return (
    <Fragment>
      <NavBar logout={handleLogout} location={location} />
      <ThemeProvider theme={theme}>
        <Paper className={classes.paper} elevation={5}>
          <GridList cols={2} className={classes.mainContainerGrid}>
            <GridListTile className={classes.mainGridLoginItem}>
              <GridList cols={1} className={classes.innerGridRoot}>
                <GridListTile className={classes.headerGridItem}>
                  <GridList cols={2}>
                    <GridListTile className={classes.headerInnerGridItem}>
                      <h2 className={classes.greeting}>Hello, {fbinfo.first_name}</h2>
                    </GridListTile>
                    <GridListTile className={`${classes.headerInnerGridItem} ${classes.headerLogoutItem}`}>
                      <Button className={classes.logoutButton} endIcon={<ArrowForwardIcon />} onClick={handleLogout}>
                        Logout
                      </Button>
                    </GridListTile>
                  </GridList>
                </GridListTile>
                <GridListTile className={classes.gridItem}>
                  <p className={classes.descriptionText}>
                    Please choose your <b>state</b>, then select your <b>MLS</b> and enter your MLS <b>Agent ID</b> or{' '}
                    <b>Office ID</b>.
                  </p>
                </GridListTile>
                <GridListTile className={classes.gridItem}>
                  <FormControl>
                    <InputLabel id="select-state">State</InputLabel>
                    <Select
                      labelId="select-state"
                      value={state}
                      onChange={(e) => handleStateChange(e.target.value, APIStates.states)}
                      className={classes.formSelect}
                    >
                      {APIStates
                        ? APIStates.states.map((state, i) => {
                            return (
                              <MenuItem key={i} value={state.id}>
                                ({state.abr}) - {state.name}
                              </MenuItem>
                            )
                          })
                        : null}
                    </Select>
                  </FormControl>
                </GridListTile>
                <GridListTile className={classes.gridItem}>
                  <FormControl disabled={state !== '' ? false : true}>
                    <InputLabel id="select-mls">MLS</InputLabel>
                    <Select
                      labelId="select-mls"
                      value={MLSId}
                      onChange={(e) => handleMLSSettingsChange(e.target.value, MLSByState.mls)}
                      className={classes.formSelect}
                      classes={{ underline: classes.newUnderline }}
                    >
                      {MLSByState
                        ? MLSByState.mls.map((mls, i) => {
                            return (
                              <MenuItem key={i} value={mls.id}>
                                (<b>{mls.prefix}</b>) - {mls.description}
                              </MenuItem>
                            )
                          })
                        : null}
                    </Select>
                  </FormControl>
                </GridListTile>
                <GridListTile className={classes.gridItem}>
                  <TextField
                    value={agentID}
                    onChange={(e) => setAgentID(e.target.value)}
                    multiline
                    rows={3}
                    className={classes.textInput}
                    label="Agent ID or Office ID"
                    helperText="Multiple comma separated Agent or Office IDs can be used"
                  />
                  {/* <button style={{marginTop: 16}} onClick={() => checkMlSInfo()}>Find My Listings</button> */}
                </GridListTile>
                <GridListTile className={classes.gridItem}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.submitButton}
                    endIcon={<ArrowForwardIcon />}
                    onClick={(e) => handleClick(e)}
                  >
                    Find My Listings
                  </Button>
                </GridListTile>
              </GridList>
            </GridListTile>
            <GridListTile className={classes.mainGridImageItem}>
              <div style={{ height: 616, width: 300 }}>
                <div
                  style={{
                    backgroundImage: 'url(' + require('../assets/images/login-img.jpg') + ')'
                  }}
                  className={classes.image}
                />
              </div>
              <div className={classes.overlay} />
            </GridListTile>
          </GridList>
        </Paper>
        {/* <Paper elevation={5} className={classes.imagePaper}>
          Hey
        </Paper> */}
      </ThemeProvider>
      <BottomNavBar />
    </Fragment>
  )
}

export default VerifyMLS
