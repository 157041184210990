export const trimText = (string, length, noDots) => {
  if (string.length <= length) return string
  if (noDots) return string.substring(0, length)
  if (string.length + 3 < length) return string
  return string.substring(0, length) + '...'
}

// format dates(/time) to a humanized format
// Date Object type as "previous" param
export const humanTime = (previous) => {
  const current = new Date()
  let msPerMinute = 60 * 1000
  let msPerHour = msPerMinute * 60
  let msPerDay = msPerHour * 24
  let msPerMonth = msPerDay * 30
  let msPerYear = msPerDay * 365

  let elapsed = current - previous

  if (elapsed < msPerMinute) {
    return `${Math.round(elapsed / 1000)} seconds ago`
  } else if (elapsed < msPerHour) {
    let total = Math.round(elapsed / msPerMinute)
    if (total === 1) return `${total} minute ago`
    return `${total} minutes ago`
  } else if (elapsed < msPerDay) {
    let total = Math.round(elapsed / msPerHour)
    if (total === 1) return `${total} hour ago`
    return `${total} hours ago`
  } else if (elapsed < msPerMonth) {
    let total = Math.round(elapsed / msPerDay)
    if (total === 1) return `${total} day ago`
    return `${total} days ago`
  } else if (elapsed < msPerYear) {
    let total = Math.round(elapsed / msPerMonth)
    if (total === 1) return `${total} month ago`
    return `${total} months ago`
  } else {
    let total = Math.round(elapsed / msPerYear)
    if (total === 1) return `${total} year ago`
    return `${total} years ago`
  }
}
