import React from 'react'
import { createUseStyles } from 'react-jss'
import { useHistory } from 'react-router'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import { black, yellow } from '../assets/colorPalette'

const useStyles = createUseStyles({
  mainContainer: {
    padding: 16
  },
  paperStyles: {
    padding: 32,
    backgroundColor: `${yellow}!important`,
    color: `${black}!important`
  },
  title: {
    fontWeight: '500!important'
  },
  buttonContainer: {
    width: 200
  }
})

const NoListings = () => {
  const classes = useStyles()
  const history = useHistory()
  const localMLSSettings = JSON.parse(localStorage.getItem('mlsSettings'))
  const localMLS = localMLSSettings.settings

  const handleClick = () => {
    history.push({ pathname: '/verify-mls' })
  }

  return (
    <Grid container direction="row" justify="center" alignItems="center" spacing={4} className={classes.mainContainer}>
      <Grid item xs={8}>
        <Paper className={classes.paperStyles} elevation={5}>
          <Typography variant="h4" className={classes.title}>
            No listing data found for KSZMLS - New Real State Corp. / {localMLS.mls_id}
          </Typography>
          <br />
          <Typography variant="body2">
            Make sure you selected the <b>correct MLS</b> and entered your MLS <b>Agent ID</b> or <b>Office ID</b>{' '}
            exactly.
          </Typography>
          <br />
          <div className={classes.buttonContainer}>
            <Button variant="contained" color="primary" fullWidth onClick={() => handleClick()}>
              Change MLS ID
            </Button>
          </div>
          <br />
          <Typography variant="body2">We currently support the following MLS Regions in Utah:</Typography>
          <Typography variant="body2">
            <ol>
              <li>Some MLS.</li>
              <li>Cool MLS.</li>
              <li>Nice MLS.</li>
              <li>Big MLS.</li>
              <li>Small MLS.</li>
            </ol>
          </Typography>
          <Typography variant="h5" className={classes.title}>
            Other things to try...
          </Typography>
          <Typography variant="body2">
            <ol>
              <li>
                Make sure you entered your <b>MLS ID</b> and{' '}
                <b>
                  <u>
                    <i>not your license number</i>.
                  </u>
                </b>
              </li>
              <li>
                <b>Double check your MLS ID</b>, it's easy to misread letter and numbers such as OH/ZERO (O/0),
                ELL/EYE/ONE (l,i,1), etc.
              </li>
              <li>
                Verify that you brokerage publishes to a <b>supported MLS</b>.
              </li>
            </ol>
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  )
}
export default NoListings
