import React, { Fragment, useState, useEffect, createContext } from 'react'
import { useHistory, withRouter, useParams } from 'react-router'
import { createUseStyles } from 'react-jss'
import { createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles'
import SwipeableViews from 'react-swipeable-views'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Chip from '@material-ui/core/Chip'

import Dashboard from '../../pages/Dashboard'
import Listings from '../../pages/Listings'
import Listing from '../../pages/Listing'
import Checkout from '../../pages/Checkout'
import Ads from '../../pages/Ads'
import Profile from '../../pages/Profile'
import NoListings from '../../pages/NoListings'

import NavBar from './NavBar'
import { blue } from '../../assets/colorPalette'

import api from '../../services/api'

// * ------ context api ------
const AdContext = createContext(undefined)
const AdDispatchContext = createContext(undefined)
// *----- context api - ends --

// https://github.com/mui-org/material-ui/issues/10651
// contrast text used to be "white" and would crash when the Chip was implemented in the Tab
// * theme
const theme = createMuiTheme({
  palette: {
    primary: {
      main: blue,
      contrastText: '#fff'
    }
  }
})

// * stlyes
const useStyles = createUseStyles({
  container: {
    padding: 32,
    position: 'relative',
    minHeight: 'calc(100vh - 128px)',
    height: '100%'
  },
  menuPaper: {
    maxWidth: '1100px',
    width: '100vw'
  },
  contentContainer: {
    marginTop: '24px!important',
    width: 1100
  },
  tabPanelStyles: {
    overflow: 'hidden'
  }
})
//* api actions
const getListings = (mlsId, hook) => api.get(`/listings/mls-settings/${mlsId}?pageSize=30`, hook)
const getMlsSettings = (userId, hook) => api.get(`/mls-settings/user/${userId}`, hook)
const getAds = (hook) => api.get(`/facebook/me/ads`, hook)

// * custom components
const CustomTab = withStyles({
  root: {
    transition: '0.3s ease',
    '&:hover': {
      color: blue
    }
  }
})(Tab)

// * components
const TabPanel = (props) => {
  const classes = useStyles()
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`main-content-tab-${index}`}
      aria-labelledby={`content-tab-${index}`}
      {...other}
      className={classes.tabPanelStyles}
    >
      {value === index && <div>{children}</div>}
    </div>
  )
}

const isTabbedRoute = (location) => {
  const { pathname } = location
  if (pathname === '/dashboard' || pathname === '/listings' || pathname === '/ads' || pathname === '/profile') {
    return true
  }
  return false
}

const renderChild = (location, mlsNum) => {
  const { pathname } = location

  if (mlsNum === undefined) {
    switch (pathname) {
      // all routes that don't need a mlsNum for a listing go here
      case '/no-listings':
        return <NoListings />
      default:
        return <h1>404</h1>
    }
  } else {
    // every other route goes here (not from the main 4 tabs, and that requires a mlsNum)
    if (pathname.includes('/listings/')) {
      return <Listing listingId={mlsNum} />
    }
    if (pathname.includes('/checkout/')) {
      return <Checkout listingId={mlsNum} />
    }
    // return <Listing listingId={mlsNum} />
  }
}
const AppContainer = ({ children, location }) => {
  const classes = useStyles()
  const listingsObj = JSON.parse(localStorage.getItem('listings'))
  const listingArray = listingsObj.listings
  const mlsSettings = JSON.parse(localStorage.getItem('mlsSettings'))
  const history = useHistory()
  const { mlsNum } = useParams()
  const [value, setValue] = useState(0)
  const [apiListings, setApiListings] = useState('')
  const [apiMlsSettings, setApiMlsSettings] = useState('')
  const [myAds, setMyAds] = useState(null)
  // *context hook
  const [adDetails, setAdDetails] = useState({
    ad_duration: 14,
    ad_daily_budget: 10,
    ad_description: null,
    image_index: 0,
    from_listing: true,
    ad_name: 'See all the photos NOW!'
  })
  // ! ON DEPLOY: change 'from_listing' to false when deployed

  useEffect(() => {
    // console.log({apiMlsSettings});
    // console.log({apiListings});
    if (location.pathname === '/dashboard') {
      setValue(0)
    }
    if (location.pathname === '/listings') {
      setValue(1)
    }
    if (location.pathname === '/ads') {
      setValue(2)
    }
    if (location.pathname === '/profile') {
      setValue(3)
    }
    // componentWillUnmount
    return () => {
      setValue(0)
    }
  }, [apiListings, location])

  useEffect(() => {
    // !important read for working with fetch in the current architecture
    // https://davidwalsh.name/cancel-fetch
    // https://stackoverflow.com/questions/48998013/multiple-fetch-with-one-signal-in-order-to-abort-them-all

    getListings(mlsSettings.settings.id, setApiListings)
    getMlsSettings(mlsSettings.settings.user_id, setApiMlsSettings)
    getAds(setMyAds)
  }, [])

  const appLogout = () => {
    window.FB.logout((res) => {
      console.log(res)
      console.log('Successfully Logged out')
      localStorage.clear()
      history.push({ pathname: '/facebook-login' })
    })
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const handleChangeIndex = (index) => {
    setValue(index)
  }

  return (
    <Fragment>
      <NavBar logout={appLogout} location={location} />
      <ThemeProvider theme={theme}>
        {isTabbedRoute(location) ? (
          <div className={classes.container}>
            <Grid container direction="column" justify="flex-start" alignItems="center">
              <Grid item xs={12}>
                <Paper className={classes.menuPaper}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="Main App Navigation Menu"
                    indicatorColor="primary"
                    textColor="primary"
                  >
                    <CustomTab label="Dashboard" />
                    {/* https://stackoverflow.com/questions/56136435/how-can-i-create-custom-tabs-with-multiple-labels-per-tab-in-material-ui */}
                    <CustomTab
                      label={
                        <div>
                          Listings{' '}
                          <Chip label={!!apiListings ? apiListings.listings.totalResults : listingArray.length} />
                        </div>
                      }
                    />
                    <CustomTab
                      label={
                        <div>
                          Ads <Chip label={!!myAds ? myAds.ads.length : '0'} />
                        </div>
                      }
                    />
                    <CustomTab label="Account" />
                  </Tabs>
                </Paper>
              </Grid>
              <Grid item xs={12} className={classes.contentContainer}>
                <SwipeableViews
                  axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                  index={value}
                  onChangeIndex={handleChangeIndex}
                >
                  <TabPanel value={value} index={0} dir={theme.direction}>
                    <Dashboard apiListings={apiListings} />
                  </TabPanel>
                  <TabPanel value={value} index={1} dir={theme.direction}>
                    <AdContext.Provider value={adDetails}>
                      <AdDispatchContext.Provider value={setAdDetails}>
                        <Listings />
                      </AdDispatchContext.Provider>
                    </AdContext.Provider>
                  </TabPanel>
                  <TabPanel value={value} index={2} dir={theme.direction}>
                    <Ads myAds={myAds} />
                  </TabPanel>
                  <TabPanel value={value} index={3} dir={theme.direction}>
                    <Profile mlsSettings={apiMlsSettings} />
                  </TabPanel>
                </SwipeableViews>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div className={classes.container}>
            <AdContext.Provider value={adDetails}>
              <AdDispatchContext.Provider value={setAdDetails}>
                {renderChild(location, mlsNum)}
              </AdDispatchContext.Provider>
            </AdContext.Provider>
          </div>
        )}
      </ThemeProvider>
    </Fragment>
  )
}
export { AdContext, AdDispatchContext }
export default withRouter(AppContainer)
