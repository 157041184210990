// const facebookAppId = env.REACT_APP_FACEBOOK_APP_ID;

export function initFacebookSdk() {
  return new Promise((resolve) => {
    // wait for facebook sdk to initialize before starting the react app
    window.fbAsyncInit = function () {
      console.log('Facebook SDK loaded')
      window.FB.init({
        appId: 1959179517511317,
        xfbml: true,
        version: 'v16.0',
        status: true
      })

      // auto authenticate with the api if already logged in with facebook
      window.FB.getLoginStatus(({ authResponse }) => {
        if (authResponse) {
          resolve() //aqui va para no pedir el boton de facebook y logearse de una TO DO
          // accountService.apiAuthenticate(authResponse.accessToken).then(resolve);
        } else {
          resolve()
        }
      })
    }

    // load facebook sdk script
    ;(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')
  })
}
