import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, useHistory, Redirect } from 'react-router-dom'

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  let history = useHistory()
  let userInfo = localStorage.getItem('brUserInfo')
  let listings = localStorage.getItem('listings')

  const render = (props) => {
    const isReady = !!userInfo
    return isReady ? <Component {...props} /> : 'Failed to load requested page. Please reload this page'
    // TODO: add fallback page indicating that something went wrong on loggin in the user
  }
  return <Route path={path} render={render} {...rest} />
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired
}

export default PrivateRoute
