import React, { useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { useHistory } from 'react-router'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'

import FacebookIcon from '@material-ui/icons/Facebook'
import BottomNavBar from '../components/Base/BottomNavBar'
import { blue, facebook, facebookHover } from '../assets/colorPalette'
import api from '../services/api'

const useStyles = createUseStyles({
  paper: {
    width: 400,
    height: 350,
    position: 'absolute',
    top: 'calc(50% - 175px)',
    left: 'calc(50% - 200px)'
  },
  loginHeader: {
    width: '100%',
    height: 72,
    background: blue,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  logo: {
    backgroundColor: blue
  },
  title: {
    paddingLeft: 24,
    fontFamily: 'Roboto',
    textAlign: 'left'
  },
  gridItem: {
    width: '100%',
    textAlign: 'center',
    padding: 20
  },
  loginButton: {
    textTransform: 'initial!important'
  },
  facebookButtonWrapper: {
    margin: 8,
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
})

const CustomButton = withStyles({
  root: {
    background: facebook,
    color: '#fff',
    '&:hover': {
      backgroundColor: facebookHover
    },
    borderRadius: 50,
    paddingLeft: 40,
    paddingRight: 40
  },
  label: {
    textTransform: 'uppercase',
    fontWeight: 700
  }
})(Button)

const FacebookLogin = ({
  button,
  onLoginStatus,
  onFbLogin,
  integrationData,
  userTokenStatus,
  dcFacebook,
  dcFacebookResponse,
  agentEmail
}) => {
  const history = useHistory()
  const classes = useStyles()
  const [fetchingFB, setFetchingFB] = useState(true)
  const [SDKLoaded, setSDKLoaded] = useState(true)
  const [manualButtonDisable, setManualButtonDisable] = useState(false)
  const [facebookLoginStatus, setFacebookLoginStatus] = useState('')

  const setCookiesByPrefix = (prefix, value) => {
    // Get an array of cookies
    var arrSplit = document.cookie.split(';')

    for (var i = 0; i < arrSplit.length; i++) {
      var cookie = arrSplit[i].trim()
      var cookieName = cookie.split('=')[0]

      // If the prefix of the cookie's name matches the one specified, remove it
      if (cookieName.indexOf(prefix) === 0) {
        // Set the cookie value
        let setCookie = cookieName + '=' + value + ';'
        document.cookie = setCookie
      }
    }
  }

  const setSessionKeyByPrefix = (prefix, value) => {
    for (var i = 0; i < sessionStorage.length; i++) {
      var keyName = sessionStorage.key(i)

      // If the prefix of the cookie's name matches the one specified, remove it
      if (keyName.indexOf(prefix) === 0) {
        // Set the item value
        sessionStorage.setItem(keyName, value)
      }
    }
  }

  useEffect(() => {
    if (userTokenStatus?.error_code > 0) {
      console.log('facebook user token error message: ' + userTokenStatus?.message)

      dcFacebook()
      window.FB.logout((res) => {
        setFacebookLoginStatus(res.status)
      })

      window.FB.getLoginStatus((res) => console.log(res.status))

      localStorage.setItem('isFBLoggedIn', false)
      localStorage.setItem('fb_info', '')

      //Set Facebook session items to null
      setSessionKeyByPrefix('fbssls_', null)

      // Set Facebook cookies to 'n' (no)
      setCookiesByPrefix('fblo_', 'n')
    }
  }, [userTokenStatus])

  // const getSDK = (setFunction) => {
  //   let loaded = false;
  //   const interval = setInterval(() => {
  //     if(window.FB){
  //       loaded = true;
  //       clearInterval(interval);
  //     }
  //   }, 500);
  //   if(loaded){
  //     clearInterval(interval);
  //     setFunction(true);
  //     return true;
  //   }
  // }

  useEffect(() => {
    // const asyncSDKFetch = async () => {
    //   if(window.FB){
    //     setSDKLoaded(true);
    //   } else{
    //     await getSDK(setSDKLoaded);
    //   }
    // }
    // asyncSDKFetch();
    if (window.FB) {
      window.FB.getLoginStatus((res) => {
        onLoginStatus && onLoginStatus(res)
        setFacebookLoginStatus(res.status)
      })
    }
  }, [])

  // useEffect(() => {
  //   if(window.FB){
  //     window.FB.getLoginStatus((res) => {
  //       setFetchingFB(false);
  //       onLoginStatus && onLoginStatus(res);
  //     });
  //   };
  // }, [SDKLoaded]);

  const defaultOnLogin = () => {
    history.push({ pathname: '/verify-mls' })
  }

  const loginToFacebook = () => {
    setManualButtonDisable(true)
    window.FB.login(
      (res) => {
        if (res.status === 'connected') {
          console.log('connected')
          window.FB.api('/me?fields=id,name,email,first_name,last_name,picture', async (me) => {
            setManualButtonDisable(false)
            if (!me.email) me.email = agentEmail
            localStorage.setItem('fb_info', JSON.stringify(me))
            localStorage.setItem('isFBLoggedIn', true)
            if (integrationData) {
              await api.authAPIUser(res.authResponse.accessToken, integrationData)
            } else {
              await api.authAPIUser(res.authResponse.accessToken)
            }
            onFbLogin ? onFbLogin(me) : defaultOnLogin()
          })
        } else if (res.status === 'not_authorized') {
          console.log('not authorized')
        } else if (res.status === 'unknown') {
          console.log('unknown')
          setManualButtonDisable(false)
        } else {
          console.log('something went horribly wrong')
        }
      },
      {
        scope:
          'email, pages_show_list, ads_management, business_management, pages_messaging, pages_read_engagement, pages_manage_metadata, pages_manage_ads, public_profile, leads_retrieval'
      }
    )
    setManualButtonDisable(false)
    // november 19: removed - pages_manage_metadata,
  }

  const FacebookButton = () => (
    <div className={classes.facebookButtonWrapper}>
      <p>
        {facebookLoginStatus !== 'connected' && (
          <>
            <ReportProblemOutlinedIcon style={{ color: 'red', fontSize: '60' }} />
            <br />
            <span style={{ color: 'red' }}>
            Your Facebook account link to Brivity Marketer has expired.
            </span>
          </>
        )}
      </p>
      <CustomButton
        size="large"
        startIcon={<FacebookIcon />}
        disabled={manualButtonDisable}
        onClick={manualButtonDisable ? undefined : loginToFacebook}
        className={classes.loginButton}
      >
        {facebookLoginStatus !== 'connected' ? 'Refresh your Facebook link' : 'Continue with Facebook'}
      </CustomButton>
      {/* {fetchingFB && <CircularProgress color="primary" size={24} className={classes.buttonProgress} /> } */}
    </div>
  )

  if (button)
    return (
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={12}>
          <FacebookButton />
        </Grid>
      </Grid>
    )

  return (
    <div>
      <Paper elevation={5} className={classes.paper}>
        <div className={classes.loginHeader}>
          <div className={classes.logo}>
            <img alt="" src={require('../assets/svg/brlogo.svg')} />
          </div>
        </div>
        <Grid container direction="column" justify="flex-end" alignItems="center">
          <Grid item xs={12} className={classes.gridItem}>
            <h2 className={classes.title}>Login</h2>
          </Grid>
          <Grid item xs={12} className={classes.gridItem}>
            <FacebookButton />
          </Grid>
        </Grid>
      </Paper>
      <BottomNavBar />
    </div>
  )
}

export default FacebookLogin
