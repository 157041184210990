import React, { Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import BarChartIcon from '@material-ui/icons/BarChart'
import HomeIcon from '@material-ui/icons/Home'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { blue, darkestGrey } from '../assets/colorPalette'

const useStyles = createUseStyles({
  mainContainer: {
    padding: 16
  },
  paperStyles: {
    padding: 16
  },
  counter: {
    marginTop: '32px!important',
    fontWeight: '700!important',
    color: darkestGrey
  },
  logo: {
    color: blue
  },
  buttonContainer: {
    textAlign: 'center'
  }
})

const Dashboard = ({ apiListings }) => {
  const classes = useStyles()
  const listingsObj = JSON.parse(localStorage.getItem('listings'))
  const listingArray = listingsObj.listings
  // private route logic
  // if(!localStorage.brUserInfo && !localStorage.fb_info) return <Redirect to="/login" />

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      spacing={4}
      className={classes.mainContainer}
    >
      <Grid item xs={4}>
        <Paper className={classes.paperStyles} elevation={5}>
          <Grid container direction="column" justify="flex-start" alignItems="center">
            <Grid item xs={12}>
              <HomeIcon fontSize="large" className={classes.logo} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3" className={classes.counter}>
                {!!apiListings ? apiListings.listings.totalResults : listingArray.length}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">Listings</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={8}>
        <Paper className={classes.paperStyles} elevation={5}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={6}>
              <Grid container direction="column" justify="flex-start" alignItems="center">
                <Grid item xs={12}>
                  <BarChartIcon fontSize="large" className={classes.logo} />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h3" className={classes.counter}>
                    0
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">Ads</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.buttonContainer}>
              <Button variant="contained" color="primary" endIcon={<ArrowForwardIcon />}>
                Advertise on Facebook
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
export default Dashboard
