import { Button, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import ReactPlayer from 'react-player'
const getButtonStyle = (currentVideoRatio, videoRatio) => {
  const isSelected = currentVideoRatio === videoRatio
  return {
    border: isSelected ? '2px solid' : 'unset',
    borderRadius: '16px',
    borderColor: isSelected ? '#59c4c4' : '#EBEAEB',
    backgroundColor: isSelected ? 'transparent' : '#EBEAEB',
    color: isSelected ? '#59c4c4' : 'unset',
    fontWeight: 700
  }
}

const VideoAdPreviewCard = ({ videos, instagramMode = false, checkoutPage = false, displayRatioSelector = true  }) => {
  const [currentVideo, setCurrentVideo] = useState({ url: videos[0].videoURL, ratio: videos[0].videoRatio })

  const changeVideo = (video) => {
    setCurrentVideo({ url: video.videoURL, ratio: video.videoRatio })
  }

  const ratioNameTable = {
    '1:1': 'Square',
    '9:16': 'Story'
  }

  return (
    <div>
      {displayRatioSelector && 
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline', paddingBottom: 10, paddingTop: checkoutPage ? 50 : 15 }}>
          <Typography>Preview video as:</Typography>
          {videos.map((video, index) => (
            <div key={index} style={{ paddingLeft: 10 }}>
              <Button onClick={() => changeVideo(video)} style={getButtonStyle(currentVideo.ratio, video.videoRatio)}>
                {ratioNameTable[video.videoRatio]}
              </Button>
            </div>
          ))}
        </div>
      }
      <ReactPlayer
        url={currentVideo.url}
        height={instagramMode ? '290px' : '350px'}
        width={'100%'}
        controls={true}
        volume={0.5}
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload noplaybackrate'
            }
          }
        }}
        pip
      />   
    </div>
  )
}

export default VideoAdPreviewCard
