import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

import { AdContext, AdDispatchContext } from '../components/Base/AppContainer'

import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary'
import { blue, darkestGrey, black } from '../assets/colorPalette'

const useStyles = createUseStyles({
  mainContainer: {
    padding: 16
  },
  paperStyles: {
    padding: 32
  },
  mainListingImageContainer: {
    width: 304,
    height: 200,
    paddingTop: 6
  },
  navigationContainer: {
    paddingRight: 96
  },
  mainListingImage: {
    height: '100%',
    width: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: 8
  },
  searchStyles: {
    width: 200
  },
  bottomNavigationContainer: {
    margin: 16,
    marginTop: 32
  },
  contentBlockInnerGridItem: {
    width: '100%'
  },
  priceGrid: {
    paddingTop: 24
  },
  remarksWrapper: {
    maxHeight: 80,
    overflow: 'hidden'
  },
  remarks: {
    lineHeight: '2em!important'
  },
  spaceBetweenSecondToLastEl: {
    textAlign: 'center!important'
  },
  spaceBetweenLastEl: {
    textAlign: 'end!important'
  },
  blueText: {
    color: `${blue}!important`
  },
  greyText: {
    color: `${darkestGrey}!important`
  },
  blackText: {
    color: `${black}!important`
  },
  boldText: {
    fontWeight: '600!important'
  }
})

const ListingCards = ({ adDetails, setAdDetails }) => {
  const classes = useStyles()

  const listingsObj = JSON.parse(localStorage.getItem('listings'))
  const listingArray = listingsObj.listings
  console.log({ listingArray })

  let newAdDetails = { ...adDetails, ad_duration: 14, ad_daily_budget: 10 }

  const cards = listingArray.map((listing, i) => {
    return (
      <Grid item xs={12}>
        <Paper className={classes.paperStyles} elevation={5} key={i}>
          <Grid container direction="row" justify="center" alignItems="flex-start" spacing={3}>
            <Grid item xs={4}>
              <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
                <Grid item xs={12}>
                  <div className={classes.mainListingImageContainer}>
                    <div
                      style={{
                        backgroundImage: `url(${listing.photos[0].url})`
                      }}
                      className={classes.mainListingImage}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" className={`${classes.blueText} ${classes.boldText}`}>
                    <PhotoLibraryIcon fontSize="small" className={classes.blueText} />
                    {listing.photos.length > 1 ? `${listing.photos.length} more photos` : 'Listing photo'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
                <Grid item xs={12} className={classes.contentBlockInnerGridItem}>
                  <Typography variant="h4" className={`${classes.blueText} ${classes.boldText}`}>
                    {listing.address.street}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.contentBlockInnerGridItem}>
                  <Typography variant="subtitle1" className={classes.greyText}>
                    {listing.standardPropertyType} • {listing.address.county}, {listing.address.state}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={`${classes.contentBlockInnerGridItem} ${classes.blackText}`}>
                  <Grid container direction="row" justify="flex-start" alignItems="center">
                    <Grid item xs={3}>
                      <Typography variant="subtitle2">{listing.bedrooms} beds</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="subtitle2">{listing.totalBaths} baths</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="subtitle2" className={classes.spaceBetweenSecondToLastEl}>
                        {listing.sqFeet.toLocaleString()} sqft
                      </Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.spaceBetweenLastEl}>
                      <Typography variant="subtitle2">{listing.acreage} acres</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={classes.contentBlockInnerGridItem}>
                  <div className={classes.remarksWrapper}>
                    <Typography variant="body2" className={`${classes.remarks} ${classes.greyText}`}>
                      {listing.remarks}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} className={`${classes.contentBlockInnerGridItem} ${classes.blackText}`}>
                  <Grid container direction="row" justify="space-between" alignItems="center">
                    <Grid item xs={8}>
                      <Typography variant="subtitle2">{listing.Agents[0].Name}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="subtitle2" className={classes.spaceBetweenLastEl}>
                        MLS ID: {listing.Agents[0].ID}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
                spacing={1}
                className={classes.priceGrid}
              >
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.greyText}>
                    Listing Price:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h4" className={`${classes.blackText} ${classes.boldText}`}>
                    ${listing.price.toLocaleString()}
                  </Typography>
                </Grid>
                <br />
                <Grid item xs={12}>
                  {/* https://stackoverflow.com/a/54378357/8460812 */}
                  <Button
                    fullWidth
                    component={Link}
                    to={`/listings/${listing.mlsNum}`}
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() => setAdDetails(newAdDetails)}
                  >
                    View Facebook Ad
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    color="primary"
                    component={Link}
                    to={`/listing-page?mls=${listing.mlsId}&listing=${Number(listing.mlsNum)}`}
                    endIcon={<ArrowForwardIcon />}
                  >
                    View Listings Page
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    )
  })
  return cards
}
const Listings = () => {
  const classes = useStyles()
  // context
  const adDetails = useContext(AdContext)
  const setAdDetails = useContext(AdDispatchContext)
  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      spacing={4}
      className={classes.mainContainer}
    >
      <Grid item xs={5}>
        <Paper className={classes.mainContainer}>
          <Grid container direction="row" justify="flex-start" alignItems="center">
            <Grid item xs={2}>
              <Typography>Filter: </Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField placeholder="Address, Name, MLS ID" fullWidth />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <div className={classes.navigationContainer}>
        <Paper>
          <ButtonGroup variant="outlined" color="primary" aria-label="Listing paging navigation">
            <Button disabled>Previous</Button>
            <Button variant="contained" color="primary">
              1
            </Button>
            <Button>2</Button>
            <Button>3</Button>
            <Button>...</Button>
            <Button>8</Button>
            <Button>Next</Button>
          </ButtonGroup>
        </Paper>
      </div>
      <ListingCards adDetails={adDetails} setAdDetails={setAdDetails} />
      <Paper className={classes.bottomNavigationContainer}>
        <ButtonGroup variant="outlined" color="primary" aria-label="Listing paging navigation">
          <Button disabled>Previous</Button>
          <Button variant="contained" color="primary">
            1
          </Button>
          <Button>2</Button>
          <Button>3</Button>
          <Button>...</Button>
          <Button>8</Button>
          <Button>Next</Button>
        </ButtonGroup>
      </Paper>
    </Grid>
  )
}
export default Listings
