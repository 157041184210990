import React from 'react'
import { createUseStyles } from 'react-jss'
import Router from './Router'
import { lightestGrey } from './assets/colorPalette'
import { init as initApm } from '@elastic/apm-rum'

import '@brivity/ai-components/dist/style.css'

const elasticActive = process.env.REACT_APP_ELASTIC_APM_ENABLED
const elasticServerUrl = process.env.REACT_APP_ELASTIC_RUM_SERVER_URL
const elasticTransactionSampleRate = process.env.REACT_APP_ELASTIC_APM_TRANSACTION_SAMPLE_RATE

initApm({
  active: elasticActive,
  breakdownMetrics: true,
  flushInterval: 1000,
  logLevel: 'error',
  serviceName: 'facebook-listings-ads-rum',
  serverUrl: elasticServerUrl,
  transactionSampleRate: elasticTransactionSampleRate
})

const useStyles = createUseStyles({
  appBody: {
    width: '100vw',
    minHeight: '100vh',
    height: '100%',
    background: lightestGrey,
    overflowY: 'scroll'
  }
})

const App = () => {
  const classes = useStyles()
  return (
    <div className={classes.appBody}>
      <Router />
    </div>
  )
}

export default App
