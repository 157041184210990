import React, { Fragment, useState, useEffect, useContext, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import { useTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'

import CheckRoundedIcon from '@material-ui/icons/CheckRounded'
import CloseIcon from '@material-ui/icons/Close'
import AddIcon from '@material-ui/icons/Add'
import api from '../services/api'

import { trimText } from '../util/Helpers'

import { darkestGrey, marketerGrey, marketerBlue } from '../assets/colorPalette'

import { MarketerAdContext, MarketerAdDispatchContext } from '../components/Base/MarketerContainer'

const useStyles = createUseStyles({
  mainContainer: {
    padding: 16
  },
  fullContainer: {
    width: '100%'
  },
  cardContainerPage: {
    padding: '8px 24px!important'
  },
  alignStart: {
    textAlign: 'start!important'
  },
  alignEnd: {
    textAlign: 'end!important'
  },
  marginTopMedium: {
    marginTop: '16px!important'
  },
  marginTopLarge: {
    marginTop: '24px!important'
  },
  darkestGrey: {
    color: `${darkestGrey}!important`
  },
  loaderContainer: {
    textAlign: 'center',
    paddingTop: 150
  },
  pageCard: {
    cursor: 'pointer'
  },
  createButtonContainer: {
    paddingTop: '24px!important'
  },
  marketerButton: {
    fontWeight: '700!important',
    borderRadius: '25px!important',
    minWidth: '100px!important',
    maxWidth: '250px!important',
    position: 'static!important'
  },
  cancelButton: {
    color: `${marketerGrey}!important`
  },
  dcButton: {
    right: '20px',
    width: '250px'
  },
  createPageButton: {
    color: `${marketerBlue}!important`,
    backgroundColor: 'rgba(89, 196, 196, 0.2)!important',
    '&:hover': {
      color: '#fff!important',
      backgroundColor: 'rgba(89, 196, 196, 0.8)!important'
    }
  },
  tosIcon: {
    color: `${marketerBlue}!important`
  },
  accepTosButton: {
    color: `${marketerBlue}!important`,
    transition: 'all .3s ease!important',
    cursor: 'pointer',
    '&:hover': {
      color: '#357676!important'
    }
  },
  chipContainer: {
    paddingLeft: 28
  },
  validChip: {
    color: '#fff!important',
    backgroundColor: '#F5B350!important',
    cursor: 'pointer!important'
  },
  invalidChip: {
    color: '#fff!important',
    backgroundColor: '#F2714D!important'
  },
  fullContainerError: {
    width: '100%',
    color: '#721c24',
    backgroundColor: '#f8d7da',
    borderColor: '#f5c6cb',
    border: '1px solid',
    borderRadius: '0.25rem',
    padding: '5px',
    justifyContent: 'center'
  },
  pagesWrapper: {
    maxHeight: '450px',
    overflow: 'auto'
  }
})

const FbPageSelect = ({
  userFbPages,
  cancelAction,
  standaloneAction,
  sliderIndex,
  resetUserFbPages,
  fetchUserFbPages,
  standalone,
  dcFacebook,
  closeModal
}) => {
  // external hooks
  let classes = useStyles()
  let theme = useTheme()
  // hooks
  let [selectedFbPage, setSelectedFbPage] = useState(null)
  let [clickedFbTOS, setClickedFbTOS] = useState(false)
  let [clickedCreateFbPage, setclickedCreateFbPage] = useState(false)
  let [validatedFbPages, setValidatedFbPages] = useState({})
  // context
  let adDetails = useContext(MarketerAdContext)
  let setAdDetails = useContext(MarketerAdDispatchContext)

  const getIsPageValid = async (id, pageToken) =>
    api.getValidPage(`/facebook/assign-page/${id}/token/${pageToken}`, id, setValidatedFbPages, validatedFbPages)
  // effects
  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange)
    if (clickedFbTOS) {
      resetUserFbPages()
    }
    if (clickedCreateFbPage) {
      resetUserFbPages()
    }
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  })
  useEffect(() => {
    if (validatedFbPages[selectedFbPage] === false) setSelectedFbPage(null)
  }, [validatedFbPages])
  // functions
  const handleFacebookPage = () => {
    if (selectedFbPage) {
      let fbPage =
        adDetails && selectedFbPage && userFbPages && userFbPages.pages.find((el) => el.id === selectedFbPage)
      let newAdDetails = {
        ...adDetails,
        pageToken: fbPage.page_token,
        page_id: selectedFbPage,
        page_name: fbPage.name,
        page_profile_img: fbPage.picture.data.url,
        allowBackButton: true
      }
      setAdDetails(newAdDetails)
    }
    standaloneAction()
  }
  const handleCheckbox = async (e, id, pageToken) => {
    e.preventDefault()
    e.stopPropagation()
    await getIsPageValid(id, pageToken)

    if (id !== selectedFbPage) setSelectedFbPage(id)
  }
  const handleFacebookTOS = () => {
    setClickedFbTOS(true)
    if (clickedCreateFbPage) setclickedCreateFbPage(false)
    window.open('https://www.facebook.com/ads/leadgen/tos', '_blank')
  }
  const handleCreateFacebookPage = () => {
    setclickedCreateFbPage(true)
    window.open('https://www.facebook.com/pages/create', '_blank')
  }
  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible' && clickedFbTOS) {
      fetchUserFbPages()
      setClickedFbTOS(false)
    }
    if (document.visibilityState === 'visible' && clickedCreateFbPage) {
      fetchUserFbPages()
      setclickedCreateFbPage(false)
    }
    if (document.visibilityState === 'visible' && clickedCreateFbPage) {
      fetchUserFbPages()
    }
  }
  const allowedIndexes = [2, 5]

  if (userFbPages === null) {
    return (
      <div className={classes.loaderContainer}>
        <div className={classes.loaderContainer}>
          <CircularProgress color="primary" size={75} />
        </div>
      </div>
    )
  } else {
    if (!allowedIndexes.includes(sliderIndex)) return null
    const renderCards = (fbPages) => {
      let cards = fbPages.pages.map((el, i) => {
        return (
          <Grid key={`${el.id}-${i}`} item xs={6}>
            <Paper elevation={2} className={classes.cardContainerPage}>
              {validatedFbPages[el.id] === false && (
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  className={classes.fullContainerError}
                >
                  <Typography align="center">
                    You're not an owner of this Facebook page. Please make sure you have the right page permissions or
                    follow these{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/business/help/183277585892925?id=420299598837059"
                    >
                      {' '}
                      Steps{' '}
                    </a>
                    <br />
                    After that, you can{' '}
                    <a
                      style={{ cursor: 'pointer' }}
                      href="#"
                      onClick={(e) => (el.leadgen_tos_accepted ? handleCheckbox(e, el.id, el.page_token) : undefined)}
                    >
                      Revalidate
                    </a>{' '}
                    the page
                  </Typography>
                </Grid>
              )}
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                className={classes.fullContainer}
              >
                <Grid
                  item
                  style={{ display: 'flex' }}
                  xs={6}
                  className={`${el.leadgen_tos_accepted && classes.pageCard}`}
                  onClick={(e) => (el.leadgen_tos_accepted ? handleCheckbox(e, el.id, el.page_token) : undefined)}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedFbPage === el.id}
                        name={el.name}
                        color="primary"
                        disabled={!el.leadgen_tos_accepted || validatedFbPages[el.id] === false}
                      />
                    }
                    label={trimText(el.name, 40)}
                    classes={{
                      label: el.leadgen_tos_accepted && classes.darkestGrey
                    }}
                  />
                  <br />
                  {/* <div className={classes.chipContainer}><Chip size="small" label={el.leadgen_tos_accepted ? 'TOS Accepted' : 'TOS Not Accepted'} classes={{root: el.leadgen_tos_accepted ? classes.validChip : classes.invalidChip}} /></div> */}
                </Grid>
                <Grid item xs={6} className={classes.alignEnd}>
                  {el.leadgen_tos_accepted ? (
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                      className={classes.fullContainer}
                    >
                      <Grid item xs={6}>
                        <CheckRoundedIcon className={classes.tosIcon} fontSize="large" />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography style={{ color: darkestGrey }}>Terms Accepted</Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Typography className={classes.accepTosButton} onClick={handleFacebookTOS}>
                      Accept Facebook Terms
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )
      })
      if (fbPages.pages.length % 2 !== 0) {
        cards.push(<Grid item xs={6} />)
      }
      return cards
    }

    return (
      <Fragment>
        <Grid container direction="row" justify="space-between" alignItems="center" className={classes.mainContainer}>
          <Grid item xs={6} className={classes.alignStart}>
            <Typography variant="h4" style={{ color: theme.palette.primary.main }}>
              Select Facebook Business Page
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.alignEnd}>
            {
              <IconButton onClick={closeModal} aria-label="close ad creation modal">
                <CloseIcon />
              </IconButton>
            }
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.mainContainer}>
            {userFbPages
              ? userFbPages.pages.length > 0 && (
                  <Grid item xs={12}>
                    <Typography className={classes.darkestGrey}>
                      Choose the Facebook business page you want to run ads for. If a page is missing, try disconnecting
                      and reconnecting your Facebook account. If you still don't see the page, you do not have
                      sufficient permissions to post ads on behalf of that page.{' '}
                      <a target="_blank" href="https://www.facebook.com/help/510247025775149" rel="noreferrer">
                        Click here for more info
                      </a>
                      .
                    </Typography>
                  </Grid>
                )
              : undefined}
          </Grid>
        </Grid>
        <Grid id="pages-wrapper" item xs={12} className={`${classes.fullContainer} ${classes.mainContainer}`}>
          <Grid
            id="pages-item-wrapper"
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={4}
            className={[classes.marginTopMedium, classes.pagesWrapper]}
          >
            {userFbPages ? (
              userFbPages.pages.length > 0 ? (
                renderCards(userFbPages)
              ) : (
                <Typography variant="h6" style={{ color: marketerGrey }}>
                  No Facebook pages were found for this profile.
                </Typography>
              )
            ) : (
              <div className={classes.marginTopMedium}>
                <CircularProgress />
              </div>
            )}
          </Grid>
        </Grid>
        <div style={{
          margin: '1em 1em 0',
          display: 'flex',
          gap: '8px',
          flexDirection: 'row',
          flexWrap: 'none',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
          <div style={{flex: '1'}}>
            <Button
              variant="contained"
              color="primary"
              endIcon={<AddIcon />}
              onClick={handleCreateFacebookPage}
              className={classes.marketerButton}
              classes={{ contained: classes.createPageButton }}
              disableElevation
              fullWidth
            >
              Create New Page
            </Button>
          </div>
          {adDetails.allowBackButton && (
            <div>
              <Button
                  variant="contained"
                  onClick={cancelAction}
                  className={`${classes.marketerButton} ${classes.cancelButton}`}
                  disableElevation
                >
                Back
              </Button>
            </div>
          )}

          <div>
            <Button
              variant="contained"
              style={{ color: '#F2714D', backgroundColor: 'RGB(252,227,219)' }}
              onClick={dcFacebook}
              className={`${classes.marketerButton} ${classes.dcButton}`}
              disableElevation
            >
              Disconnect Facebook Account
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={handleFacebookPage}
              disabled={validatedFbPages[selectedFbPage] === true ? false : true}
              className={classes.marketerButton}
              disableElevation
            >
              Save
            </Button>
          </div>

        </div>
      </Fragment>
    )
  }
}
export default FbPageSelect
