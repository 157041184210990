import React from 'react'
import styled from 'styled-components'
// import { Icon } from './Base';
import DeleteIcon from '@material-ui/icons/Delete'
import Dinero from 'dinero.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'

const PreviewCard = styled.div`
  width: 97%;
  height: 190px!important
  padding: 1rem!important;
  margin: 0.2rem!important;
  // margin-left: 0.5rem!important;
  // margin-right: 0.5rem!important;
  box-shadow: 0 2px 8px rgba(213, 213, 213, 0.5)!important;
  border-radius: 8px!important;
  font-family: "Open Sans";
  font-size: 12px!important;
  text-align: left!important;
  & > .header {
    margin-left: 15px;
    display: flex!important;
    justify-content: space-between!important;
    align-items: center!important;
    text-transform: uppercase!important;
    font-weight: 700!important;
    margin-bottom: 1rem!important;
  }
  & > .content {
    display: grid!important;
    grid-template-columns: 100px 1fr!important;
    grid-template-rows: minmax(75px, auto) auto!important;
    gap: 0.5rem 1rem!important;
    
    & .listing-img > img {
      margin-left: 30px;
      height: 75px!important;
      width: 100%!important;
      object-fit: contain!important;
      object-position: center top!important;
    }
    & .details {
      margin-left: 30px;
      & p:first-child {
        font-size: 14px!important;
        font-weight: 600!important;
      }
      & p {
        margin: 0 0 1px;
      }
    }
    & .mls-credit {
      margin-left: 15px;
      grid-column: span 2!important;
      color: #b8b8b8!important;
      font-size: 12px!important;
      padding-bottom:10px;
    }
  }
  
`

const DeleteIconDiv = styled.div`
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  & > i {
    margin: 0;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.06);
  }
`

export default function CardPreviewListing({ listing, setListing }) {
  const listingData = listing.listing
  return (
    <PreviewCard>
      <div className="header">
        <span>{`Listed by ${listingData?.listAgentName}`}</span>
        <DeleteIconDiv onClick={setListing(listing)}>
          <FontAwesomeIcon icon={faTrashAlt} size="lg" />
          {/* <DeleteIcon name="trash alternate outline" onClick={setListing(listing)} /> */}
        </DeleteIconDiv>
      </div>
      <div className="content">
        <div className="listing-img">
          <img src={listingData?.photos[0]?.url} alt="listing" />
        </div>
        <div className="details">
          {/* {console.log(listingData.price)} */}
          <p>{listingData?.streetAddress}</p>
          <p>{`${listingData?.city}, ${listingData?.state} ${listingData?.postalCode}`}</p>
          <p>
            {Dinero({
              amount: listingData?.price !== undefined && listingData?.price !== null ? listingData?.price * 100 : 0
            }).toFormat('$0,0')}
          </p>
          <p>{`${listingData?.bedrooms} beds | ${listingData?.fullBaths} baths | ${listingData?.squareFeet} sqft`}</p>
        </div>
        <span className="mls-credit">{`Courtesy of ${listingData.originatingSystemName}`}</span>
      </div>
    </PreviewCard>
  )
}
