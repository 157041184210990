import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { withStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

import { blue } from '../../assets/colorPalette'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  title: {
    flexGrow: 1
  },
  iconContainer: {
    backgroundColor: blue,
    width: 46,
    height: 46,
    borderRadius: 30
  },
  appTitle: {
    paddingLeft: 8,
    fontWeight: '500',
    flexGrow: 1
  },
  icon: {
    width: 42,
    padding: 2
  },
  profileMenuButton: {
    textTransform: 'initial'
  }
}))
const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: blue,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: '#fff'
      }
    }
  }
}))(MenuItem)

const NavBar = ({ logout, location }) => {
  const [openMenu, setOpenMenu] = useState(null)

  const history = useHistory()
  const classes = useStyles()
  const userInfo = JSON.parse(localStorage.getItem('fb_info'))

  const handleClick = (e) => setOpenMenu(e.currentTarget)
  const handleClose = () => setOpenMenu(null)

  const handleLogout = () => {
    logout()
    handleClose()
  }
  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{
          backgroundColor: '#fff',
          color: 'black',
          paddingLeft: '0px!important',
          flexDirection: 'column-reverse'
        }}
        className={classes.gutters}
      >
        <Toolbar>
          <div className={classes.iconContainer}>
            <img alt="" src={require('../../assets/svg/brlogo.svg')} className={classes.icon} />
          </div>
          <Typography className={classes.appTitle}>MLS Facebook Ads</Typography>
          <Button
            className={classes.profileMenuButton}
            onClick={handleClick}
            aria-controls="logout-menu"
            startIcon={
              <Avatar
                src={
                  userInfo.picture
                    ? userInfo.picture.data.url
                    : 'url(' + require('../../assets/images/profile-img.png') + ')'
                }
              />
            }
          >
            {userInfo.name}
          </Button>
          <Menu
            id="logout-menu"
            anchorEl={openMenu}
            keepMounted
            open={Boolean(openMenu)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
          >
            {/* <StyledMenuItem onClick={handleClose}> */}
            {location.pathname !== '/verify-mls' ? (
              <StyledMenuItem onClick={() => history.push({ pathname: '/profile' }, handleClose())}>
                {' '}
                <ListItemIcon>
                  <AccountCircleIcon fontSize="medium" />
                </ListItemIcon>
                <ListItemText primary="My Account" />
              </StyledMenuItem>
            ) : (
              ''
            )}
            <StyledMenuItem onClick={handleLogout}>
              <ListItemIcon>
                <ExitToAppIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </StyledMenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  )
}
export default NavBar
