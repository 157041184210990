import React from 'react'
import { Route, Switch } from 'react-router-dom'

import PrivateRoute from './containers/PrivateRoute'
import IndexPage from './containers/IndexPage'
import LoginPage from './containers/LoginPage'
import FacebookPage from './containers/FacebookPage'
import MLSPage from './containers/MLSPage'

import AppContainer from './components/Base/AppContainer'
import MarketerContainer from './components/Base/MarketerContainer'

import FacebookLogin from './pages/FacebokLogin'
import VerifyMLS from './pages/VerifyMLS'
import ListingLandingPage from './pages/ListingLandingPage'
import PrivacyPolicyPage from './pages/PrivacyPolicyPage'

export default () => {
  return (
    <Switch>
      {/* Public Routes */}
      <Route exact path="/" component={IndexPage} />
      <Route path="/login" component={LoginPage} />
      <Route path="/check-fb-login" component={FacebookPage} />
      <Route path="/check-mls" component={MLSPage} />
      <Route path="/facebook-login" component={FacebookLogin} />
      <Route path="/listing-page" component={ListingLandingPage} />
      <Route path="/privacy-policy" component={PrivacyPolicyPage} />

      {/* Marketer Routes */}
      <Route path="/marketer" component={MarketerContainer} />

      {/* Private Routes */}
      <PrivateRoute path="/verify-mls" component={VerifyMLS} />
      <PrivateRoute path="/dashboard" component={AppContainer} />
      <PrivateRoute exact path="/no-listings" component={AppContainer} />
      <PrivateRoute exact path="/listings" component={AppContainer} />
      <PrivateRoute exact path="/listings/:mlsNum" component={AppContainer} />
      <PrivateRoute exact path="/checkout/:mlsNum" component={AppContainer} />
      <PrivateRoute exact path="/ads" component={AppContainer} />
      <PrivateRoute exact path="/profile" component={AppContainer} />
      {/* https://stackoverflow.com/a/37491381/8460812 */}
      {/* //! 404 ^ */}
    </Switch>
  )
}
