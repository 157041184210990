import React from 'react'
import { createUseStyles } from 'react-jss'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'

import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { blue } from '../assets/colorPalette'

const useStyles = createUseStyles({
  mainContainer: {
    padding: 16
  },
  fullContainer: {
    width: '100%',
    height: '100%'
  },
  paperStyles: {
    padding: 16
  },
  profilePicture: {
    width: 56,
    height: 56
  },
  bold: {
    fontWeight: '500!important'
  },
  marginTop: {
    marginTop: '24px!important'
  },
  iconActionContainer: {
    textAlign: 'end'
  },
  blue: {
    color: blue
  }
})

const MLSCard = ({ mlsPrefix, mlsName, id }) => {
  let classes = useStyles()
  return (
    <Grid item xs={12} className={`${classes.fullContainer}`}>
      <Paper className={classes.mainContainer}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={8}>
            <Grid container direction="column" justify="center" alignItems="flex-start">
              <Grid item xs={12}>
                <Typography variant="h6" className={`${classes.bold} ${classes.blue}`}>
                  {!!mlsPrefix ? mlsPrefix : 'Not Defined'}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">{!!mlsName ? mlsName : 'Not Defined'}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container direction="row" justify="flex-end" alignItems="center">
              <Grid item xs={4} className={classes.iconActionContainer}>
                <IconButton aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </Grid>
              <Grid item xs={4} className={classes.iconActionContainer}>
                <IconButton aria-label="delete" disabled>
                  <EditIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

const Profile = ({ mlsSettings }) => {
  let classes = useStyles()
  let fb_info = JSON.parse(localStorage.getItem('fb_info'))

  return (
    <Grid container spacing={3} className={classes.mainContainer}>
      <Grid item xs={5}>
        <Paper className={classes.paperStyles}>
          <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
            <Grid item xs={12}>
              <Avatar
                className={classes.profilePicture}
                src={
                  fb_info.picture
                    ? fb_info.picture.data.url
                    : 'url(' + require('../assets/images/profile-img.png') + ')'
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.bold}>
                {fb_info.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">{fb_info.email}</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={7}>
        <Grid container direction="row" alignItems="center" spacing={3}>
          <Grid item xs={12}>
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Grid item xs={9}>
                <Typography variant="h5" className={classes.bold}>
                  MLS Agents/Offices
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Button fullWidth variant="contained" color="primary" endIcon={<AddCircleIcon />} disableElevation>
                  Add MLS
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
              <MLSCard mlsPrefix="nwmls" mlsName="New Primary MLS Service" key={123} />
              {!!mlsSettings
                ? mlsSettings.settings.map((mls, i) => (
                    <MLSCard mlsPrefix={mls.mls_prefix} mlsName={mls.mls_name} id={mls.id} key={i} />
                  ))
                : 'Loading...'}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default Profile
