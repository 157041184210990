import React, { useEffect, useState, Fragment, createContext, useCallback } from 'react'
import queryString from 'query-string'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { createUseStyles } from 'react-jss'
import Dialog from '@material-ui/core/Dialog'
import CircularProgress from '@material-ui/core/CircularProgress'
import SwipeableViews from 'react-swipeable-views'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import MuiAlert from '@material-ui/lab/Alert'
import Chip from '@material-ui/core/Chip'
import CancelIcon from '@material-ui/icons/Cancel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
// import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined'
import axios from 'axios'
import Listing from '../../pages/Listing'
import Checkout from '../../pages/Checkout'
import FacebookLogin from '../../pages/FacebokLogin'
import AdCreatedPage from '../../pages/AdCreatedPage'
import FbPageSelect from '../../pages/FbPageSelect'
import CardPreviewListing from '../../pages/CardPreviewListing'

import { marketerBlue, darkestGrey } from '../../assets/colorPalette'

import api from '../../services/api'
import dummyObj from '../../pages/dummyObj'
//* context api
const MarketerAdContext = createContext(undefined)
const MarketerAdDispatchContext = createContext(undefined)

// *theme
// https://material-ui.com/customization/palette/
// https://material-ui.com/styles/advanced/#main-content
// https://material-ui.com/customization/default-theme/?expand-path=$.palette
const theme = createMuiTheme({
  palette: {
    primary: {
      main: marketerBlue,
      contrastText: '#fff'
    }
  },
  typography: {
    fontFamily: `"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: 13
  }
})
// *styles
const useStyles = createUseStyles({
  dialogBuyerSearch: {
    background: '#fff!important',
    padding: 24,
    transition: 'all .3s linear!important',
    height: '600px',
    width: '550px!important',
    maxWidth: '1060px!important'
  },
  dialogMultipleSearch: {
    background: '#fff!important',
    padding: 24,

    transition: 'all .3s linear!important',

    width: '550px!important'
    // maxWidth: '1060px!important',
  },
  dialogPaperDialog: {
    minHeight: '100vh',
    maxHeight: '100vh',
    minWidth: '550px',
    maxWidth: '100vw'
  },

  gridListings: {
    width: '100%'
  },
  tileListings: {
    padding: '10px'
  },
  linkStyleCall: {
    cursor: 'pointer',
    textDecoration: 'none',
    fontSize: '15px!important'
  },
  PaddingdDescription: {
    paddingTop: '20px',
    paddingLeft: '24px',
    paddingRight: '24px'
  },
  PaddingdDescriptionMultiple: {
    paddingTop: '0px',
    paddingLeft: '24px',
    paddingRight: '24px'
  },
  PaddingdTop: {
    paddingTop: '20px'
  },
  PaddingdTopLis: {
    paddingTop: '20px'
    // width:"300px!important"
  },
  PaddingTopBottom: {
    paddingTop: '10px'
  },
  PaddingTopBottomListings: {
    paddingTop: '10px',
    // height: '400px',
    overflowY: 'scroll'
  },
  PaddingdTopListing: {
    paddingTop: '10px'
  },
  PaddingdTopListing2: {
    paddingTop: '20px'
  },
  PaddingdTopListing200: {
    position: 'absolute',
    top: 'calc(100vh - 100px)'
    // paddingTop: '220px',
  },
  PaddingdTopDropDown: {
    paddingTop: '40px'
  },
  PaddingdTopDropDownWithChips: {
    paddingTop: '10px'
  },
  chip: {
    marginTop: 10,
    marginRight: 5
  },
  buttonTopPadding: {
    paddingTop: '20px!important'
  },
  headDisplayRoot: {
    background: '#F4F5F5!important'
  },
  headDisplay: {
    position: 'relative',
    top: '-10px!important'
  },
  roundButton: {
    borderRadius: '25px!important',
    fontWeight: '700!important',
    fontSize: '15px!important',
    width: '175px!important'
  },
  roundButtonContinueOpa: {
    borderRadius: '25px!important',
    fontWeight: '700!important',
    fontSize: '15px!important',
    width: '275px!important',
    opacity: 0.5
  },
  roundButtonContinue: {
    borderRadius: '25px!important',
    fontWeight: '700!important',
    fontSize: '15px!important',
    width: '275px!important',
    opacity: 1
  },
  dialogPaperTall: {
    background: '#fff!important',
    padding: 24,
    paddingLeft: 32,
    paddingRight: 32,
    transition: 'all .3s linear!important',
    height: '870px',
    maxWidth: '1130px!important'
  },
  dialogPaperReadyMade: {
    background: '#fff!important',
    padding: 24,
    paddingLeft: 32,
    paddingRight: 32,
    transition: 'all .3s linear!important',
    height: '830px',
    maxWidth: '1130px!important'
  },
  dialogPaper: {
    background: '#fff!important',
    padding: 24,
    transition: 'all .3s linear!important',
    height: '870px',
    maxWidth: '1060px!important'
  },
  facebookDialogPaper: {
    maxWidth: '500px!important',
    height: '600px!important',
    background: '#fff!important',
    padding: 24,
    transition: 'all .3s linear!important'
  },
  swipeable: {
    height: '100%!important',
    '& > :first-child': {
      height: '100%!important'
    }
  },
  swipeableHideOverflowY: {
    overflow: 'hidden!important'
  },
  buttonStyles: {
    textTransform: 'initial!important'
  },
  headerActionContainer: {
    width: '200px'
  },
  mainContentContainer: {
    marginTop: 90,
    marginBottom: 90,
    width: 444,
    textAlign: 'center'
  },
  selectEmpty: {
    margin: 5,
    minWidth: 100,
    backgroundColor: '#eee'
  },
  MuiInputBase: {
    margin: '5px!important',
    padding: '5px!important'
  },
  selectEmptyLabel: {
    marginLeft: 12,
    minWidth: 90
  },
  mainBackgroundImage: {
    width: '100%',
    height: '100%',
    backgroundImage: 'url(' + require('../../assets/images/marketer-bg.jpg') + ')',
    backgroundSize: 'cover',
    display: 'inline-block',
    filter: 'blur(6px)'
  },
  darkestGrey: {
    color: `${darkestGrey}!important`
  },
  green: {
    color: '#008000'
  },
  placeholder: {
    color: '#aaa'
  }
})

// * API calls
const getFacebookUserTokenStatus = (id, setHook) =>
  api.getFbUserTokenStatus(`/facebook/checkFbUserToken/${id}`, setHook)
const getListing = (mls, mlsNum, setHook) => api.getNoNull(`/listings/mls/${mls}/mlsnum/${mlsNum}`, setHook)
const getSessionStatus = (data, setHook) => api.post('/facebook/external-user/session-status', data, setHook)
const getUserFbPages = (setHook) => api.get(`/facebook/me/pages`, setHook)
const getAdHeader = (type, setHook) => api.get(`/facebook/headlines`, setHook)
// const getAdHeader = (type,setHook) => api.getAutoComplete(`https://facebookads.api.staging.brivitymarketer.com/v1/facebook/headlines`,setHook);
//const getHomeValuePhotos = (type, setHook) => api.get(`/facebook/gallery-images/adtype/${type}`, setHook);
const getUserInfo = (id, setHook) => api.get(`/user/brivity/${id}`, setHook)
const getUserInfoPost = (data, setHook) => api.post('/user/brivity/user-account', data, setHook)
const getComplete = (id, criteria, setHook) =>
  api.getAutoCompleteMultiple(`/external/blueroof/ads/${id}/listing/autocomplete?prefix=${criteria}`, setHook)
const getGalleryPhotosReady = (type, setHook) => api.getReadyMade(`/facebook/gallery-images/adtype/${type}`, setHook)
// const getUser = (id,setHook) => api.getAutoComplete(`https://facebookads.api.staging.brivitymarketer.com/v1/user/brivity/${id}`,setHook);


// * custom components
const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}
const MarketerContainer = ({ location }) => {
  const classes = useStyles()
  const [userSaved, setUserSaved] = useState(null)
  const [listing, setListing] = useState(null)
  const [dialogOpen, setDialogOpen] = useState(true)
  const [listingId, setListingId] = useState(null)
  const [contentIndex, setContentIndex] = useState(2)
  const [userFbAuth, setUserFbAuth] = useState(false)
  const [completedAutoFbAuth, setCompletedAutoFbAuth] = useState(false)
  const [integrationData, setIntegrationData] = useState(null)
  const [userSavedLogin, setUserSavedLogin] = useState(null)
  const [userFbPages, setUserFbPages] = useState(null)
  const [billingInfo, setBillingInfo] = useState(null)
  const [firstTimeLogin, setFirstTimeLogin] = useState(false)
  const [dcFacebookResponse, setDcFacebookResponse] = useState(null)
  const [currentHeight, setCurrentHeight] = useState(0)
  const [justLogged, setJustLogged] = useState(false)
  const [readyMadePhotoListcpy, setReadyMadePhotoListcpy] = useState(null)
  const [headerList, setHeaderList] = useState({})
  let [homeValuePhotoList, setHomeValuePhotoList] = useState({})
  const [locationAuto, setLocationAuto] = React.useState(null)
  const [inputValue, setInputValue] = React.useState('')
  const [locationObj, setLocationObj] = React.useState(null)
  const [searchResponse, setSearchResponse] = useState(null)
  const [userInfo, setUserInfo] = useState(null)
  const [userInfoTemp, setUserInfoTemp] = useState(null)
  const [userComplete, setUserComplete] = useState({})
  const [minPrice, setMinPrice] = React.useState(null)
  const [maxPrice, setMaxPrice] = React.useState(null)
  const [minBed, setMinBed] = React.useState(null)
  const [maxBed, setMaxBed] = React.useState(null)
  const [minBath, setMinBath] = React.useState(null)
  const [maxBath, setMaxBath] = React.useState(null)
  const [fromBuyerSearch, setFromBuyerSearch] = React.useState(false)
  const [fromMultipleLis, setFromMultipleLis] = React.useState(false)
  const [dataFacebook, setDataFacebook] = React.useState(null)
  const [errorSnackbar, setErrorSnackbar] = React.useState(false)
  const [snackbarMessage, setSnackbarMessage] = React.useState(null)
  const [localSelected, setLocalSelected] = useState([])
  const [selectedItem, setSelectedItem] = useState([])
  const [facebookUserTokenStatus, setFacebookUserTokenStatus] = useState(null)

  const [adSeed, setAdSeed] = useState()

  const getAdSeed = (adId, setHook) => {
    const handleData = (response) => {
      if (response && response.data) {
        setHook(response.data)
      }
    }
    
    api.get(`/facebook/marketeer/videos/${adId}`, handleData)
  }
  

  const isSelected = (objSel) => {
    const array = [...localSelected]
    if (array.indexOf(objSel) !== -1) return true
    else return false
  }
  const removeFromSelected = (objSel) => {
    const array = [...localSelected]

    var index = array.indexOf(objSel)
    if (index !== -1) {
      array.splice(index, 1)
      setLocalSelected(array)
    }
  }
  const selectedHandler = (objSel) => (event) => {
    if (localSelected.length < 10) {
      if (!isSelected(objSel)) {
        setLocalSelected((localSelected) => [...localSelected, objSel])
        console.log('added: ', objSel)
      } else {
        console.log('Already on list: ', localSelected.indexOf(objSel))
      }
      // } else {
      //   if (localSelected.length > 2) {
      //     removeFromSelected(objSel);
      //     DEBUG_MODE &&
      //       console.log("Already on list: ", localSelected.indexOf(objSel));
      //   }
      // }
    }

    if (isSelected(objSel) && localSelected.length === 10) {
      removeFromSelected(objSel)

      console.log('Already on list: ', localSelected.indexOf(objSel))
    }
  }

  const handleErrorSnackbarClose = () => {
    setErrorSnackbar(false)
  }
  const Placeholder = ({ children }) => {
    // const classes = usePlaceholderStyles();

    return <div className={classes.placeholder}>{children}</div>
  }

  const handleDelete = (chipToDelete) => () => {
    setSelectedItem((chips) =>
      chips.filter((chip) => chip.value !== chipToDelete.value || chip.category !== chipToDelete.category)
    )
  }
  const handleChange = (event, newValue) => {
    if (newValue.category !== '' && handleCheck(newValue) === false) {
      setSelectedItem([newValue, ...selectedItem])
    }
    setLocationAuto('')
    setInputValue('')
  }
  const handleCheck = (val) => {
    return selectedItem.some((item) => val.value === item.value && val.category === item.category)
  }
  let raw = window.location.href

  let queries = queryString.parse(raw.slice(raw.indexOf('?'), raw.length))

  // *context hook
  const [adDetails, setAdDetails] = useState({
    ad_duration: 14,
    ad_daily_budget: 20,
    credit: 0,
    ad_description: null,
    image_index: 0,
    from_listing: true,
    ad_name: 'See all the photos NOW!',
    page_id: null,
    page_name: null,
    page_profile_img: null,
    website: '',
    appBMContext: null,
    brivityUserId: null,
    allowBackButton: false
  })
  // page_id: '108816963922',
  // page_name: 'Blueroof360 / Real Estate Website Provider',
  // page_profile_img: 'https://scontent-sea1-1.xx.fbcdn.net/v/t1.0-1/cp0/c12.12.156.156a/s50x50/969250_10151662021063923_1389367264_n.jpg?_nc_cat=107&_nc_sid=dbb9e7&_nc_ohc=G8UFzFaVqogAX_IUH2g&_nc_ht=scontent-sea1-1.xx&_nc_tp=28&oh=aa6d020aa897ebc3e86fb372d486a742&oe=5FAC7858',

  //* API Calls from Services
  const getDcFacebook = (id, setHook) => api.get(`/facebook/disconnect/acct/${id}`, setHook)
  const dcFacebook = () => {
    getDcFacebook(queries.userId, setDcFacebookResponse)
    setUserFbAuth(false)
    setContentIndex(1)
  }

  const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken'))

  useEffect(() => {
    if (accessToken) getAdSeed(queries.ad_id, setAdSeed)
  }, [queries.ad_id, accessToken])


  //Check Facebook User token status
  useEffect(() => {
    getFacebookUserTokenStatus(queries.userId, setFacebookUserTokenStatus)
  }, [])

  useEffect(() => {
    let { website } = queries
    const sanitizeWebsite = () => {
      if (website) {
        let tempWebsite = null
        if (website.includes('https://')) {
          tempWebsite = website.replace('https://', '')
        } else if (website.includes('http://')) {
          tempWebsite = website.replace('http://', '')
        } else {
          tempWebsite = website
        }
        return tempWebsite
      } else {
        return null
      }
    }

    let sanitized = sanitizeWebsite()
    let bmContext = queries.env === 'ads' ? 'ads' : queries.env === 'beta' ? 'beta' : null

    if (!!sanitized) {
      let newAdDetails = {
        ...adDetails,
        website: sanitized,
        appBMContext: bmContext
      }
      // console.log(sanitized);//RemoveConsole
      setAdDetails(newAdDetails)
      setIntegrationData({
        userId: queries.userId,
        token: queries.token,
        website: sanitized
      })
    } else {
      // console.log("else sanitized:", queries.website);//RemoveConsole
      let newAdDetails = { ...adDetails, appBMContext: bmContext }
      setAdDetails(newAdDetails)
      //console.log("newAdDetails:", newAdDetails);//removeConsole
      setIntegrationData({ userId: queries.userId, token: queries.token })
    }

    // if(queries.adType === "homeValue" || queries.adType === "buyerSearch" || queries.adType === "multiple" || queries.adType === "readyMade") getListing("2", "1722059", handleListing);
    if (
      queries.adType === 'homeValue' ||
      queries.adType === 'buyerSearch' ||
      queries.adType === 'multiple' ||
      queries.adType === 'readyMade'
    ) {
      handleListing(dummyObj) //handleListing(dummyObj);
      // console.log("nerdy aqui:",dummyObj.listing);
    } else getListing(queries.mls, queries.listing, handleListing)

    setListingId(queries.listing)
    initBillingInfo()
    //getUserInfo(queries.userId,setUserInfo);
    //getUserInfoPost({uuid:queries.userId,website:queries.website !== undefined ? queries.website : "",agent_email:queries.agent_email !== undefined ? queries.agent_email : ""},setUserInfoTemp);
    let xs = {
      uuid: queries.userId,
      website: queries.website !== undefined ? queries.website : '',
      agent_email: queries.agent_email !== undefined ? queries.agent_email : ''
    }
    // console.log(xs);
    getUserInfoPost(xs, setUserInfo)
    // getAdHeader("any",setHeaderList);
    // console.log(sessionStorage.getItem('asinopapu'));
    // console.log(createQS());
    //getHomeValuePhotos("homeValue",setHomeValuePhotoList);
    if (
      queries.adType === 'readyMade' ||
      queries.adType === 'homeValue' ||
      queries.adType === 'listed' ||
      queries.adType === 'sold' ||
      queries.adType === 'openHouse' ||
      queries.adType === 'buyerSearch' ||
      queries.adType === 'multiple'
    ) {
      if (!localStorage.getItem('refresh_token') && !localStorage.getItem('access_token')) {
        const data = {
          token: queries.token,
          company_user_id: queries.userId,
          company: 'brivity'
          // email: res.email,
        }
        getSessionStatus(data, setUserSavedLogin)
      }
    }
    // if(queries.adType === "buyerSearch" || queries.adType === "multiple"){
    //   setUserFbAuth(true);
    // }
    if (queries.adType === 'readyMade') {
      if (localStorage.getItem('accessToken') !== null) getGalleryPhotosReady('readyMade', setReadyMadePhotoListcpy)
      // else setContentIndex(1);
    } else {
      setReadyMadePhotoListcpy({ image: 'nothing' })
    }
  }, [])

  useEffect(() => {
    if (userInfoTemp) {
      console.log('userInfoTemp:', userInfoTemp) //RemoveConsole
    }
  }, [userInfoTemp])

  useEffect(() => {
    if (userComplete) {
      console.log('userComplete:', userComplete) //RemoveConsole
    }
  }, [userComplete])

  useEffect(() => {
    if (userSavedLogin) {
      //  console.log("userSavedLogin",userSavedLogin); //RemoveConsole
      if (userSavedLogin.code === 200) {
        localStorage.setItem('accessToken', userSavedLogin.tokens.access_token)
        localStorage.setItem('refreshToken', userSavedLogin.tokens.refresh_token)
        setAccessToken(userSavedLogin.tokens.access_token)
        setUserFbAuth(true)
        setCompletedAutoFbAuth(true)
        getUserFbPages(setUserFbPages)
        if (queries.adType === 'readyMade') getGalleryPhotosReady('readyMade', setReadyMadePhotoListcpy)
        if (
          queries.adType === 'listed' ||
          queries.adType === 'sold' ||
          queries.adType === 'openHouse' ||
          queries.adType === 'homeValue'
        )
          getAdHeader('any', setHeaderList)
      }
    }
  }, [userSavedLogin])
  useEffect(() => {
    if (userSaved === true) {
      // ! TRUE for debugging the facebook login page, default/final behavior is to set userFbAuth to FALSE.
      // if(res.status === 'connected'){
      if (!localStorage.getItem('refresh_token') && !localStorage.getItem('access_token')) {
        window.FB.api('/me?fields=id,name,email', () => {
          const data = {
            token: queries.token,
            company_user_id: queries.userId,
            company: 'brivity'
            // email: res.email,
          }

          getSessionStatus(data, setUserSavedLogin)
        })
      } else {
        setUserFbAuth(true)
        setCompletedAutoFbAuth(true)
      }
      // }
      // ? logic for just checking if the user is logged in should go in this else, with no email (which is currently required) to account for users that have already logged in to the app but are currently trying to use it from another device or browser.
      // else{
      // there's a "login" flow wheere I don't have the email but the api might still have a login, we have to account fo rthi suse case as it would be fairly common
      //   const data = {
      //     token: queries.token,
      //     company_user_id: queries.userId,
      //     company: 'brivity',
      //   };
      //   getSessionStatus(data, setUserSavedLogin);
      // }
      setUserSaved(null)
    }
  }, [userSaved])

  useEffect(() => {
    if (completedAutoFbAuth && userFbAuth && contentIndex === 1) {
      setContentIndex(2)
    }
  }, [completedAutoFbAuth])
  useEffect(() => {
    if (locationObj !== null && locationObj[0]?.value === 'Oops! Something went wrong!') {
      setErrorSnackbar(true)
      setSnackbarMessage('Request failed at: ' + userInfo.user_info.site.site_url)
    }
  }, [locationObj])
  useEffect(() => {
    // console.log({userInfo});
    if (userInfo !== null) {
      // let newAdDetails = { ...adDetails, credit: 80.55};
      let newAdDetails = {
        ...adDetails,
        credit:
          queries.credit_in_cents !== undefined && isNaN(queries.credit_in_cents) !== true
            ? queries?.credit_in_cents / 100
            : 0
      }
      // console.log(sanitized);//RemoveConsole
      setAdDetails(newAdDetails)
      if (userInfo.code !== 200) {
        console.log({ userInfo })
      }
      // console.log({userInfo});
      // let url = "https://facebookads.api.staging.brivitymarketer.com/v1/user/brivity/" + queries.userId;
      // console.log(url);
      //"https://facebookads.api.staging.brivitymarketer.com/v1/user/brivity/brivity-57d951d0-f7f7-4317-bcbc-a48c9768fb76"
      // axios
      // .get(url)
      // .then(res => {
      //   console.log("desde axios:",res);
      //   //this.setState({ dogs: res.data });
      // })
      // .catch(err => console.log(err));
    }
  }, [userInfo])

  useEffect(() => {
    if (headerList.code !== undefined) {
      // console.log({headerList});
    }
  }, [headerList])

  useEffect(() => {
    if (searchResponse !== null) {
      // map((el, i) => {
      //   if (el.main_photo === undefined) return null
      //   return (
      //     <Item img= {`${el.main_photo}`} />

      //   );
      // })
      console.log(searchResponse)
    }
  }, [searchResponse])
  useEffect(() => {
    if (selectedItem.length > 0 && queries.adType !== 'multiple') {
      fetchFromSearchSiteBuilder()
      // console.log("selected");
    }
  }, [selectedItem, minPrice, maxPrice, minBath, maxBath, minBed, maxBed])

  // ? userFbPages on load hook (use?)
  // useEffect(() => {
  //   console.log(userFbPages);
  // }, [userFbPages]);

  function completeDomainUrl(site) {
    site.replace('/', '')
    if (!site.includes('www')) {
      site = `www.${site}`
    }
    return site
  }

  const initBillingInfo = () => {
    let data = {
      address: queries?.billing_address,
      city: queries?.billing_city,
      state: queries?.billing_state,
      zip: queries?.billing_zip,
      firstName: queries?.first_name,
      lastName: queries?.last_name,
      cardType: queries?.card_type,
      cardNumber: queries?.masked_card_number
    }
    setBillingInfo(data)
  }
  const handleListing = (data) => !!data && setListing(data.listing)
  const handleListingAction = () => setContentIndex(userFbAuth ? 3 : 1)
  const handleListingPageAction = () => setContentIndex(userFbAuth ? 2 : 1)
  const resetUserFbPages = () => setUserFbPages(null)
  const fetchUserFbPages = () => getUserFbPages(setUserFbPages)
  const getAutoComplete = (site, mlsids, term, setHook) => {
    const updatedSite = completeDomainUrl(site)

    api.getAutoComplete(`https://${updatedSite}/pages/autocomplete.php?mlsIds=${mlsids}&term=${term}`, setHook)
  }
  const postSearch = (site, data, hook) => {
    const updatedSite = completeDomainUrl(site)

    api.postForm_noToken(`https://${updatedSite}/pages/search.php`, data, hook)
  }
  const handleFbAuthStatus = (res) => {
    // console.log("desde handleFbAuthStatus");
    setUserSaved(true)
  }
  const handleFbOnLogin = (res) => {
    // if(queries.adType === "readyMade"){

    //   setJustLogged(true);
    //   getUserFbPages(setUserFbPages);
    //   setContentIndex(2);
    //   setUserFbAuth(true);
    //   setFirstTimeLogin(true);
    // }
    setJustLogged(true)
    getUserFbPages(setUserFbPages)
    setContentIndex(2)
    setUserFbAuth(true)
    setFirstTimeLogin(true)
  }
  const handleFbOnLoginReadyMade = (res) => {
    // console.log("desde handleFbOnLoginReadyMade");
    // getGalleryPhotosReady("readyMade",setReadyMadePhotoListcpy);
    // getListing("2", "1722059", handleListing);
    // handleListing(dummyObj);
    setJustLogged(true)
    getUserFbPages(setUserFbPages)
    // setContentIndex(2);
    setUserFbAuth(true)
    setFirstTimeLogin(true)
  }
  const handleCancelCheckoutAction = (adCreated) => {
    if (adCreated) {
      const subdomain = ['ads','beta'].includes(adDetails.appBMContext) ? adDetails.appBMContext : 'www'
      window.location.href = `https://${subdomain}.brivitymarketer.com/v2/ads`
    } else {
      setContentIndex(0)
    }
  }
  const handleCancelPageSelectAction = () => {
    setContentIndex(0)
  }
  const handlePageSelectAction = () => {
    setContentIndex(0)
  }
  // ?onAdCreate action
  const handleCreateAdAction = () => {
    // console.log('nice ad');
  }
  // ?onAdCreated action
  const handleAdCreated = (res) => {
    setContentIndex(4)
    // console.log({res});
    // console.log('greetings from marketer');
  }

  const handleHeight = (index) => {
    setCurrentHeight(index)
    // console.log(index);
    // console.log({res});
    // console.log('greetings from marketer');
  }
  const fetchFromSearchSiteBuilder = () => {
    // if(minBed === null && maxBed === null) return null;
    // if(minBath === null && maxBath === null) return null;
    // if(minPrice === null && maxPrice === null) return null;
    if (selectedItem.length === 0) return null
    var search = ''
    var cat = ''
    var ids = ''
    var states = ''
    selectedItem.map((item, index) => {
      search = search + (index ? '|' : '') + item.value
      cat =
        cat +
        (index ? '|' : '') +
        (item.category === 'City'
          ? 'CityState'
          : item.category === 'County'
            ? 'CountyState'
            : item.category === 'Neighborhood'
              ? 'NeighborhoodCityState'
              : item.category)
    })
    userInfo.user_info.site.site_mls.map((item, index) => {
      ids = ids + (index ? '|' : '') + item.mls_id
      states = states + (index ? ',' : '') + item.abbr
    })

    // console.log("cama:",minBed,maxBed);
    // console.log(selectedItem);
    let data = {
      // mlsId:"101|590",
      // mlsId:queries.mls,
      mlsId: ids,

      // multi_search:"Aberdeen,%20WA",
      // multi_search:selectedItem[0].value,

      multi_search: search,
      // multi_cat:selectedItem[0].category === "City" ? "CityState" : selectedItem[0].category === "County" ? "CountyState" : selectedItem[0].category === "Neighborhood" ? "NeighborhoodCityState" : selectedItem[0].category ,
      multi_cat: cat,
      // multi_cat:"CityState",
      // states:"UT,WA",
      states: states,
      // price: (minPrice === null ? "1000": minPrice) + ":" + (maxPrice === null ? "": maxPrice),
      // bedrooms:(minBed === null ? "1": minBed) + ":" + (maxBed === null ? "": maxBed),
      // totalBaths:(minBath === null ? "1": minBath) + ":" + (maxBath === null ? "": maxBath),
      minPrice: minPrice,
      maxPrice: maxPrice,
      minBed: minBed,
      maxBed: maxBed,
      minBath: minBath,
      maxBath: maxBath,
      bedrooms: minBed,
      totalBaths: minBath,
      get_polygon: true
    }

    // data = {...data, price: "1000:"};
    // data = {...data, bedrooms: "1:5"};
    // data = {...data, totalBaths: ":5"};
    // console.log({userInfo});
    // console.log(ids);
    // console.log(data);
    postSearch(userInfo.user_info.site.site_url, data, setSearchResponse)
    // console.log("locations:", selectedItem);
    // console.log("Price range:",minPrice,maxPrice);
    // console.log("Beds:",minBed,maxBed);
    // console.log("Bath:",minBath,maxBath);
  }
  const handleCloseAdTool = useCallback(() => {
    // const enviroment = window.location.hostname === 'listings.ui.production.brivitymarketer.com' ? 'prod' : 'beta';
    if (window.location.hostname === 'facebooktool.brivitymarketer.com') {
      window.location.href = 'https://brivitymarketer.com/v2/dashboard'
      return null
    }
    if (adDetails.appBMContext === 'ads') {
      window.location.href = 'https://ads.brivitymarketer.com/v2/dashboard'
      return null
    }

    if (
      window.location.hostname === 'listings.staging.brivitymarketer.com' ||
      window.location.hostname === 'localhost'
    ) {
      window.location.href = 'https://beta.brivitymarketer.com/v2/dashboard'
      return null
    }

    if (adDetails.appBMContext === 'beta') {
      window.location.href = 'https://beta.brivitymarketer.com/v2/dashboard'
    } else {
      window.location.href = 'https://brivitymarketer.com/v2/dashboard'
    }
  }, [])
  const handleCreateMultipleAd = () => {
    if (selectedItem.length < 3) {
      console.log('selectedItem is Null', selectedItem)
      setErrorSnackbar(true)
      setSnackbarMessage('At least 3 listings must be selected')
      return null
    }
    setFromMultipleLis(true)
  }
  const handleCreateBuyerSearchAd = () => {
    // map((el, i) => {
    //   if (el.main_photo === undefined) return null
    //   return (
    //     <Item img= {`${el.main_photo}`} />

    //   );
    // })

    // console.log(x,selectedItem);

    if (searchResponse === null || searchResponse.data === undefined) {
      console.log('searchResponse is Null')
      return null
    }
    console.log()
    if (searchResponse.total < 3) {
      console.log('total of listings found must be greater than 3')
      setErrorSnackbar(true)
      setSnackbarMessage('The total of listings found must be equal or greater than 3')
      return null
    } //carousel and 0 listing error
    // if(searchResponse !== null && searchResponse.data !== undefined)
    var x = selectedItem.map((i) => {
      return {
        multiCat:
          i.category === 'City'
            ? 'CityState'
            : i.category === 'County'
              ? 'CountyState'
              : i.category === 'Neighborhood'
                ? 'NeighborhoodCityState'
                : i.category,
        address: i.value,
        priceMin: parseInt(minPrice) || 0,
        priceMax: parseInt(maxPrice) || 0,
        bedMin: parseInt(minBed) || 0,
        bedMax: parseInt(maxBed) || 0,
        bathMin: parseInt(minBath) || 0,
        bathMax: parseInt(maxBath) || 0
      }
    })
    setSearchResponse({ ...searchResponse, searchOptions: x })

    setFromBuyerSearch(true)
  }
  const askServerAutoComplete = (event, value) => {
    setInputValue(value)

    var ids = ''
    if (
      userInfo.user_info.site.site_mls === undefined ||
      userInfo.user_info.site.site_mls === null ||
      userInfo.user_info.site.site_mls.length === 0
    ) {
      console.log('no userInfo.user_info.site.site_mls found on client', userInfo)
      setErrorSnackbar(true)
      setSnackbarMessage(
        'Website does not have a valid mls number, please contact support or add valid mls to your website'
      )
      return null
    }
    if (userInfo.user_info.site.id === 0 || userInfo.user_info.site.site_url === '') {
      console.log('no userInfo.user_info.site.id found on client', userInfo)
      setErrorSnackbar(true)
      setSnackbarMessage('Account website not found')
      return null
    }
    // if (userInfo.user_info.user.id === 0){
    //   console.log("no userInfo.user_info.user.id found on client",userInfo);
    //   setErrorSnackbar(true);
    //   setSnackbarMessage("No user ID found");
    //   return null;
    // }
    if (userInfo.user_info.site.site_mls === null) {
      console.log('no userInfo.user_info.site.site_mls found on client', userInfo)
      setErrorSnackbar(true)
      setSnackbarMessage(
        'Website does not have a valid mls number, please contact support or add valid mls to your website'
      )
      return null
    }

    userInfo.user_info.site.site_mls.map((item, index) => {
      ids = ids + (index ? '|' : '') + item.mls_id
    })

    if (value !== '' && value !== ' ') {
      // getAutoComplete(userInfo.user_info.site.site_url, ids,value,setLocationObj);
      getAutoComplete(userInfo.user_info.site.site_url, ids, value, setLocationObj)
      //postSearch(userInfo.user_info.site.site_url,{"a":"a"},setSearchResponse);
      // console.log(value);
    } else {
      setLocationObj(null)
      setLocationAuto('')
      setInputValue('')
    }
  }
  const createQS = () => {
    // let adType = null;
    // listingItem.standardStatus === 'Closed'
    //   ? (adType = { adType: 'sold' })
    //   : (adType = { adType: 'listed' });
    // let params = { ...listingDetails.adProduct.qs, ...adType };
    if (selectedItem.length === 0) return null
    var search = ''
    var cat = ''
    var ids = ''
    var states = ''
    selectedItem.map((item, index) => {
      search = search + (index ? '|' : '') + item.value
      cat =
        cat +
        (index ? '|' : '') +
        (item.category === 'City'
          ? 'CityState'
          : item.category === 'County'
            ? 'CountyState'
            : item.category === 'Neighborhood'
              ? 'NeighborhoodCityState'
              : item.category)
    })
    userInfo.user_info.site.site_mls.map((item, index) => {
      ids = ids + (index ? '|' : '') + item.mls_id
      states = states + (index ? ',' : '') + item.abbr
    })
    let mBed = minBed === null ? '' : minBed
    let maBed = maxBed === null ? '' : maxBed
    let mBath = minBath === null ? '' : minBath
    let maBath = maxBath === null ? '' : maxBath
    let mPrice = minPrice === null ? '' : minPrice
    let maPrice = maxPrice === null ? '' : maxPrice
    let params = {
      q_limit: '36',
      q_prioritize: 'agents.0.id=,,1234|office.id=9978,,123',
      mlsId: ids,
      price: mPrice + ':' + maPrice,
      bedrooms: mBed + ':' + maBed,
      totalBaths: mBath + ':' + maBath,
      multi_search: search,
      multi_cat: cat,
      status: '1|3',
      q_sort: 'featured',
      q_offset: '0'
    }
    // params.listing = item.mlsNum;
    // params.mls = item.blueroofMlsId;
    console.log(params)
    return Object.keys(params)
      .map((param) => `${param}=${params[param.replace('#', '%23')]}`)
      .join('&')
  }

  const handleDeleteM = (chipToDelete) => () => {
    setSelectedItem((chips) => chips.filter((chip) => chip.listing.blossorId !== chipToDelete.listing.blossorId))
  }

  const handleCheckM = (val) => {
    return selectedItem.some((item) => val.listing.blossorId === item.listing.blossorId)
  }

  const handleChangeMultiple = (event, newValue) => {
    setInputValue('')
    if (newValue.listing?.blossorId === undefined || newValue.listing?.blossorId === null) {
      setErrorSnackbar(true)
      setSnackbarMessage('Listing has no blossorId')
      return null
    }
    if (handleCheckM(newValue) === false && selectedItem.length < 7) {
      const items = [...selectedItem]
      items.push(newValue)
      setSelectedItem(items)
    }
    // setLocationAuto('');
  }
  const askServerMultipleAutoComplete = (event, value) => {
    setInputValue(value)
    // console.log("here:",value);
    getComplete(queries.userId, value, setUserComplete)
  }
  //  <Alert open={true} autoHideDuration={null} onClose={() =>  console.log('you closed me')} severity="error">
  //         We couldn't create your ad. Please try again later
  //     </Alert>

  const FacebookConnected = () => (
    <>
      <CheckCircleOutlinedIcon style={{ color: 'green', fontSize: '60' }} />
      <br />
      <span style={{ color: 'green' }}>You're connected to your Facebook account!</span>
      <p />
      <Button
        variant="outlined"
        startIcon={<ArrowBackIcon />}
        // style={{ color: 'green' }}
        color="primary"
        size="small"
        className={classes.roundButtonContinue}
        onClick={() => setContentIndex(2)}
        // disableElevation
      >
        Return to ad settings
      </Button>
    </>
  )

  return (
    <ThemeProvider theme={theme}>
      <div style={{ width: '100vw', height: '100vh' }}>
        <div className={classes.mainBackgroundImage} />
        {/* https://stackoverflow.com/questions/50649408/how-to-use-material-ui-dialog-paperprops */}
        {queries.adType === 'multiple' && fromMultipleLis === false ? (
          <Dialog
            open={dialogOpen}
            maxWidth={contentIndex === 1 ? 'xs' : 'lg'}
            classes={{ paper: classes.dialogPaperDialog }}
            PaperProps={{ classes: { root: classes.dialogMultipleSearch } }}
            style={{
              position: 'absolute',
              left: 'calc(100vw - 550px)',
              height: '100%!important'
            }}
          >
            {userInfo === null || headerList === null ? (
              <div className={classes.mainContentContainer}>
                <CircularProgress color="primary" size={75} />
              </div>
            ) : (
              <Grid container spacing={16}>
                <Snackbar
                  open={errorSnackbar}
                  autoHideDuration={null}
                  onClose={handleErrorSnackbarClose}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  className={classes.snackbarComponent}
                >
                  <Alert onClose={handleErrorSnackbarClose} severity="error">
                    <span>{snackbarMessage}</span>
                  </Alert>
                </Snackbar>
                <Grid className={classes.PaddingdTop} container justify="center" item xs={12}>
                  <Typography
                    id="modal-content"
                    style={{
                      fontFamily: 'Open Sans',
                      color: 'grey',
                      fontWeight: 'bolder',
                      fontSize: '17px',
                      marginLeft: 50
                    }}
                  >
                    Import Listing Data
                  </Typography>
                  <IconButton
                    style={{
                      left: '60px',
                      bottom: '10px'
                    }}
                    onClick={handleCloseAdTool}
                    aria-label="close ad creation modal"
                  >
                    <CloseIcon />
                  </IconButton>

                  {/* <span>
                  Buyer Search
                </span> */}
                  {/* <DialogTitle id="form-dialog-title">Buyer Search</DialogTitle> */}
                </Grid>

                <Grid container justify="center" item xs={12} className={classes.PaddingdDescriptionMultiple}>
                  <Typography
                    id="modal-content-2"
                    style={{
                      fontFamily: 'Open Sans',
                      color: 'grey',
                      fontWeight: 'normal',
                      fontSize: '13px',
                      marginLeft: 0
                      // position: "absolute",
                      // left: "calc(50% - 424px/2 - 9px)",
                      // top: "198px",
                    }}
                  >
                    Search for listings to populate your template with dynamic data
                  </Typography>

                  <Grid container justify="center" item xs={12} className={classes.PaddingdTopLis}>
                    <Autocomplete
                      disableClearable
                      freeSolo
                      disabled={selectedItem.length > 7 ? true : false}
                      id="combo-box-location"
                      closeIcon={<SearchIcon fontSize="small" />}
                      classes={{
                        input: classes.headDisplay,

                        inputRoot: classes.headDisplayRoot
                      }}
                      options={userComplete.results !== undefined ? userComplete.results : [{ text: 'No options' }]}
                      // groupBy={(option) => option.category}
                      getOptionLabel={(option) => option.text}
                      value={locationAuto}
                      noOptionsText={'No options'}
                      inputValue={inputValue}
                      onChange={handleChangeMultiple}
                      onInputChange={askServerMultipleAutoComplete}
                      style={{
                        width: '370px',
                        height: '40px',
                        marginLeft: '25px'
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          placeholder={
                            selectedItem.length > 7 ? 'Max listings added' : 'Search by address or MLS number'
                          }
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true
                          }}
                        />
                      )}
                    />
                    <div
                      style={{
                        position: 'relative',
                        left: '-40px',
                        top: '13px'
                      }}
                    >
                      <SearchIcon fontSize="large" />
                    </div>
                    <Typography
                      id="modal-content-3"
                      style={{
                        fontFamily: 'Open Sans',
                        color: '#B8B8B8',
                        fontWeight: 'bold',
                        fontSize: '15px',
                        marginLeft: '10px',
                        width: '330px',
                        paddingBottom: '10px',
                        paddingTop: '30px'
                        // position: "absolute",
                        // left: "calc(50% - 424px/2 - 9px)",
                        // top: "198px",
                      }}
                    >
                      Supports a maximum of 7 listings:{' '}
                      <span
                        id="modals-3"
                        style={{
                          fontFamily: 'Open Sans',
                          color: selectedItem.length > 0 ? '#59C4C4' : '#F2714D',
                          fontWeight: 'bold',
                          fontSize: '18px',
                          marginLeft: 0,
                          width: '320px',
                          paddingBottom: '10px',
                          paddingTop: '30px'
                          // position: "absolute",
                          // left: "calc(50% - 424px/2 - 9px)",
                          // top: "198px",
                        }}
                      >
                        {selectedItem.length}{' '}
                      </span>{' '}
                      <span
                        id="modals-3"
                        style={{
                          fontFamily: 'Open Sans',
                          color: selectedItem.length > 9 ? 'RGB(89,196,196)' : 'grey',
                          fontWeight: 'bold',
                          fontSize: '20px',
                          marginLeft: 0,
                          width: '320px',
                          paddingBottom: '10px',
                          paddingTop: '30px'
                          // position: "absolute",
                          // left: "calc(50% - 424px/2 - 9px)",
                          // top: "198px",
                        }}
                      >
                        / 7
                      </span>
                    </Typography>
                  </Grid>
                  {/* //TODO: HARDCODE 2 OR LESS */}
                  <GridList
                    cellHeight={180}
                    style={{
                      overflowY: selectedItem.length > 2 ? 'scroll' : 'hidden',
                      width: '320px',
                      height: selectedItem.length > 2 ? '550px' : '350px'
                    }}
                    cols={1}
                    rows={2}
                    justify="flex-start"
                    className={classes.gridListings}
                  >
                    {selectedItem.length > 0 &&
                      selectedItem.map((item, index) => (
                        <GridListTile key={`${item.text}-${index}`}>
                          <CardPreviewListing listing={item} setListing={handleDeleteM} />
                        </GridListTile>
                      ))}

                    {/* <GridListTile key={"tile.img2"} style={{ height: "190px!important",paddingBottom: "10px!important" }}>
                        <CardPreviewListing listing = {exampleObj} setListing={handleDeleteM}/>
                      </GridListTile>
                      <GridListTile key={"tile.img2"} style={{ height: "190px!important",paddingBottom: "10px!important" }}>
                        <CardPreviewListing listing = {exampleObj} setListing={handleDeleteM}/>
                      </GridListTile> */}
                  </GridList>

                  {/* <Grid
                  container
                  justify="left"
                  item
                  xs={12}
                  className={classes.PaddingTopBottomListings}
                >
                  
                  <CardPreviewListing listing = {exampleObj} />
                  <CardPreviewListing listing = {exampleObj} />
                  <CardPreviewListing listing = {exampleObj} />
                  <CardPreviewListing listing = {exampleObj} />

                  </Grid> */}

                  {/* <DialogTitle id="form-dialog-title">Buyer Search</DialogTitle> */}

                  <Grid //classes.PaddingdTopDropDown
                    className={selectedItem.length > 2 ? classes.PaddingdTopListing2 : classes.PaddingdTopListing200}
                    container
                    justify="center"
                    item
                    xs={12}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      className={selectedItem.length > 0 ? classes.roundButtonContinue : classes.roundButtonContinueOpa}
                      onClick={handleCreateMultipleAd}
                      disableElevation
                    >
                      CONTINUE
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Dialog>
        ) : queries.adType === 'buyerSearch' && fromBuyerSearch === false ? (
          <Dialog
            open={dialogOpen}
            maxWidth={contentIndex === 1 ? 'xs' : 'lg'}
            PaperProps={{ classes: { root: classes.dialogBuyerSearch } }}
            style={{ height: '200px!important' }}
          >
            {userInfo === null || headerList === null ? (
              <div className={classes.mainContentContainer}>
                <CircularProgress color="primary" size={75} />
              </div>
            ) : (
              <Grid container spacing={16}>
                <Snackbar
                  open={errorSnackbar}
                  autoHideDuration={null}
                  onClose={handleErrorSnackbarClose}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  className={classes.snackbarComponent}
                >
                  <Alert onClose={handleErrorSnackbarClose} severity="error">
                    <span>{snackbarMessage}</span>
                  </Alert>
                </Snackbar>
                <Grid className={classes.PaddingdTop} container justify="center" item xs={12}>
                  <Typography
                    id="modal-content"
                    style={{
                      fontFamily: 'Open Sans',
                      color: 'grey',
                      fontWeight: 'bolder',
                      fontSize: '17px',
                      marginLeft: 0
                    }}
                  >
                    Customize your search results
                  </Typography>
                  <IconButton
                    style={{
                      left: '100px',
                      bottom: '10px'
                    }}
                    onClick={handleCloseAdTool}
                    aria-label="close ad creation modal"
                  >
                    <CloseIcon />
                  </IconButton>

                  {/* <span>
                  Buyer Search
                </span> */}
                  {/* <DialogTitle id="form-dialog-title">Buyer Search</DialogTitle> */}
                </Grid>

                <Grid container justify="center" item xs={12} className={classes.PaddingdDescription}>
                  <Typography
                    id="modal-content-2"
                    style={{
                      fontFamily: 'Open Sans',
                      color: 'grey',
                      fontWeight: 'normal',
                      fontSize: '13px',
                      marginLeft: 0
                      // position: "absolute",
                      // left: "calc(50% - 424px/2 - 9px)",
                      // top: "198px",
                    }}
                  >
                    Your buyer search ad will direct leads to your IDX website’s search page. Customize your search
                    results here so that the listings your leads are directed to reflect your target audience.
                  </Typography>

                  <Grid container justify="left" item xs={12} className={classes.PaddingdTop}>
                    <Typography
                      id="modal-content-3"
                      style={{
                        fontFamily: 'Open Sans',
                        color: 'grey',
                        fontWeight: 'bold',
                        fontSize: '13px',
                        marginLeft: 0,
                        width: '100%'
                        // position: "absolute",
                        // left: "calc(50% - 424px/2 - 9px)",
                        // top: "198px",
                      }}
                    >
                      Location(s)
                    </Typography>
                    <Autocomplete
                      disableClearable
                      id="combo-box-location"
                      closeIcon={<SearchIcon fontSize="small" />}
                      classes={{
                        input: classes.headDisplay
                      }}
                      options={
                        locationObj !== null
                          ? locationObj
                          : [
                              {
                                category: '',
                                value: 'Search by city, county, neighborhood, or address'
                              }
                            ]
                      }
                      groupBy={(option) => option.category}
                      getOptionLabel={(option) => option?.value?.toString()}
                      value={locationAuto}
                      noOptionsText={'No options'}
                      inputValue={inputValue}
                      onChange={handleChange}
                      onInputChange={askServerAutoComplete}
                      style={{ width: '100%', height: '40px' }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          placeholder="Search by city, county, neighborhood, or address"
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid container justify="left" item xs={12} className={classes.PaddingTopBottom}>
                    {selectedItem.length > 0 &&
                      selectedItem.map((item, index) => (
                        <Chip
                          key={`${item.value}-${index}`}
                          className={classes.chip}
                          label={item.label}
                          deleteIcon={<CancelIcon />}
                          onDelete={handleDelete(item)}
                          // onDelete={() => onRemoveItem(item)}
                          // onClick={() => onRemoveItem(item)}
                        />
                      ))}
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    className={
                      selectedItem.length === 0 ? classes.PaddingdTopDropDown : classes.PaddingdTopDropDownWithChips
                    }
                  >
                    <Typography
                      id="modal-content-3"
                      style={{
                        fontFamily: 'Open Sans',
                        color: 'grey',
                        fontWeight: 'bold',
                        fontSize: '13px',
                        marginLeft: 0,
                        width: '100%'
                        // position: "absolute",
                        // left: "calc(50% - 424px/2 - 9px)",
                        // top: "198px",
                      }}
                    >
                      Price
                    </Typography>
                    <FormControl className={classes.formControl}>
                      {/* <InputLabel id="demo-mutiple-name-label" className={classes.selectEmptyLabel}>No Min</InputLabel> */}
                      <Select
                        value={minPrice}
                        displayEmpty
                        onChange={(event) => setMinPrice(event.target.value)}
                        className={classes.selectEmpty}
                        classes={{
                          root: classes.MuiInputBase
                        }}
                        renderValue={minPrice !== null ? undefined : () => <Placeholder>No Min</Placeholder>}
                      >
                        <MenuItem value={null}>No Min</MenuItem>
                        <MenuItem value={'1000'}>$1,000</MenuItem>
                        <MenuItem value={'2000'}>$2,000</MenuItem>
                        <MenuItem value={'3000'}>$3,000</MenuItem>
                        <MenuItem value={'4000'}>$4,000</MenuItem>
                        <MenuItem value={'5000'}>$5,000</MenuItem>
                        <MenuItem value={'10000'}>$10,000</MenuItem>
                        <MenuItem value={'15000'}>$15,000</MenuItem>
                        <MenuItem value={'25000'}>$25,000</MenuItem>
                        <MenuItem value={'50000'}>$50,000</MenuItem>
                        <MenuItem value={'60000'}>$60,000</MenuItem>
                        <MenuItem value={'70000'}>$70,000</MenuItem>
                        <MenuItem value={'80000'}>$80,000</MenuItem>
                        <MenuItem value={'90000'}>$90,000</MenuItem>
                        <MenuItem value={'100000'}>$100,000</MenuItem>
                        <MenuItem value={'125000'}>$125,000</MenuItem>
                        <MenuItem value={'150000'}>$150,000</MenuItem>
                        <MenuItem value={'175000'}>$175,000</MenuItem>
                        <MenuItem value={'200000'}>$200,000</MenuItem>
                        <MenuItem value={'225000'}>$225,000</MenuItem>
                        <MenuItem value={'250000'}>$250,000</MenuItem>
                        <MenuItem value={'275000'}>$275,000</MenuItem>
                        <MenuItem value={'300000'}>$300,000</MenuItem>
                        <MenuItem value={'325000'}>$325,000</MenuItem>
                        <MenuItem value={'350000'}>$350,000</MenuItem>
                        <MenuItem value={'375000'}>$375,000</MenuItem>
                        <MenuItem value={'400000'}>$400,000</MenuItem>
                        <MenuItem value={'425000'}>$425,000</MenuItem>
                        <MenuItem value={'450000'}>$450,000</MenuItem>
                        <MenuItem value={'475000'}>$475,000</MenuItem>
                        <MenuItem value={'500000'}>$500,000</MenuItem>
                        <MenuItem value={'550000'}>$550,000</MenuItem>
                        <MenuItem value={'600000'}>$600,000</MenuItem>
                        <MenuItem value={'650000'}>$650,000</MenuItem>
                        <MenuItem value={'700000'}>$700,000</MenuItem>
                        <MenuItem value={'750000'}>$750,000</MenuItem>
                        <MenuItem value={'800000'}>$800,000</MenuItem>
                        <MenuItem value={'850000'}>$850,000</MenuItem>
                        <MenuItem value={'900000'}>$900,000</MenuItem>
                        <MenuItem value={'950000'}>$950,000</MenuItem>
                        <MenuItem value={'1000000'}>$1,000,000</MenuItem>
                        <MenuItem value={'1250000'}>$1,250,000</MenuItem>
                        <MenuItem value={'1500000'}>$1,500,000</MenuItem>
                        <MenuItem value={'1750000'}>$1,750,000</MenuItem>
                        <MenuItem value={'2000000'}>$2,000,000</MenuItem>
                        <MenuItem value={'2250000'}>$2,250,000</MenuItem>
                        <MenuItem value={'2500000'}>$2,500,000</MenuItem>
                        <MenuItem value={'2750000'}>$2,750,000</MenuItem>
                        <MenuItem value={'3000000'}>$3,000,000</MenuItem>
                        <MenuItem value={'4000000'}>$4,000,000</MenuItem>
                        <MenuItem value={'4500000'}>$4,500,000</MenuItem>
                        <MenuItem value={'5000000'}>$5,000,000</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      {/* <InputLabel id="demo-mutiple-name-label" className={classes.selectEmptyLabel}>No Max</InputLabel> */}
                      <Select
                        value={maxPrice}
                        displayEmpty
                        onChange={(event) => setMaxPrice(event.target.value)}
                        className={classes.selectEmpty}
                        classes={{
                          root: classes.MuiInputBase
                        }}
                        renderValue={maxPrice !== null ? undefined : () => <Placeholder>No Max</Placeholder>}
                      >
                        <MenuItem value={null}>No Max</MenuItem>
                        <MenuItem value={'1000'}>$1,000</MenuItem>
                        <MenuItem value={'2000'}>$2,000</MenuItem>
                        <MenuItem value={'3000'}>$3,000</MenuItem>
                        <MenuItem value={'4000'}>$4,000</MenuItem>
                        <MenuItem value={'5000'}>$5,000</MenuItem>
                        <MenuItem value={'10000'}>$10,000</MenuItem>
                        <MenuItem value={'15000'}>$15,000</MenuItem>
                        <MenuItem value={'25000'}>$25,000</MenuItem>
                        <MenuItem value={'50000'}>$50,000</MenuItem>
                        <MenuItem value={'60000'}>$60,000</MenuItem>
                        <MenuItem value={'70000'}>$70,000</MenuItem>
                        <MenuItem value={'80000'}>$80,000</MenuItem>
                        <MenuItem value={'90000'}>$90,000</MenuItem>
                        <MenuItem value={'100000'}>$100,000</MenuItem>
                        <MenuItem value={'125000'}>$125,000</MenuItem>
                        <MenuItem value={'150000'}>$150,000</MenuItem>
                        <MenuItem value={'175000'}>$175,000</MenuItem>
                        <MenuItem value={'200000'}>$200,000</MenuItem>
                        <MenuItem value={'225000'}>$225,000</MenuItem>
                        <MenuItem value={'250000'}>$250,000</MenuItem>
                        <MenuItem value={'275000'}>$275,000</MenuItem>
                        <MenuItem value={'300000'}>$300,000</MenuItem>
                        <MenuItem value={'325000'}>$325,000</MenuItem>
                        <MenuItem value={'350000'}>$350,000</MenuItem>
                        <MenuItem value={'375000'}>$375,000</MenuItem>
                        <MenuItem value={'400000'}>$400,000</MenuItem>
                        <MenuItem value={'425000'}>$425,000</MenuItem>
                        <MenuItem value={'450000'}>$450,000</MenuItem>
                        <MenuItem value={'475000'}>$475,000</MenuItem>
                        <MenuItem value={'500000'}>$500,000</MenuItem>
                        <MenuItem value={'550000'}>$550,000</MenuItem>
                        <MenuItem value={'600000'}>$600,000</MenuItem>
                        <MenuItem value={'650000'}>$650,000</MenuItem>
                        <MenuItem value={'700000'}>$700,000</MenuItem>
                        <MenuItem value={'750000'}>$750,000</MenuItem>
                        <MenuItem value={'800000'}>$800,000</MenuItem>
                        <MenuItem value={'850000'}>$850,000</MenuItem>
                        <MenuItem value={'900000'}>$900,000</MenuItem>
                        <MenuItem value={'950000'}>$950,000</MenuItem>
                        <MenuItem value={'1000000'}>$1,000,000</MenuItem>
                        <MenuItem value={'1250000'}>$1,250,000</MenuItem>
                        <MenuItem value={'1500000'}>$1,500,000</MenuItem>
                        <MenuItem value={'1750000'}>$1,750,000</MenuItem>
                        <MenuItem value={'2000000'}>$2,000,000</MenuItem>
                        <MenuItem value={'2250000'}>$2,250,000</MenuItem>
                        <MenuItem value={'2500000'}>$2,500,000</MenuItem>
                        <MenuItem value={'2750000'}>$2,750,000</MenuItem>
                        <MenuItem value={'3000000'}>$3,000,000</MenuItem>
                        <MenuItem value={'4000000'}>$4,000,000</MenuItem>
                        <MenuItem value={'4500000'}>$4,500,000</MenuItem>
                        <MenuItem value={'5000000'}>$5,000,000</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} className={classes.PaddingdTopDropDown}></Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={6} className={classes.PaddingdTopDropDown}>
                    <Typography
                      id="modal-content-3"
                      style={{
                        fontFamily: 'Open Sans',
                        color: 'grey',
                        fontWeight: 'bold',
                        fontSize: '13px',
                        marginLeft: 0,
                        width: '100%'
                        // position: "absolute",
                        // left: "calc(50% - 424px/2 - 9px)",
                        // top: "198px",
                      }}
                    >
                      Beds
                    </Typography>
                    <Select
                      value={minBed}
                      displayEmpty
                      onChange={(event) => setMinBed(event.target.value)}
                      className={classes.selectEmpty}
                      classes={{
                        root: classes.MuiInputBase
                      }}
                      renderValue={minBed !== null ? undefined : () => <Placeholder>No Min</Placeholder>}
                    >
                      <MenuItem value={null}>No Min</MenuItem>
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                    </Select>
                    <Select
                      value={maxBed}
                      displayEmpty
                      onChange={(event) => setMaxBed(event.target.value)}
                      className={classes.selectEmpty}
                      classes={{
                        root: classes.MuiInputBase
                      }}
                      renderValue={maxBed !== null ? undefined : () => <Placeholder>No Max</Placeholder>}
                    >
                      <MenuItem value={null}>No Max</MenuItem>
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={6} className={classes.PaddingdTopDropDown}>
                    <Typography
                      id="modal-content-3"
                      style={{
                        fontFamily: 'Open Sans',
                        color: 'grey',
                        fontWeight: 'bold',
                        fontSize: '13px',
                        marginLeft: 0,
                        width: '100%'
                        // position: "absolute",
                        // left: "calc(50% - 424px/2 - 9px)",
                        // top: "198px",
                      }}
                    >
                      Baths
                    </Typography>
                    <Select
                      value={minBath}
                      displayEmpty
                      onChange={(event) => setMinBath(event.target.value)}
                      className={classes.selectEmpty}
                      classes={{
                        root: classes.MuiInputBase
                      }}
                      renderValue={minBath !== null ? undefined : () => <Placeholder>No Min</Placeholder>}
                    >
                      <MenuItem value={null}>No Min</MenuItem>
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                    </Select>
                    <Select
                      value={maxBath}
                      displayEmpty
                      onChange={(event) => setMaxBath(event.target.value)}
                      className={classes.selectEmpty}
                      classes={{
                        root: classes.MuiInputBase
                      }}
                      renderValue={maxBath !== null ? undefined : () => <Placeholder>No Max</Placeholder>}
                    >
                      <MenuItem value={null}>No Max</MenuItem>
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                    </Select>
                  </Grid>

                  {/* <DialogTitle id="form-dialog-title">Buyer Search</DialogTitle> */}

                  <Grid
                    className={classes.PaddingdTopListing} //PaddingdTopListing2
                    container
                    justify="center"
                    item
                    xs={12}
                  >
                    {/* <Typography
                  id="modal-content"
                  style={{
                    fontFamily: "Open Sans",
                    color: "RGB(89,196,196)",
                    fontWeight: "bolder",
                    fontSize: "15px",
                    marginLeft: 0,
                    paddingTop:"15px"
                  }}
                >
                  
                 {(searchResponse !== null && searchResponse !== undefined && searchResponse.total !== undefined) ? searchResponse.total + " Listings Found": " " } 
                </Typography> */}
                    <a
                      style={{
                        fontFamily: 'Open Sans',
                        color: 'RGB(89,196,196)',
                        fontWeight: 'bolder',
                        fontSize: '15px',
                        marginLeft: 0,
                        paddingTop: '15px'
                      }}
                      onClick={() =>
                        window.open('https://' + userInfo.user_info.site.site_url + '/search#' + createQS(), '_blank')
                      }
                    >
                      <span className={`${classes.linkStyleCall}`} style={{ color: theme.palette.primary.main }}>
                        {searchResponse !== null && searchResponse !== undefined && searchResponse.total !== undefined
                          ? searchResponse.total + ' Listings Found'
                          : ' '}
                      </span>
                    </a>

                    {/* <span>
                  Buyer Search
                </span> */}
                    {/* <DialogTitle id="form-dialog-title">Buyer Search</DialogTitle> */}
                  </Grid>

                  <Grid //classes.PaddingdTopDropDown
                    className={
                      searchResponse !== null && searchResponse !== undefined && searchResponse.total !== undefined
                        ? classes.PaddingdTopListing2
                        : classes.PaddingdTopDropDown
                    }
                    container
                    justify="center"
                    item
                    xs={12}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      className={classes.roundButton}
                      onClick={handleCreateBuyerSearchAd}
                      disableElevation
                    >
                      Create Ad
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Dialog>
        ) : (
          <Dialog
            open={dialogOpen}
            maxWidth={contentIndex === 1 ? 'xs' : 'lg'}
            PaperProps={{
              classes: {
                root:
                  contentIndex === 1
                    ? classes.facebookDialogPaper
                    : currentHeight === 0
                      ? classes.dialogPaper
                      : classes.dialogPaperTall // : queries.adType === "readyMade" ? classes.dialogPaperReadyMade : classes.dialogPaperTall,
              }
            }}
            style={{ height: '400px!important' }}
            disableEnforceFocus
          >
            {contentIndex === 1 && (
              <Fragment>
                <DialogTitle id="form-dialog-title">Facebook Authentication</DialogTitle>
                <Typography className={classes.darkestGrey} style={{ paddingLeft: 24 }}>
                  <Box
                    color="darkestGrey"
                    bgcolor="#FFFADD"
                    p={1}
                    style={{
                      borderRadius: 15,
                      border: '1px solid',
                      borderColor: '#E0E0E0',
                      padding: 15
                    }}
                  >
                    You may need to temporarily turn off any pop up blocking software for this website in order to
                    proceed
                  </Box>
                </Typography>
              </Fragment>
            )}
            {listing === null ||
            userInfo === null ||
            headerList === null ||
            readyMadePhotoListcpy === null ||
            !userFbAuth ? (
              <div className={classes.mainContentContainer}>
                {listing === null && userFbAuth ? (
                  <>
                    <Typography variant="h4" className={classes.title} style={{ paddingBottom: '10px' }}>
                      No listing data found.
                    </Typography>
                    <Typography variant="h4" className={classes.title}>
                      Please contact support if this error continues to occur.
                    </Typography>

                    <Button
                      style={{ marginTop: '30px' }}
                      variant="contained"
                      color="primary"
                      onClick={handleCloseAdTool}
                    >
                      Back to Brivity Marketer
                    </Button>
                  </>
                ):(
                  <FacebookLogin
                    button
                    onLoginStatus={(res) => handleFbAuthStatus(res)}
                    onFbLogin={(res) => handleFbOnLoginReadyMade(res)}
                    integrationData={integrationData}
                    userTokenStatus={facebookUserTokenStatus}
                    dcFacebook={dcFacebook}
                    dcFacebookResponse={dcFacebookResponse}
                    agentEmail={queries.agent_email}
                  />
                )}
              </div>
            ) : (
              <MarketerAdContext.Provider value={adDetails}>
                <MarketerAdDispatchContext.Provider value={setAdDetails}>
                  {/* https://stackoverflow.com/questions/47095900/dynamic-height-for-material-ui-tab-containers */}
                  {/* //! overflow hidden removed to fix scroll bug on firefox, SHOULD LOOK INTO FIXING FOR BOTH BROWSERS LATER. */}
                  {/* //todo: */}
                  {/* //! also REMOVED ANIMATEHEIGHT PROP FOR MESSING WITH FIREFOX SCROLLINg, look into a way of woarking around that */}
                  {/* https://stackoverflow.com/questions/28636832/firefox-overflow-y-not-working-with-nested-flexbox */}
                  {/* <SwipeableViews index={contentIndex} className={classes.swipeable} slideStyle={{overflow: 'hidden'}} animateHeight> */}
                  <SwipeableViews
                    index={contentIndex}
                    className={`${classes.swipeable} ${
                      (contentIndex === 1 || contentIndex === 2 || contentIndex === 4) ? classes.swipeableHideOverflowY : undefined
                    }`}
                    slideStyle={{ overflow: 'hidden!important' }}
                  >
                    <Listing
                      standalone
                      standaloneContext="marketer"
                      justLogged={justLogged}
                      userInfo={userInfo}
                      f={true}
                      setDataFacebook={setDataFacebook}
                      readyMadePhotoListcpy={readyMadePhotoListcpy}
                      setReadyMadePhotoListcpy={setReadyMadePhotoListcpy}
                      // if (searchResponse !== null)
                      buyerSearchObj={searchResponse}
                      multipleLisObj={selectedItem}
                      searchCriteria={selectedItem[0]?.value !== undefined ? selectedItem[0].value : 'buyer search'}
                      // homeValuePhotoList={homeValuePhotoList}
                      // setHomeValuePhotoList={setHomeValuePhotoList}
                      headerList={headerList}
                      setHeaderList={setHeaderList}
                      setJustLogged={setJustLogged}
                      listing={listing}
                      standaloneAction={handleListingAction}
                      userFbPages={userFbPages}
                      editPageAction={handleListingPageAction}
                      userFbAuth={userFbAuth}
                      queries={queries}
                      contentIndex={contentIndex}
                      adCreateAction={handleCreateAdAction}
                      onAdCreated={handleAdCreated}
                      handleHeight={handleHeight}
                      adSeed={adSeed}
                    />
                    <div className={classes.mainContentContainer}>
                      {userFbAuth ? (
                        <FacebookConnected />
                      ) : (
                        <FacebookLogin
                          button
                          onLoginStatus={(res) => handleFbAuthStatus(res)}
                          onFbLogin={(res) => handleFbOnLogin(res)}
                          integrationData={integrationData}
                          userTokenStatus={facebookUserTokenStatus}
                          dcFacebook={dcFacebook}
                          dcFacebookResponse={dcFacebookResponse}
                          agentEmail={queries.agent_email}
                        />
                      )}
                    </div>
                    <FbPageSelect
                      userFbPages={userFbPages}
                      cancelAction={handleCancelPageSelectAction}
                      dcFacebook={dcFacebook}
                      standaloneAction={handlePageSelectAction}
                      sliderIndex={contentIndex}
                      resetUserFbPages={resetUserFbPages}
                      fetchUserFbPages={fetchUserFbPages}
                      closeModal={handleCloseAdTool}
                      standalone
                    />
                    <Checkout
                      standalone
                      standaloneContext="marketer"
                      listing={listing}
                      adCreateAction={handleCreateAdAction}
                      cancelAction={(adCreated) => handleCancelCheckoutAction(adCreated)}
                      onAdCreated={handleAdCreated}
                      billingInfo={billingInfo}
                      sliderIndex={contentIndex}
                    />
                    <AdCreatedPage
                      listing={listing}
                      dataFacebook={dataFacebook}
                      standaloneContext="marketer"
                      standalone
                    />
                  </SwipeableViews>
                </MarketerAdDispatchContext.Provider>
              </MarketerAdContext.Provider>
            )}
          </Dialog>
        )}
      </div>
    </ThemeProvider>
  )
}
export { MarketerAdContext, MarketerAdDispatchContext }
export default MarketerContainer
