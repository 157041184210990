import React from 'react'

import { Redirect } from 'react-router-dom'

const LoginPage = () => {
  if (localStorage.brUserInfo && localStorage.listings) return <Redirect to="/dashboard" />

  if (localStorage.isFBLoggedIn && !localStorage.listings) return <Redirect to="/check-mls" />

  return <Redirect to="/check-fb-login" />
}

export default LoginPage
