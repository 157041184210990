import React from 'react'
import { createUseStyles } from 'react-jss'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'

import { lightGrey, hoverBlack, black } from '../../assets/colorPalette'

const useStyles = createUseStyles({
  paper: {
    backgroundColor: `${lightGrey}!important`,
    width: '100%',
    height: 56,
    left: 'auto',
    right: 0,
    position: 'fixed',
    top: 'auto',
    bottom: 0
  },
  grid: {
    height: '100%'
  },
  copyright: {
    color: hoverBlack,
    fontSize: 13,
    textAlign: 'center',
    fontFamily: 'Roboto'
  },
  link: {
    fontSize: 14,
    color: black,
    textDecoration: 'none'
  }
})

const BottomNavBar = () => {
  const classes = useStyles()
  return (
    <Paper square elevation={0} className={classes.paper}>
      <Grid container direction="row" justify="space-between" alignItems="center" className={classes.grid}>
        <Grid item xs={6}>
          <p className={classes.copyright}>©2020 Blueroof360, All Rights Reserved.</p>
        </Grid>
        <Grid item xs={6}>
          <Grid container direction="row" justify="flex-end" alignItems="center">
            <Grid item xs={2}>
              <a href="javascript:void(0)" className={classes.link}>
                Privacy
              </a>
            </Grid>
            <Grid item xs={2}>
              <a href="javascript:void(0)" className={classes.link}>
                Help
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}
export default BottomNavBar
