import React from 'react'

import { Redirect } from 'react-router-dom'

const MLSPage = () => {
  if (localStorage.brUserInfo && localStorage.listings) return <Redirect to="/dashboard" />

  if (!localStorage.isFBLoggedIn) return <Redirect to="/facebook-login" />

  return <Redirect to="/verify-mls" />
}

export default MLSPage
