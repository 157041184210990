import { globalAbortController, updateAbortController } from '../abortController'
import axios from 'axios'
const apiBaseURL =
  window.location.hostname === 'facebooktool.brivitymarketer.com'
    ? 'https://facebookads.brivitymarketer.com/v1'
    : 'https://facebookads.staging.brivitymarketer.com/v1'
// const apiBaseURL = 'https://facebookads.production.brivitymarketer.com/v1';

let queuedRequests = []

const fetchQueuedRequests = async (newToken) => {
  console.log(queuedRequests)
  const runQueue = queuedRequests.filter((q) => !q.hasBeenConsumed)
  if (runQueue.length > 0) {
    runQueue.map((req) => {
      req.hasBeenConsumed = true
      if (req.action === 'get') {
        get(req.path, req.setHook, newToken)
      }
      if (req.action === 'post') {
        post(req.path, req.data, req.setHook, newToken)
      }
    })
  } else {
    console.log('%c There are no pending requests. Odd.', 'color: lightblue')
  }
}

const handleResponse = async (response, setHook) => {
  const { ok, error } = response

  // if(error) {
  //   const error = await response.json();
  //   return error
  // }
  // const data = await response.json();
  // setHook(data);
  // return data;

  if (ok) {
    const data = await response.json()
    setHook(data)
    return data
  }
  const errorData = await response.json()
  console.log(errorData)
  return errorData
}

const getAuto = async (path, setHook) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
  const response = await fetch(`${path}`, {
    headers,
    method: 'GET',
    signal: globalAbortController.signal
  })
  // console.log("el token:",accessToken);
  // console.log("el path",path);
  // console.log("el response:",response);

  if (!response.ok) {
    if (response.status === 401) {
      if (globalAbortController) {
        globalAbortController.abort()
      }
      // console.log("desde get papu!!");
      // console.log("el token:",accessToken);
      // console.log("el path",path)
      const newAccessToken = await refreshAPIToken()
      !!newAccessToken
        ? fetchQueuedRequests(newAccessToken)
        : console.log('%cCouldnt refresh token, please try again later', 'color: #fc5f4e')
    }
  } else {
    let requestIndex = queuedRequests.findIndex((req) => req.path === path)
    let tempQueuedRequests = [...queuedRequests]

    if (requestIndex > -1) {
      tempQueuedRequests.splice(requestIndex, 1)
    }
    queuedRequests = [...tempQueuedRequests]

    return await handleResponse(response, setHook)
  }
}
const getNoNull = async (path, setHook, newToken) => {
  const accessToken = localStorage.getItem('accessToken')
  // if (accessToken === null) {console.log("Token is null", path); return null;}
  if (!newToken) {
    let requests = [...queuedRequests, { path: path, setHook: setHook, action: 'get' }]
    queuedRequests = [...requests]
  }

  const headers = {
    Authorization: `Bearer ${!!newToken ? newToken : accessToken}`,
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
  const response = await fetch(`${apiBaseURL}${path}`, {
    headers,
    method: 'GET',
    signal: globalAbortController.signal
  })
  // console.log("el token:",accessToken);
  // console.log("el path",path);
  // console.log("el response:",response);

  if (!response.ok) {
    if (response.status === 401) {
      if (globalAbortController) {
        globalAbortController.abort()
      }
      // console.log("desde get papu!!");
      console.log('el token:', accessToken)
      console.log('el path', path)
      const newAccessToken = await refreshAPIToken()
      !!newAccessToken
        ? fetchQueuedRequests(newAccessToken)
        : console.log('%cCouldnt refresh token, please try again later', 'color: #fc5f4e')
    }
  } else {
    let requestIndex = queuedRequests.findIndex((req) => req.path === path)
    let tempQueuedRequests = [...queuedRequests]

    if (requestIndex > -1) {
      tempQueuedRequests.splice(requestIndex, 1)
    }
    queuedRequests = [...tempQueuedRequests]

    return await handleResponse(response, setHook)
  }
}

const get = async (path, setHook, newToken) => {
  const accessToken = localStorage.getItem('accessToken')
  if (accessToken === null) {
    console.log('Token is null', path)
    return null
  }
  if (!newToken) {
    let requests = [...queuedRequests, { path: path, setHook: setHook, action: 'get' }]
    queuedRequests = [...requests]
  }

  const headers = {
    Authorization: `Bearer ${!!newToken ? newToken : accessToken}`,
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
  const response = await fetch(`${apiBaseURL}${path}`, {
    headers,
    method: 'GET',
    signal: globalAbortController.signal
  })
  // console.log("el token:",accessToken);
  // console.log("el path",path);
  // console.log("el response:",response);

  if (!response.ok) {
    if (response.status === 401) {
      if (globalAbortController) {
        globalAbortController.abort()
      }
      // console.log("desde get papu!!");
      console.log('token:', accessToken)
      console.log('path', path)
      const newAccessToken = await refreshAPIToken()
      !!newAccessToken
        ? fetchQueuedRequests(newAccessToken)
        : console.log('%cCouldnt refresh token, please try again later', 'color: #fc5f4e')
    }
  } else {
    let requestIndex = queuedRequests.findIndex((req) => req.path === path)
    let tempQueuedRequests = [...queuedRequests]

    if (requestIndex > -1) {
      tempQueuedRequests.splice(requestIndex, 1)
    }
    queuedRequests = [...tempQueuedRequests]

    return await handleResponse(response, setHook)
  }
}

const getFbUserTokenStatus = async (path, setHook) => {
  const headers = {
    // Authorization: `Bearer ${!!newToken ? newToken : accessToken}`,
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
  const response = await fetch(`${apiBaseURL}${path}`, {
    headers,
    method: 'GET',
    signal: globalAbortController.signal
  })

  if (!response.ok) {
    // if (response.status === 401) {
    //   if (globalAbortController) {
    //     globalAbortController.abort();
    //   }
    // }
    const data = await response.json()
    setHook(data)
  } else {
    let requestIndex = queuedRequests.findIndex((req) => req.path === path)
    let tempQueuedRequests = [...queuedRequests]

    if (requestIndex > -1) {
      tempQueuedRequests.splice(requestIndex, 1)
    }
    queuedRequests = [...tempQueuedRequests]

    return await handleResponse(response, setHook)
  }
}

const getReadyMade = async (path, setHook, newToken) => {
  const accessToken = localStorage.getItem('accessToken')
  if (accessToken === null) {
    console.log('Token is null', path)
    return null
  }
  if (!newToken) {
    let requests = [...queuedRequests, { path: path, setHook: setHook, action: 'get' }]
    queuedRequests = [...requests]
  }

  const headers = {
    Authorization: `Bearer ${!!newToken ? newToken : accessToken}`,
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
  const response = await fetch(`${apiBaseURL}${path}`, {
    headers,
    method: 'GET',
    signal: globalAbortController.signal
  })
  // console.log("el token:",accessToken);
  // console.log("el path",path);
  // console.log("el response:",response);

  if (!response.ok) {
    if (response.status === 401) {
      // console.log("desde get papu!!");
      console.log('token:', accessToken)
      console.log('path', path)
      const newAccessToken = await refreshAPITokenReady()
      if (!!newAccessToken) {
        fetchQueuedRequests(newAccessToken)
      } else {
        console.log('%cCouldnt refresh token, please try again later', 'color: #fc5f4e')
        localStorage.setItem('accessToken', null)
        setHook({})
      }
    }
  } else {
    let requestIndex = queuedRequests.findIndex((req) => req.path === path)
    let tempQueuedRequests = [...queuedRequests]

    if (requestIndex > -1) {
      tempQueuedRequests.splice(requestIndex, 1)
    }
    queuedRequests = [...tempQueuedRequests]

    return await handleResponse(response, setHook)
  }
}
const openGet = async (path, setHook, newToken) => {
  if (!newToken) {
    let requests = [...queuedRequests, { path: path, setHook: setHook, action: 'get' }]
    queuedRequests = [...requests]
  }

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
  const response = await fetch(`${apiBaseURL}${path}`, {
    headers,
    method: 'GET',
    signal: globalAbortController.signal
  })
  // console.log("el token:",accessToken);
  // console.log("el path",path);
  // console.log("el response:",response);

  if (!response.ok) {
    if (response.status === 401) {
      if (globalAbortController) {
        globalAbortController.abort()
      }
      // console.log("desde get papu!!");
      // console.log("el token:",accessToken);
      // console.log("el path",path)
      const newAccessToken = await refreshAPIToken()
      !!newAccessToken
        ? fetchQueuedRequests(newAccessToken)
        : console.log('%cCouldnt refresh token, please try again later', 'color: #fc5f4e')
    }
  } else {
    let requestIndex = queuedRequests.findIndex((req) => req.path === path)
    let tempQueuedRequests = [...queuedRequests]

    if (requestIndex > -1) {
      tempQueuedRequests.splice(requestIndex, 1)
    }
    queuedRequests = [...tempQueuedRequests]

    return await handleResponse(response, setHook)
  }
}

const getAutoComplete = (path, setHook) => {
  // console.log("from:",path);

  axios
    .get(`${path}`) //"https://jaradhull.com/pages/autocomplete.php?mlsIds=101|590&term=park"
    // .get("https://staging-004.bkcoair.com/pages/autocomplete.php?mlsIds=101|590&term=park")
    .then((res) => {
      setHook(res.data)
      // console.log("from autocomplete success:",res);
      // this.setState({ dogs: res.data.message });
    })
    .catch((err) => {
      console.log(err, path)
      setHook([
        {
          category: '',
          value: 'Oops! Something went wrong!'
        }
      ])
    })
}
const getAutoCompletes = (path, setHook) => {
  // console.log("from:",path);

  axios
    .get(`${path}`) //"https://jaradhull.com/pages/autocomplete.php?mlsIds=101|590&term=park"
    // .get("https://staging-004.bkcoair.com/pages/autocomplete.php?mlsIds=101|590&term=park")
    .then((res) => {
      // setSelectedItemI((chips) => chips.filter((chip) => chip.value !== chipToDelete.value));
      setHook(res.data.filter((item) => item.category === 'City' || item.category === 'Zip'))
      // console.log("from autocomplete success:",res);
      // this.setState({ dogs: res.data.message });
    })
    .catch((err) => {
      console.log(err)
      setHook(err)
    })
}

const getAutoCompleteMultiple = (path, setHook) => {
  // console.log("from:",path);
  const apiBrivityBaseURL =
    window.location.hostname === 'facebooktool.brivitymarketer.com'
      ? 'https://www.brivitymarketer.com/api'
      : 'https://ads.brivitymarketer.com/api'
  // const apiBrivityBaseURL = 'https://www.brivitymarketer.com/api';
  axios
    .get(`${apiBrivityBaseURL}${path}`) //"https://jaradhull.com/pages/autocomplete.php?mlsIds=101|590&term=park"
    // .get("https://staging-004.bkcoair.com/pages/autocomplete.php?mlsIds=101|590&term=park")
    .then((res) => {
      setHook(res.data)
      // console.log("from autocomplete success:",res);
      // this.setState({ dogs: res.data.message });
    })
    .catch((err) => {
      console.log(err)
      setHook(err)
    })
}

const getHomeValue = (path, setHook) => {
  // console.log("desde:",path);
  axios
    .get(`${apiBaseURL}${path}`) //"https://jaradhull.com/pages/autocomplete.php?mlsIds=101|590&term=park"
    .then((res) => {
      setHook(res.data)
      // console.log("desde getHomeValue index:",res);
      // this.setState({ dogs: res.data.message });
    })
    .catch((err) => {
      console.log(err)
      setHook(err)
    })
}

const getValidPage = (path, id, setHook, oldState) => {
  const accessToken = localStorage.getItem('accessToken')

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  }

  axios
    .get(`${apiBaseURL}${path}`, config)
    .then((res) => {
      if (res.data.error_code != 0) {
        throw 'error_code != 0'
      }
      setHook({ ...oldState, [id]: true })
    })
    .catch((err) => {
      console.log(err)
      setHook({ ...oldState, [id]: false })
    })
}

const post = async (path, data, setHook, newToken) => {
  const accessToken = localStorage.getItem('accessToken')
  // console.log(accessToken);
  // console.log(data);
  if (!newToken) {
    let requests = [...queuedRequests, { path: path, data: data, setHook: setHook, action: 'post' }]
    queuedRequests = [...requests]
  }

  const headers = {
    Authorization: `Bearer ${!!newToken ? newToken : accessToken}`,
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
  // https://gist.github.com/odewahn/5a5eeb23279eed6a80d7798fdb47fe91

  const response = await fetch(`${apiBaseURL}${path}`, {
    headers,
    method: 'POST',
    body: JSON.stringify(data),
    signal: globalAbortController.signal
  })

  if (!response.ok) {
    if (response.status === 401) {
      if (globalAbortController) {
        globalAbortController.abort()
      }
      // console.log("desde post papu!!");
      const newAccessToken = await refreshAPIToken()

      !!newAccessToken
        ? fetchQueuedRequests(newAccessToken)
        : console.log('Couldnt refresh token, please try again later')
    } else {
      let requestIndex = queuedRequests.findIndex((req) => req.path === path)
      let tempQueuedRequests = [...queuedRequests]
      if (requestIndex > -1) {
        tempQueuedRequests.splice(requestIndex, 1)
      }
      queuedRequests = [...tempQueuedRequests]

      console.log('%c•\tERROR\t•', 'background: #ffa500; color: #fc5f4e')
      let errLog = await response.json()
      console.log(errLog)
      let errorResponse = {
        error: errLog
      }
      setHook(errorResponse)
    }
  } else {
    let requestIndex = queuedRequests.findIndex((req) => req.path === path)
    let tempQueuedRequests = [...queuedRequests]
    if (requestIndex > -1) {
      tempQueuedRequests.splice(requestIndex, 1)
    }
    queuedRequests = [...tempQueuedRequests]
    return handleResponse(response, setHook)
  }
}
const postPhoto = (path, data, setHook, newToken) => {
  const accessToken = localStorage.getItem('accessToken')
  // console.log(accessToken);

  if (!newToken) {
    let requests = [...queuedRequests, { path: path, data: data, setHook: setHook, action: 'post' }]
    queuedRequests = [...requests]
  }
  var formData = new FormData()

  formData.append('photo', data)

  const config = {
    headers: {
      Authorization: `Bearer ${!!newToken ? newToken : accessToken}`
    }
  }
  axios
    .post(`${apiBaseURL}${path}`, formData, config)
    .then(function (res) {
      // console.log({res});

      setHook(res.data)
      // alert("success");
    })
    .catch(function (err) {
      console.log({ err })
      setHook({ message: err.response.data.message })
      // alert("failed upload image" + err);
    })
}
const postForm_noToken = (path, data, setHook, newToken) => {
  const accessToken = localStorage.getItem('accessToken')
  // console.log(accessToken);
  if (!newToken) {
    let requests = [...queuedRequests, { path: path, data: data, setHook: setHook, action: 'post' }]
    queuedRequests = [...requests]
  }
  var formData = new FormData()

  formData.append('mlsId', data.mlsId)
  // formData.append("mlsId", "2");
  formData.append('multi_search', data.multi_search)
  formData.append('multi_cat', data.multi_cat)
  formData.append('states', data.states)
  // price: (minPrice === null ? "1000": minPrice) + ":" + (maxPrice === null ? "": maxPrice),
  // bedrooms:(minBed === null ? "1": minBed) + ":" + (maxBed === null ? "": maxBed),
  // totalBaths:(minBath === null ? "1": minBath) + ":" + (maxBath === null ? "": maxBath),
  // console.log(data.maxPrice)
  if (data.minPrice !== null || data.maxPrice !== null) {
    formData.append(
      'price',
      (data.minPrice === null ? '1000' : data.minPrice) + ':' + (data.maxPrice === null ? '' : data.maxPrice)
    )
  }
  if (data.minBed !== null || data.maxBed !== null) {
    formData.append(
      'bedrooms',
      (data.minBed === null ? '1' : data.minBed) + ':' + (data.maxBed === null ? '' : data.maxBed)
    )
  }
  if (data.minBath !== null || data.maxBath !== null) {
    formData.append(
      'totalBaths',
      (data.minBath === null ? '1' : data.minBath) + ':' + (data.maxBath === null ? '' : data.maxBath)
    )
  }
  // formData.append("bedrooms", data.bedrooms);
  // formData.append("totalBaths", data.totalBaths);
  formData.append('q_include_total_count', 'true')
  formData.append('get_polygon', 'true')
  formData.append('q_limit', '11')
  formData.append('q_offset', '0')

  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }

  axios
    .post(`${path}`, formData, config)
    .then(function (res) {
      // console.log({res});
      let y = { ...res.data }
      y.data = y.data.filter((item) => item.main_photo !== undefined)
      setHook(y)
      //setHook(res.data);
      // alert("success");
    })
    .catch(function (err) {
      console.log({ err })
      setHook(err)
      // alert("failed upload image" + err);
    })
}

function IsValidJSONString(str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

const refreshAPIToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken')
  await updateAbortController()

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }

  const response = await fetch(`${apiBaseURL}/token/refresh`, {
    headers,
    method: 'post',
    body: JSON.stringify({ refresh_token: refreshToken })
  })
  // console.log("El refresco del token:",refreshToken);
  if (response.ok) {
    const data = await response.json()

    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.setItem('accessToken', data.access_token)
    localStorage.setItem('refreshToken', data.refresh_token)

    console.log('%cToken Refresh: Successful', 'color: #bada55')

    return data.access_token
  } else {
    console.log('%cToken Refresh: Failed', 'color: #fc5f4e')
  }
}
const refreshAPITokenReady = async () => {
  const refreshToken = localStorage.getItem('refreshToken')

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }

  const response = await fetch(`${apiBaseURL}/token/refresh`, {
    headers,
    method: 'post',
    body: JSON.stringify({ refresh_token: refreshToken })
  })
  // console.log("El refresco del token:",refreshToken);
  if (response.ok) {
    const data = await response.json()

    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.setItem('accessToken', data.access_token)
    localStorage.setItem('refreshToken', data.refresh_token)

    console.log('%cToken Refresh: Successful', 'color: #bada55')

    return data.access_token
  } else {
    console.log('%cToken Refresh: Failed', 'color: #fc5f4e')
    localStorage.setItem('accessToken', null)
  }
}
const authAPIUser = async (fbToken, integrationData) => {
  const fb_info = JSON.parse(localStorage.getItem('fb_info'))
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('brUserInfo')

  let payload = {}
  let basicData = {
    email: fb_info.email,
    facebook_id: fb_info.id,
    facebook_token: fbToken
  }
  if (integrationData) {
    let extendedData = {
      ...basicData,
      company_user_id: integrationData.userId,
      company: 'brivity',
      billing_type: 'chargify',
      integration_token: integrationData.token,
      website: integrationData.website
    }
    payload = { ...extendedData }
  } else {
    payload = { ...basicData, company: 'blueroof' }
  }
  //todo: https://stackoverflow.com/a/51320025
  //todo: MUST look into "how to handle empty response on fetch"
  const response = await fetch(`${apiBaseURL}/users/login`, {
    headers,
    method: 'post',
    body: JSON.stringify(payload)
  })

  const data = await response.json()

  if (response.ok) {
    console.log('response:', data)
    localStorage.setItem('accessToken', data.token.access_token)
    localStorage.setItem('refreshToken', data.token.refresh_token)
    localStorage.setItem('brUserInfo', JSON.stringify(data.user))
  } else {
    console.log('%cERROR! - Couldnt log in the user, please try again later.', 'color: #fc5f4e')
  }
}

export default {
  get,
  getNoNull,
  getAutoComplete,
  getAutoCompletes,
  getAutoCompleteMultiple,
  post,
  postPhoto,
  postForm_noToken,
  refreshAPIToken,
  getReadyMade,
  authAPIUser,
  handleResponse,
  openGet,
  getHomeValue,
  getFbUserTokenStatus,
  getValidPage
}
